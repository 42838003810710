@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@300;500;600;800&display=swap");
@import url("https://fonts.cdnfonts.com/css/gilroy-bold");
h1, h2 {
  font-family: 'Inter', sans-serif;
  font-weight: 700; }

h3, h4 {
  font-family: 'Inter', sans-serif;
  font-weight: 400; }

p,
a,
button,
input,
select,
option,
ul,
li,
table,
td,
tr,
th,
tbody,
thead,
div,
header,
section,
aside,
figure,
label,
textarea,
::placeholder {
  font-family: 'Inter', sans-serif;
  font-weight: 400; }

span {
  font-family: 'Inter', sans-serif;
  font-weight: 700; }

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none; }

a {
  text-decoration: none; }

ul,
ol,
li {
  margin: 0;
  padding: 0; }

html {
  scroll-behavior: smooth; }

small {
  font-size: inherit; }

/**
*
* Testing
*
**/
.single_games {
  overflow: hidden; }
  @media (max-width: 800px) {
    .single_games .about__shape {
      display: none; } }
  .single_games__banner {
    background-color: #8354D4;
    background-image: url("/wp-content/uploads/2022/09/game_back.png");
    background-repeat: repeat;
    background-size: 58%; }
    @media (max-width: 800px) {
      .single_games__banner {
        display: none; } }
    @media (max-width: 800px) {
      .single_games__banner_info {
        margin: 0 auto;
        text-align: center; }
        .single_games__banner_info h1 {
          text-align: center;
          font-size: 22px; } }
    @media (max-width: 800px) {
      .single_games__banner_info .banner_btn_play_now {
        margin: 0 auto; } }
    .single_games__banner_introduction a {
      text-decoration: underline;
      color: #FCC13E;
      font-size: 14px;
      padding: 5px; }
    @media (max-width: 800px) {
      .single_games__banner_introduction {
        text-align: center; } }
    .single_games__banner_screenshots {
      display: flex;
      position: relative;
      z-index: 5;
      top: 275px;
      flex-direction: column; }
      .single_games__banner_screenshots h3 {
        font-family: Inter;
        font-size: 22px;
        font-weight: 700;
        line-height: 31px;
        margin-bottom: 20px; }
      .single_games__banner_screenshots img {
        max-width: 190px;
        border-radius: 10px; }
        @media (max-width: 600px) {
          .single_games__banner_screenshots img {
            width: 250px; } }
      .single_games__banner_screenshots_no_screens {
        display: flex;
        overflow-x: auto;
        align-items: center;
        gap: 20px;
        box-shadow: 0 3px 8px #dddddd;
        border-radius: 15px;
        padding: 35px 40px 35px 20px;
        font-family: Inter;
        font-size: 18px;
        font-weight: 400; }
      @media (max-width: 800px) {
        .single_games__banner_screenshots_box {
          display: flex;
          max-width: 380px;
          overflow-x: auto;
          margin: 0 auto; }
          .single_games__banner_screenshots_box::-webkit-scrollbar-thumb {
            background-color: #925edb;
            border-radius: 10px; }
          .single_games__banner_screenshots_box::-webkit-scrollbar {
            width: 10px;
            height: 5px; }
          .single_games__banner_screenshots_box::-webkit-scrollbar-track {
            box-shadow: inset 0 0 5px #925edb;
            border-radius: 10px; }
          .single_games__banner_screenshots_box::-webkit-scrollbar-thumb:hover {
            background: #925edb; } }
    .single_games__banner_game_param {
      display: flex;
      gap: 20px;
      margin-top: 30px;
      margin-bottom: -248px;
      z-index: 5;
      position: relative; }
      @media (max-width: 800px) {
        .single_games__banner_game_param {
          display: grid;
          grid-template-columns: 100%;
          justify-content: center;
          align-items: center;
          align-content: center; } }
      .single_games__banner_game_param_item {
        width: 280px;
        height: 124px;
        display: flex;
        border-radius: 20px;
        background: #f7f7f7;
        padding: 25px;
        box-shadow: 0 3px 8px #dddddd; }
        @media (max-width: 800px) {
          .single_games__banner_game_param_item {
            display: grid;
            text-align: center;
            width: unset;
            height: unset; } }
        .single_games__banner_game_param_item_text {
          padding: 5px 0 0 15px;
          font-family: Inter;
          font-size: 20px;
          font-weight: 600; }
    .single_games__banner_prov {
      display: flex;
      gap: 20px; }
      @media (max-width: 800px) {
        .single_games__banner_prov {
          margin: 0 auto;
          text-align: center;
          justify-content: center;
          gap: 15px; } }
      .single_games__banner_prov_provider, .single_games__banner_prov_difficulty {
        display: flex;
        width: auto;
        height: 36px;
        background: #a87ee2;
        border-radius: 15px;
        justify-content: space-evenly;
        align-items: center;
        padding: 0 12px;
        gap: 5px; }
        .single_games__banner_prov_provider img, .single_games__banner_prov_difficulty img {
          max-height: 25px; }
        .single_games__banner_prov_provider_type, .single_games__banner_prov_difficulty_type {
          color: #FCC13E;
          font-weight: 600; }
  .single_games__banner_box {
    display: grid;
    grid-template-columns: 65% 30%;
    gap: 0 40px;
    align-items: center; }
    @media (max-width: 800px) {
      .single_games__banner_box {
        grid-template-columns: 100%; } }
  .single_games__banner_info_introduction {
    font-family: Inter;
    font-size: 18px;
    font-weight: 400; }
  .single_games__banner .breadcrumbs, .single_games__banner_title, .single_games__banner_prov, .single_games__banner_introduction {
    color: #f7f7f7;
    padding-bottom: 30px; }
  @media (max-width: 800px) {
    .single_games__banner .breadcrumbs {
      display: none; } }
  .single_games__banner .banner_btn_play_now {
    width: 240px;
    text-align: center;
    font-family: Mulish;
    font-size: 18px;
    font-weight: 800;
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .single_games__banner_title {
    font-family: Inter;
    font-size: 42px;
    font-weight: 700; }
  .single_games__banner_subheading {
    font-family: Inter;
    font-size: 26px;
    font-weight: 700; }
  .single_games__banner_image {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    display: block;
    background: white;
    min-width: 330px;
    z-index: 5;
    margin-bottom: -190px;
    border-radius: 30px;
    box-shadow: 0 3px 8px #dddddd; }
    @media (max-width: 800px) {
      .single_games__banner_image {
        order: -1;
        margin: 20px 0; } }
  .single_games__banner_thumb {
    border-radius: 20px;
    width: 100%; }
  .single_games__banner_terms_condition {
    font-size: 14px; }
  .single_games__banner_game_info_header {
    min-width: 330px;
    background: #292929;
    color: #f7f7f7;
    padding: 10px;
    font-family: Inter;
    font-size: 18px;
    font-weight: 700; }
    .single_games__banner_game_info_header i {
      color: #FCC13E;
      margin-right: 5px; }
  .single_games__banner_game_info_box {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 10px 20px; }
    .single_games__banner_game_info_box:nth-child(even) {
      background: #f7f7f7; }
    .single_games__banner_game_info_box:last-child {
      border-radius: 0 0 30px 30px; }
    .single_games__banner_game_info_box_title {
      color: #292929;
      font-family: Inter;
      font-size: 16px;
      font-weight: 600;
      text-align: left; }
    .single_games__banner_game_info_box_val {
      color: #292929;
      font-family: Inter;
      font-size: 16px;
      font-weight: 400;
      text-align: left; }
  .single_games__mobile_banner_introduction {
    text-align: center;
    line-height: 27px;
    padding: 0 20px 20px;
    font-size: 18px; }
  @media (min-width: 800px) {
    .single_games__view_mobile {
      display: none; } }
  .single_games__view_mobile_desc .single_games__banner_screenshots {
    position: initial;
    margin: 40px auto;
    width: 90%; }
    .single_games__view_mobile_desc .single_games__banner_screenshots h3 {
      text-align: center; }
    .single_games__view_mobile_desc .single_games__banner_screenshots_box img {
      min-width: 274px; }
  .single_games__view_mobile .games_show_more {
    position: absolute;
    bottom: -40px;
    left: 50%;
    transform: translate(-50%, 0);
    display: flex;
    align-items: center;
    gap: 5px; }
  .single_games__view_mobile_banner {
    display: flex;
    justify-content: center;
    padding: 30px 0; }
  .single_games__view_mobile_desc_btns {
    padding-top: 20px; }
  .single_games__mobile_game_param {
    padding: 12px 0; }
  .single_games__mobile_banner_info {
    position: relative;
    margin-top: 70px; }
  .single_games__mobile_banner_game_param_item_text {
    display: flex;
    font-size: 16px; }
  .single_games__mobile_banner_game_param_item {
    display: flex;
    justify-content: center;
    gap: 15px;
    align-items: center;
    align-content: center;
    width: 90%;
    background-color: #fff;
    box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.095);
    padding: 10px 0;
    margin: 0 auto 15px;
    border-radius: 20px; }
  .single_games__mobile_banner_game_info {
    width: 90%;
    margin: 10px auto; }
  .single_games__mobile_banner_game_info_header {
    background-color: #8354D4;
    padding: 18px 10px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    text-align: center;
    color: #fff;
    font-size: 18px; }
    .single_games__mobile_banner_game_info_header i {
      color: #fff;
      font-size: 20px;
      margin-right: 10px; }
  .single_games__mobile_quick_verdict {
    width: 90%;
    margin: 10px auto;
    box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.095);
    text-align: center;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 20px; }
  .single_games__mobile_quick_icon img {
    width: 50px;
    height: 50px; }
  .single_games__mobile_our_verdict_score_group_text h4 {
    font-size: 20px;
    font-weight: 600;
    padding-top: 10px; }
  .single_games__mobile_our_verdict_score_group_text p {
    font-size: 18px;
    line-height: 30px; }
  .single_games__similar_games {
    display: flex;
    justify-content: space-between;
    padding: 20px 5px; }
    @media (max-width: 800px) {
      .single_games__similar_games {
        display: flex;
        min-width: 390px;
        overflow: auto; } }

.game_verdict {
  margin-top: 270px;
  box-shadow: 0 3px 8px #dddddd;
  border-radius: 15px; }
  @media (max-width: 800px) {
    .game_verdict {
      display: none; } }
  .game_verdict h4 {
    font-family: Inter;
    font-size: 22px;
    font-weight: 700;
    padding: 20px 0; }
  .game_verdict p {
    font-family: Inter;
    font-size: 20px;
    font-weight: 400; }
  .game_verdict .single_casino__our_verdict_score_group .scoreCircle {
    width: 200px; }
  .game_verdict .single_casino__our_verdict_score_group {
    padding: 30px;
    display: grid;
    grid-template-columns: 100%;
    text-align: center; }

.casino_offers {
  margin-top: 300px; }
  @media (max-width: 800px) {
    .casino_offers {
      margin-top: 0; } }
  .casino_offers__title {
    margin: 50px 0; }
    .casino_offers__title h4 {
      font-family: Inter;
      font-size: 22px;
      font-weight: 700;
      padding: 20px 0;
      text-align: center; }
    .casino_offers__title p {
      font-family: Inter;
      font-size: 16px;
      font-weight: 400;
      line-height: 32px;
      text-align: center; }
      @media (max-width: 800px) {
        .casino_offers__title p {
          display: none; } }
  .casino_offers__feed .ad_disclosure {
    padding-bottom: 15px; }
    @media (max-width: 800px) {
      .casino_offers__feed .ad_disclosure {
        display: none; } }

.game_review {
  background: #8354D4; }
  @media (max-width: 800px) {
    .game_review .inner {
      width: 90%; } }
  .game_review__inner {
    padding: 20px 0 40px; }
  .game_review__shape-bottom {
    transform: scale(1.05, 1); }
  .game_review__shape-bottom, .game_review__shape-top {
    background-color: white;
    width: 100%;
    height: 50px;
    border-radius: 50% / 75%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border: 1px solid white; }
  .game_review__shape-top {
    transform: scale(1.05, -1); }
  .game_review__header-title {
    color: #FFFFFF;
    font-weight: 800;
    font-size: 30px;
    margin: 70px 0 50px; }
  .game_review__header-author {
    display: flex; }
    .game_review__header-author-data {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly; }
      .game_review__header-author-data > * {
        font-size: 16px;
        color: #FFFFFF; }
    .game_review__header-author img {
      border-radius: 45px;
      border: 4px solid #a87ee2;
      max-width: 68px;
      margin-right: 10px; }
  .game_review__content_item {
    background-color: #FFFFFF;
    border-radius: 5px;
    margin-bottom: 15px;
    display: flex;
    padding: 21px 85px;
    align-items: center;
    justify-content: space-between; }
    .game_review__content_item_welcome_offer {
      font-weight: bold; }
    .game_review__content_item div {
      font-size: 20px; }
    .game_review__content_item img {
      height: 75px; }
  .game_review__author-articles-title {
    display: flex;
    justify-content: space-around;
    padding: 15px; }
    .game_review__author-articles-title span {
      font-weight: 700;
      font-size: 20px;
      color: #FFFFFF; }
  .game_review__content_item_img {
    display: flex;
    flex-direction: column;
    align-items: center; }
    .game_review__content_item_img a {
      text-decoration: underline;
      color: #585858;
      font-size: 14px;
      padding: 5px; }
  .game_review__foreword img {
    width: 100%; }
  .game_review__foreword div {
    background-color: #a87ee2;
    border-radius: 5px;
    padding: 60px 128px; }
    .game_review__foreword div table {
      width: 100%;
      background: none; }
      .game_review__foreword div table th {
        font-weight: 700;
        font-size: 20px;
        color: #fff;
        text-align: center; }
      .game_review__foreword div table tr:first-child {
        background: none;
        border: none;
        height: 50px; }
        .game_review__foreword div table tr:first-child td {
          font-weight: 700; }
      .game_review__foreword div table tr {
        background-color: #fff;
        height: 150px; }
        .game_review__foreword div table tr td {
          border-top: 5px solid #a87ee2;
          border-bottom: 5px solid #a87ee2;
          color: #292929;
          font-size: 20px;
          text-align: center; }
        .game_review__foreword div table tr td:first-child {
          border-left: 5px solid #a87ee2;
          border-bottom-left-radius: 20px;
          border-top-left-radius: 20px; }
          .game_review__foreword div table tr td:first-child img {
            height: 80px; }
          .game_review__foreword div table tr td:first-child a {
            text-decoration: underline;
            color: #585858;
            font-size: 14px;
            padding: 5px; }
        .game_review__foreword div table tr td:last-child {
          border-right: 5px solid #a87ee2;
          border-bottom-right-radius: 20px;
          border-top-right-radius: 20px;
          font-weight: 700; }
  .game_review__foreword a {
    color: #FCC13E;
    text-decoration: underline; }
  .game_review__foreword li {
    margin-left: 20px;
    margin-bottom: 10px; }
  .game_review__foreword h2.heading {
    font-weight: 800;
    font-size: 30px; }
  .game_review__foreword h2,
  .game_review__foreword h3 {
    margin: 20px 0 15px; }
  .game_review__foreword, .game_review__afterword, .game_review__final {
    margin-top: 30px; }
    .game_review__foreword h3, .game_review__afterword h3, .game_review__final h3 {
      font-family: Inter;
      font-size: 22px;
      font-weight: 700;
      line-height: 50px; }
    .game_review__foreword,
    .game_review__foreword p, .game_review__afterword,
    .game_review__afterword p, .game_review__final,
    .game_review__final p {
      font-family: Inter;
      font-size: 16px;
      font-weight: 400;
      line-height: 27px;
      color: #FFFFFF;
      margin: 15px 0; }

.simmilar_games {
  margin: 70px 0; }
  .simmilar_games h3 {
    font-family: Inter;
    font-size: 30px;
    font-weight: 700;
    line-height: 31px;
    margin-bottom: 30px; }
    @media (max-width: 800px) {
      .simmilar_games h3 {
        text-align: center;
        font-size: 22px; } }

.from_provider {
  margin: 70px 0; }
  .from_provider h3 {
    font-family: Inter;
    font-size: 30px;
    font-weight: 700;
    line-height: 31px;
    margin-bottom: 30px; }
    @media (max-width: 800px) {
      .from_provider h3 {
        text-align: center;
        font-size: 22px; } }
