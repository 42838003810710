@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@300;500;600;800&display=swap");
@import url("https://fonts.cdnfonts.com/css/gilroy-bold");
h1, h2 {
  font-family: 'Inter', sans-serif;
  font-weight: 700; }

h3, h4 {
  font-family: 'Inter', sans-serif;
  font-weight: 400; }

p,
a,
button,
input,
select,
option,
ul,
li,
table,
td,
tr,
th,
tbody,
thead,
div,
header,
section,
aside,
figure,
label,
textarea,
::placeholder {
  font-family: 'Inter', sans-serif;
  font-weight: 400; }

span {
  font-family: 'Inter', sans-serif;
  font-weight: 700; }

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none; }

a {
  text-decoration: none; }

ul,
ol,
li {
  margin: 0;
  padding: 0; }

html {
  scroll-behavior: smooth; }

small {
  font-size: inherit; }

/**
*
* Testing
*
**/
.sitemap_main {
  background-color: #f7f7f7;
  padding-bottom: 50px; }
  .sitemap_main__inner {
    max-width: 1100px;
    padding: 0 20px;
    margin: 0 auto; }
  .sitemap_main__banner {
    position: relative;
    background-image: url("/wp-content/themes/gecko/img/sitemap/posts/Background-Posts-Desktop.png");
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: cover;
    padding-bottom: 100px;
    margin-bottom: 100px;
    object-fit: cover; }
    @media (max-width: 600px) {
      .sitemap_main__banner {
        padding-bottom: 50px;
        background-image: url("/wp-content/themes/gecko/img/sitemap/posts/Background-Posts-Mobile.png"); } }
  .sitemap_main__banner_container {
    padding: 20px 0 0 0; }
    @media (max-width: 600px) {
      .sitemap_main__banner_container {
        padding: 0; } }
  .sitemap_main__banner_breadcrumb {
    padding: 10px 0;
    font-weight: 400; }
    @media (max-width: 768px) {
      .sitemap_main__banner_breadcrumb {
        font-size: 14px; } }
    .sitemap_main__banner_breadcrumb span.separator {
      color: #FCC13E; }
    .sitemap_main__banner_breadcrumb a, .sitemap_main__banner_breadcrumb span {
      font-weight: 500;
      color: #FFFFFF; }
  .sitemap_main__banner_title {
    padding: 20px 0 0 0;
    color: #FFFFFF; }
    @media (max-width: 800px) {
      .sitemap_main__banner_title {
        padding: 0; } }
    .sitemap_main__banner_title h1 {
      font-weight: 900;
      font-size: 50px; }
      @media (max-width: 768px) {
        .sitemap_main__banner_title h1 {
          font-size: 45px; } }
  .sitemap_main__banner_picture {
    position: absolute;
    right: 18%;
    bottom: -18%; }
    @media (max-width: 600px) {
      .sitemap_main__banner_picture {
        display: flex;
        justify-content: center; } }
    .sitemap_main__banner_picture img {
      width: 45%; }
      @media (max-width: 600px) {
        .sitemap_main__banner_picture img {
          width: 55%; } }
    @media (max-width: 1250px) {
      .sitemap_main__banner_picture {
        right: -55px; } }
    @media (max-width: 800px) {
      .sitemap_main__banner_picture {
        right: 0; } }
    @media (max-width: 600px) {
      .sitemap_main__banner_picture {
        bottom: -17%; } }
  .sitemap_main__content_section_title {
    font-size: 25px;
    padding-bottom: 10px;
    padding-left: 35px; }
  .sitemap_main__content_section_div {
    background-color: #FFFFFF;
    padding: 40px 10px 40px 50px;
    display: grid;
    grid-template-columns: repeat(4, 23%);
    justify-content: space-between;
    margin-bottom: 30px;
    border-radius: 10px;
    max-height: 400px;
    overflow-y: auto; }
    .sitemap_main__content_section_div::-webkit-scrollbar-thumb {
      background-color: #925EDB;
      border-radius: 10px; }
    .sitemap_main__content_section_div::-webkit-scrollbar {
      width: 10px;
      height: 5px; }
    .sitemap_main__content_section_div::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #fff;
      border-radius: 10px; }
    .sitemap_main__content_section_div::-webkit-scrollbar-thumb:hover {
      background: #925EDB; }
    @media (max-width: 800px) {
      .sitemap_main__content_section_div {
        grid-template-columns: repeat(3, 32%); } }
    @media (max-width: 600px) {
      .sitemap_main__content_section_div {
        grid-template-columns: repeat(2, 50%); } }
    @media (max-width: 400px) {
      .sitemap_main__content_section_div {
        grid-template-columns: 100%; } }
  .sitemap_main__content_section_li {
    padding-bottom: 5px; }
    .sitemap_main__content_section_li::marker {
      color: #8354D4; }
  .sitemap_main__content_casino_link {
    font-size: 16px;
    font-weight: 500;
    text-decoration: underline;
    color: #8354D4;
    line-height: 25px; }
    .sitemap_main__content_casino_link:hover {
      color: #292929;
      text-decoration: none; }
