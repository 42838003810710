// @import 'fonts';

// this partial includes the scss variables used across the site

// Montserrat Fonts
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800;900&display=swap');
// Inter Fonts
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&display=swap');
// Mulish Fonts
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@300;500;600;800&display=swap');

// Gilroy Fonts
@import url('https://fonts.cdnfonts.com/css/gilroy-bold');
// font-family: 'Gilroy-Bold', sans-serif;
// font-family: 'Gilroy-Heavy', sans-serif;
// font-family: 'Gilroy-Light', sans-serif;
// font-family: 'Gilroy-Medium', sans-serif;
// font-family: 'Gilroy-Regular', sans-serif;
// font-family:  'Montserrat', sans-serif;

$light-background-vary-light: #c4b9ef;
$light-background-color: #a87ee2;
$primary-background-color: #8354D4;
$secondary-background-color: #FCC13E;
$accent-color: #7e53dd;
$accent-text-color: #585858;
$background-text-color: #7f8c8d;
$tetiary-color: #FFFFFF;
$tetiary-background-color: #f7f7f7;
$quatenary-background-color: #FFFFFF;
$paragraph-text-color: #292929;


h1, h2 {
    font-family: 'Inter', sans-serif;
    font-weight: 700;
}

h3,h4{
    font-family: 'Inter', sans-serif;
    font-weight: 400;
}


p,
a,
button,
input,
select,
option,
ul,
li,
table,
td,
tr,
th,
tbody,
thead,
div,
header,
section,
aside,
figure,
label,
textarea,
::placeholder {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
}

span{
    font-family: 'Inter', sans-serif;
    font-weight: 700;
}