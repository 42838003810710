@import '../vars';
@import '../reset';
@import '../breakpoints';

input[type="file"] {
    display: none;
}
body {
    margin: 0;
    font-size: 14px;
    line-height: 1.42857143;
    color: #333;
}
span {
    font-family: Lato,sans-serif;
    font-weight: 400;
}

.inner div[class^="single"] .add_to_favorites{
    &.added .fa-heart {
        text-shadow: 0 0;
    }
    & .fa-heart {
        font-size: 2.2em;
        text-shadow: -2px 0 #D93C65, 0 2px #D93C65, 2px 0 #D93C65, 0 -2px #D93C65;
    }
    & .loading_ring div {
        width: 33px;
        height: 33px;
    }
}
.add_to_favorites {
    position: absolute;
    padding: 18px;
    z-index: 10;
    &.added .fa-heart{
        color: #D93C65;
        text-shadow: 0 0;
    }

    & .fa-heart {
        margin-top: 2px;
        cursor: pointer;
        color: white;
        font-size: 1.4em;
        text-shadow: -1px 0 #D93C65, 0 1px #D93C65, 1px 0 #D93C65, 0 -1px #D93C65;
    }
    &.disabled{
        & .loading_ring{
            display: block;
        }
        .fa-heart{
            display: none;
        }
    }
    & .loading_ring{
        display: none;
    }
    & .loading_ring div{
        width: 20px;
        height: 20px;
        margin: 0;
        border: 2px solid #bb1216;
        border-color: #bb1216 transparent transparent transparent;
    }
}

.load_more_resources{
    display: none;
}

.header{
    background-color: $primary-background-color;
    padding-bottom: 10px;
    @include media ("<=800px"){
        padding-bottom: 10px;
    }
    & .inner{
        max-width: 1100px;
    }
    .inner &__menu{
        & ul.sub-menu li .item-top-line{
            display: none;
        }
        & a{
            font-family: 'Inter', sans-serif;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
        }

        & .current_page_item .item-top-line{
            display: block;
            height: 5px;
            border-radius: 0 0 5px 5px;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            background: $secondary-background-color;
        }
        & li{
            padding-top: 10px;
            &:hover{
                //background-color: $light-background-color;
                & .item-top-line{
                    display: block;
                    height: 5px;
                    border-radius: 0 0 5px 5px;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    background: $secondary-background-color;
                }

                i:before{
                    color: white;
                }
            }
            & i:before{
                color: #9d87f1;
            }
        }
        & #menu-header-desktop-menu{
            display: none;
        }
    }
    .header__container.search_expand &__menu{
        display: none;
        transition: all ease 0.5s;
    }

    .header__container.search_expand &__search-block-form,
    .header__container.search_expand &__search{
        width: 98%;
        transition: all ease 0.5s;
    }
    .header__container.search_expand &__search-block-form input{
        max-width: none;
        width: 98%;
        transition: all ease 0.5s;
        display: flex;
    }
    &__search-block{
        &-form{
            display: flex;
            align-items: center;

            @include media ('<=800px'){
                display: none;
            }

            & input{
                background: $light-background-color;
                border-radius: 30px;
                font-size: 16px;
                font-weight: 600;
                border: none;
                color: $tetiary-background-color;
                height: 31px;
                transition: all ease 0.5s;
                padding-left: 10px;
                display: none;
                &::placeholder{
                    color: $light-background-vary-light
                }
                &:focus{
                    color: $tetiary-background-color;
                    background-color: $light-background-color;
                }
            }
            &-button{
                margin-left: -30px;
                & button{
                    background: none;
                    border: none;
                }
                & svg{
                    margin-top: 5px;
                    color: $tetiary-background-color;
                }
            }
        }
    }

    &__mobile_search{
        padding: 5px 15px 0 0;
        color: #fff;
        font-size: 20px;
        @include media ('>=800px'){
            display: none;
        }
    }

    &__inner{
        @include media ('<=tablet'){
            //padding: 0 25px;
        }
    }

    &__container{
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 70px;

        @include media ('<=800px'){
            display: grid;
            grid-template-columns: 10% 65% 10% 15%;
            justify-content: center;
            padding-top: 10px;
        }
    }

    &__logo{
        width: 74px;

        @include media ('<=800px'){
            text-align: center;
            justify-content: center;
            display: flex;
            margin: 0 auto;
            padding: 0 0 0 45px;
        }
    }


    &__menu{
        @include media('<=tablet'){
            display: none;
        }
        ul {
            display: flex;
            align-content: center;
            align-items: center;
            li{
                right: 10px;
                padding: 0 10px 10px;
                margin: 0;
                min-height: 70px;
                display: flex;
                align-items: center;
                align-content: center;
                .dropdown_icon{
                    color: #fff;
                    padding-left: 5px;
                    display: inline;
                }
                & .fa-house:before{
                    color: $secondary-background-color;
                }

                &:hover .dropdown_icon i{
                    color: $tetiary-color;
                }
                a{
                    margin: 0;
                    padding: 0;
                }
            }
        }

        // styling for the desktop sub menu
        ul.sub-menu{
            position: absolute;
            display: flex;
            flex-direction: column;
            z-index: 999;
            width: auto;
            top: 60px;
            left: calc(50% - 84px);
            white-space: nowrap;
            transition: all ease 0.5s;
            display: none;
            padding-top: 10px;
            &:before{
                font-family: "Font Awesome 6 Free";
                font-weight: 900;
                content: '\f0de';
                color:$light-background-color;
                position: absolute;
                font-size: 45px;
                margin-top: -19px;
                margin-left: 36%;
            }

            @include media ('<=1000px'){
                top: 70px;
            }

            li{
                background-color:$light-background-color;
                min-height: 45px;
                min-width: 160px;
                //border-left: 3px solid $tetiary-background-color;

                a{
                    color: $tetiary-background-color;
                    width: 100%;
                    height: 100%;
                }
        
                &:first-child{
                    // padding-top: 15px;
                }
        
                &:first-child{
                    border-radius: 7px 7px 0 0;
                }
                &:last-child{
                    border-radius: 0 0 7px 7px;
                }
        
                &:hover{
                    background-color: $light-background-vary-light;
                    //border-left: 3px solid $accent-color;
                }
        
                &:hover a{
                    color: $tetiary-color;
                }
            }
        }
        li{
            padding: 15px 15px;
            list-style-type: none;
            position: relative;
            display: flex;

            @include media ('<=990px'){
                padding: 0 10px;
            }
        }
        li:hover ul.sub-menu{
            display: block;
            transition: all ease 0.5s;
        }
        a{
            color: $tetiary-color;
            font-size: 16px;
            font-weight: 400;
            display: flex;
            justify-content: space-between;

            @include media ('<=990px'){
                font-size: 13px;
                font-weight: 500;
            }
            .mi{
                padding-left: 5px;
            }
        }

        a:hover{
            color: $tetiary-color;
        }
    }
    @include media ('<=tablet'){
        &__search_link svg{
            width: 30px;
            height: 30px;
            padding-right: 10px;
        }
    }

    &__search{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        svg{
            font-size: 32px;
        }
    }

    &__search_link{
        cursor: pointer;
        padding: 5px 6px 0px;
        background: $light-background-color;
        margin-right: 10px;
        border-radius: 5px;
        height: 32px;
        width: 32px;
        @include media ('<=tablet'){
            padding-top: 2px;
        }
    }

    &__mobile_menu{
        z-index: 999;
        position: absolute;
        background-color: #8354d4;
        top: 60px;
        left: -100%;
        margin: 0;
        width: 100%;
        height: 100vh;
        transition: all ease 0.5s;
        overflow: auto;
        box-shadow: -11px 10px 12px rgb(105, 105, 105);
        max-height: 99vh;
        overflow-y: auto;

        > ul{
            position: relative;
            max-height: 100vh;
            overflow-y: auto;
        }
        ul.sub-menu{
            display: none;
            position: relative;
            overflow: auto;
            padding-top: 10px;
            li{
                width: 100%;
                margin-bottom: 7px;
                padding-left: 0;
                a{
                    font-size: 14px;
                    color: $tetiary-color;
                    font-weight: 400;
                }
            }
        }
        ul li{
            padding: 15px 15px;
            list-style-type: none;
            display: block;
            overflow: auto;
        }
        ul li a{
            font-size: 18px;
            font-weight: 600;
            color: $tetiary-color;
            display: flex;
            justify-content: flex-start;
            gap: 12px;

            & .new_drop_down.up{
                transform: rotate(180deg);
            }
        }
        &_social{
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            @include media ('<=800px'){
                margin: 20px 10px 10px;
                width: 70%;
            }
            & a{
                display: block;
                width: 40px;
                height: 40px;
                text-align: center;
                border-radius: 50%;
                border: 1px solid #c8bdf1;
                color: $tetiary-color;
                font-size: 20px;
                margin: 0 5px;
                & i{
                    margin-top: 10px;
                }
            }
        }
    }
    &__mobile_menu.show{
        left: 0;
        transition: all ease 0.5s;

        @include media ('>=800px'){
            left: -100%;
        }
    }
    &__language{
        display: block;
        @include media ('<=800px'){
            margin-right: 10px;
        }
        &-block-form-button-dropdown{
            display: none;
            & ul{
                list-style: none;
                & li div,
                & li a{
                    height: 25px;
                    margin-bottom: 5px;
                    display: flex;
                    justify-content: right;
                    align-items: center;
                    & span{
                        color: $tetiary-background-color;
                        font-weight: 400;
                        margin-right: 5px;
                    }
                }
                & li.disabled{
                    cursor: default;
                    & span{
                        color: #cabae0;
                    }
                    & img{
                        opacity: 0.5;
                    }
                }

            }
            position: absolute;
            background: $light-background-color;
            border-radius: 10px;
            text-align: right;
            padding: 5px 11px;
            margin-left: -50px;
            z-index: 1000;

        }

        &-block-form{
            & i{
                color: $tetiary-background-color;
                margin-right: 5px;
                font-size: 10px;
            }
            &-button{
                cursor: pointer;
                display: flex;
                align-items: center;
                height: 40px;
            }
            & img{
                border-radius: 50%;
            }

        }
        &:hover{
            & .header__language-block-form{
                & i:before {
                    content: "\f0d8";;
                }
            }
            & .header__language-block-form-button-dropdown{
                display: block;
            }
        }
    }
}

// style the mobile menu hamburger icon
.hamburger{
    display: none;

    @include media('<=tablet'){
        display: block;
        margin-left: 20px;
    }
    &__inner{
        span{
            i{
                font-size: 20px;
                color: $tetiary-color;
                &.fa-times{
                    font-size: 20px;
                }
            }
        }
    } 
    &__bar{
        background-size: cover;
        padding: 20px;
        background-repeat: no-repeat;
    }
}

// weird div that keeps displaying in the mobile menu, I dont know where it is coming from so this disables it
#menu-header-desktop-menu-1{
    display: none;
}

.show-sub-menu{
    display: block;
}

.inner {
    max-width: 1110px;
    margin: 0 auto;
    & .obingo_search_load_more.hide,
    & .disabled.obingo_search_load_more {
        display: none;
    }
    & .latest_guides__box_btn {
        width: 100%;
        display: flex;
        justify-content: center;
        & a{
            width: 170px;
            cursor: pointer;
            max-height: 34px;
        }
    }
}

.loading_ring {
    .disabled &{
        display: block;
    }
    & div {
        display: none;
        box-sizing: border-box;
        position: absolute;
        width: 40px;
        height: 40px;
        margin: 8px;
        border: 8px solid $accent-color;
        border-radius: 50%;
        animation: loading_ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: $accent-color transparent transparent transparent;
        transition: all .3s ease-in-out;
    }
    & div:nth-child(1) {
        animation-delay: -0.45s;
    }
    & div:nth-child(2) {
        animation-delay: -0.3s;
    }
    & div:nth-child(3) {
        animation-delay: -0.15s;
    }
}



@keyframes loading_ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.Modal__Overlay{
    & a {
        background-color: transparent;
        color: #057cb9;
        text-decoration: none;
        cursor: pointer;
        transition: color .1s ease-in-out;
    }
    & a:focus,
    & a:hover {
        color: #2bafe2;
        text-decoration: underline;
    }
    & .owl-upside-close {
        position: absolute;
        right: 21px;
        top: 18px;
        cursor: pointer;
        z-index: 10;
        & i{
            font-size: 30px;
            color: #7f8c8d;
        }
    }
    &.disabled{
        display: none;
    }
    position: fixed;
    inset: 0px;
    top: initial;
    right: initial;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    background-color: rgba(0,0,0,.7);
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    z-index: 100;
    & .checkbox,
    & .radio {
        position: relative;
        display: block;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    & .checkbox label,
    & .radio label {
        min-height: 20px;
        padding-left: 20px;
        margin-bottom: 0;
        font-weight: 400;
        cursor: pointer;
        font-size: small;
    }
    & label {
        display: inline-block;
        max-width: 100%;
        margin-bottom: 5px;
        font-weight: 400;
    }
    .modal-forgoot-popup{
        &.disabled{
            display: none;
        }
        & .password-reset-signup-links p{
            margin-top: 10px;
        }
    }
    .modal-login-popup{
        &.disabled{
            display: none;
        }
    }
    .loading-modal.disabled{
        display: none;
    }

    & a.open_login_modal{
        font-size: 18px;
        line-height: 36px;
        color: #fff;
        font-weight: 700;
        padding: 7px 15px;
        text-transform: uppercase;
        background: #89b91d;
        border-radius: 2px;
        border: 1px solid #89b91d;
        margin: 15px auto 10px;
        &:hover{
            text-decoration: none;
            cursor: pointer;
        }
    }
    .registration-popup{
        &.disabled{
            display: none;
        }
        bottom: initial;
        top: 0;
        left: 0;
        right: 0;
        margin: auto;
        max-width: 570px;
        width: auto;
        height: auto;
        border: none;
        position: relative;
        background: 0 0;
        padding: 25px;
        z-index: 500;

        &-main{
            background-color: #fff;
            border-radius: 8px;
            box-shadow: 0 0 11px 11px #00000030;
            position: relative;
            width: 100%;
            & .reg-overlay-form {
                border-radius: 5px;
                padding-left: 80px;
                padding-right: 80px;
                padding-top: 66px;
                & .checkbox{
                    margin: 15px auto 10px;
                    max-width: 364px;
                }
                & .checkbox input[type=checkbox].has-error{
                    outline: 1px solid #df455e;
                    box-shadow: 0 0 7px #ecb8be;
                }
                & .checkbox input[type=checkbox],
                & .checkbox-inline input[type=checkbox],
                & .radio input[type=radio],
                & .radio-inline input[type=radio] {
                    position: absolute;
                    margin-left: -20px;
                }
                & input[type=checkbox],
                & input[type=radio] {
                    margin: 4px 0 0;
                    line-height: normal;
                }
            }
            & .reg-overlay-form h4 {
                color: #99c225;
                font-size: 28px;
                line-height: 32px;
                font-weight: 500;
                text-transform: uppercase;
                margin-bottom: 3px;
                margin-top: 0;
                width: calc(100% - 20px);
            }
            & .reg-overlay-form h5 {
                color: #2bafe3;
                font-size: 22px;
                line-height: 25px;
                font-weight: 700;
                text-transform: uppercase;
                margin: 0;
            }
            & .reg-overlay-form-inner {
                color: #666;
                float: left;
                margin-top: 8px;
                width: 100%;
                & .reg-overlay-form-field-button {
                    text-align: center;
                    & p {
                        font-size: 18px;
                        line-height: 22px;
                        margin: 15px auto 50px;
                    }
                    & .btn-big {
                        border-radius: 3px;
                        font-size: 37px;
                        height: 82px;
                        line-height: 82px;
                        max-width: 315px;
                        width: 100%;
                        overflow: hidden;
                        text-shadow: 0 1px 3px #00000030;;
                        text-decoration: none;
                        margin: 15px auto;
                        &.grey {
                            background-color: #e5e5e5;
                        }
                    }
                    .btn-big {
                        background: #89b91d;
                        border: none;
                        border-radius: 2px;
                        color: #fff;
                        display: inline-block;
                        text-align: center;
                        text-decoration: none;
                        text-transform: uppercase;
                        user-select: none;
                    }
                }
                & .form-control-feedback{
                    color: #df455e;
                    font-size: 12px;
                    font-weight: normal;
                    line-height: normal;
                }
                & .reg-overlay-form-field {
                    margin-bottom: 5px;
                    margin-top: 20px;
                    color: #717171;
                    font-size: 18px;
                    line-height: 22px;
                    font-weight: 400;
                    position: relative;

                    & .has-success.form-control {
                        border-color: #3c763d;
                        box-shadow: 0 0 7px #ddf8dc;
                    }
                    & .has-error.form-control {
                        border-color: #df455e;
                        box-shadow: 0 0 7px #f8dce1;
                    }
                    & input[type=email],
                    & input[type=password],
                    & input[type=text] {
                        background-color: #f6f6f6;
                        height: 44px;
                        width: 100%;
                        max-width: 364px;
                        color: #222;
                        font-size: 18px;
                        line-height: 24px;
                        padding-left: 16px;
                        border-radius: 3px;
                        border: 1px solid #e1e1e1;
                        box-shadow: none;
                        font-weight: 400;
                    }
                    &::placeholder{
                        color: #7f8c8d;
                    }
                }
            }

        }
    }
    //// Login popup styles
    & .modal-message-popup,
    & .modal-conformation-popup,
    & .modal-login-group-popup{
        width: 100%;
        max-width: 320px;
        box-shadow: 0 0 11px 11px #00000030;
        &.disabled{
            display: none;
        }
        & .modal-login-main {
            position: relative;
            background-color: #fff;
            background-clip: padding-box;
            border-radius: 6px;
            outline: 0;
            box-shadow: 0 0 11px 11px #00000030;
            & .modal-body {
                position: relative;
                padding: 25px;
                & .login_title h3 {
                    color: #2bafe3;
                    font-size: 18px;
                    line-height: 22px;
                    margin: 0 0 26px;
                }
                & .login_form_box {
                    padding: 0 0 20px;
                    position: relative;
                    border-radius: 3px;
                    overflow: hidden;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    &  .icon_user {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 44px;
                        height: 44px;
                        line-height: 45px;
                        text-align: right;
                        background: #279fdc;
                        padding-top: 6px;
                        border-radius: 3px 0 0 3px;
                        & img{
                            vertical-align: middle;
                            max-width: 100%;
                            height: auto;
                        }
                    }
                    & input {
                        font-size: 16px;
                        color: #4c4c4c;
                        height: 44px;
                        border: 1px solid #e4e4e4;
                        padding: 0 15px 0 59px;
                        border-radius: 3px;
                        background: #f6f6f6;
                        width: 100%;
                    }
                    &.forgooten {
                        font-size: 12px;
                        color: #777;
                        a:hover{
                            cursor: pointer;
                            color: #626262;
                            text-decoration: underline;
                        }
                    }
                    & input[type=button],
                    & input[type=submit] {
                        font-size: 18px;
                        line-height: 36px;
                        color: #fff;
                        font-weight: 700;
                        padding: 0 15px;
                        background: #89b91d;
                        border-radius: 2px;
                        width: 120px;
                        height: 36px;
                        border: 1px solid #89b91d;
                        float: right;
                        margin-left: auto;
                        cursor: pointer;
                        &:hover{
                            background: #8dc015;
                        }
                    }

                }
                .password-reset-signup-links {
                    height: 36px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    width: 150px;
                }
            }
        }
    }


}

