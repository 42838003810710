@import '../vars';
@import '../reset';
@import '../breakpoints';

// Stylesheet for the sitemap page template

.sitemap_main{
    background-color: $tetiary-background-color;
    padding-bottom: 50px;
    &__inner {
      max-width: 1100px;
      padding: 0 20px;
      margin: 0 auto;
    }
    &__banner{
        position: relative;
        background-image: url('/wp-content/themes/gecko/img/sitemap/posts/Background-Posts-Desktop.png');
        background-repeat: no-repeat;
        background-position: bottom;
        background-size: cover;
        padding-bottom: 100px;
        margin-bottom: 100px;
        object-fit: cover;

        @include media ('<=600px'){
            padding-bottom: 50px;
            background-image: url('/wp-content/themes/gecko/img/sitemap/posts/Background-Posts-Mobile.png')
        }
    }

    &__banner_container{
        padding: 20px 0 0 0;

        @include media ('<=600px'){
            padding: 0;
        }
    }

    &__banner_breadcrumb{
        padding: 10px 0;
        font-weight: 400;

        @include media ('<=tablet'){
            font-size: 14px;
        }

        span.separator{
            color: $secondary-background-color;
        }

        a, span{
            font-weight: 500;
            color: $quatenary-background-color;
        }
    }

    &__banner_title{
        padding: 20px 0 0 0;
        color: $quatenary-background-color;

        @include media ('<=800px'){
            padding: 0;
        }
        h1{
            @include media ('<=tablet'){
                font-size: 45px;
            }
            font-weight: 900;
            font-size: 50px;
        }
    }

    &__banner_picture{
        position: absolute;
        right: 18%;
        bottom: -18%;

        @include media ('<=600px'){
          display: flex;
          justify-content: center;
        }
        
        img{
            width: 45%;

            @include media ('<=600px'){
                width: 55%;
            }
        }

        @include media ('<=1250px'){
            right: -55px;
        }

        @include media ('<=800px'){
            right: 0;
        }

        @include media ('<=600px'){
            bottom: -17%;
        }
    }

    &__content_section_title{
        font-size: 25px;
        padding-bottom: 10px;
        padding-left: 35px;
    }

    &__content_section_div{
        background-color: $quatenary-background-color;
        padding: 40px 10px 40px 50px;
        display: grid;
        grid-template-columns: repeat(4, 23%);
        justify-content: space-between;
        margin-bottom: 30px;
        border-radius: 10px;
        max-height: 400px;
        overflow-y: auto;

        &::-webkit-scrollbar-thumb{
          background-color: #925EDB;
          border-radius: 10px;
        }
        &::-webkit-scrollbar {
            width: 10px;
            height: 5px;
        }
        &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 5px #fff; 
            border-radius: 10px;
        }
        &::-webkit-scrollbar-thumb:hover {
            background: #925EDB; 
        }

        @include media('<=800px'){
            grid-template-columns: repeat(3, 32%);
        }

        @include media('<=600px'){
            grid-template-columns: repeat(2, 50%);
        }

        @include media('<=400px'){
            grid-template-columns: 100%;
        }
    }

    &__content_section_li{
        padding-bottom: 5px;

        &::marker{
            color: $primary-background-color;
        }
    }

    &__content_casino_link{
        font-size: 16px;
        font-weight: 500;
        text-decoration: underline;
        color: $primary-background-color;
        line-height: 25px;

        &:hover{
            color: $paragraph-text-color;
            text-decoration: none;
        }
    }
}
