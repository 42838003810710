@import '../vars';
@import '../reset';
@import '../breakpoints';

.gh-head.header {
  padding-bottom: 5px;
}

.news_banner .banner__inner {
  margin-top: 55px;
  #rev_slider_8_1_forcefullwidth {
    & rs-layer {
      font-family: Inter !important;
    }
    & rs-module [class^='fa-']:before,
    rs-module [class*=' fa-']:before {
      position: relative;
      top: -1px;
      font-size: small;
    }
    & .fa-circle.brown:before {
      color: #fcc13e;
    }
    & .fa-circle.violet:before {
      color: #864cd7;
    }
    & .fa-circle.green:before {
      color: #40c145;
    }
    & .fa-circle.red:before {
      color: #ee4266;
    }
    & #rev_slider_8_1_wrapper .hermes .tp-tab-date {
      margin-bottom: 7px;
      text-transform: uppercase;
      margin-top: 7px;
    }
    & #rev_slider_8_1_wrapper .hermes .tp-tab-title {
      font-family: Inter;
      font-size: 14px;
      font-weight: 700;
      line-height: 22px;
      text-transform: none;
    }
  }

  #rev_slider_8_1_wrapper {
    width: 100%;
  }
}

.ug-gallery-wrapper {
  min-height: 700px;

  @include media('<=800px') {
    margin: 0 !important;
  }

  .ug-theme-panel {
    top: 287px !important;

    .ug-default-button-fullscreen,
    .ug-default-button-play,
    .ug-default-button-hidepanel,
    .ug-default-button-hidepanel-tip {
      display: none !important;
    }
    .ug-strip-panel {
      background: white !important;
      .ug-strip-arrow {
        display: none !important;
      }
      .ug-thumbs-strip {
        width: 100% !important;
        left: 0 !important;
        top: 40px !important;
        &-inner {
          width: 100% !important;
          display: flex;
          justify-content: space-between;
          gap: 20px;
          .ug-thumb-wrapper {
            min-width: 256px !important;
            position: relative !important;
            left: 0 !important;
            .ug-thumb-loader,
            .ug-thumb-error,
            .ug-thumb-border-overlay,
            .ug-thumb-overlay {
              display: none !important;
            }
            img {
              height: 154px !important;
              width: 350px !important;
              left: -40px !important;
              position: relative !important;
              opacity: 1 !important;
              display: block !important;
            }
            h3 {
              font-family: Inter;
              font-size: 14px;
              font-weight: 700;
              line-height: 22px;
              letter-spacing: 0em;
              text-align: left;
            }
            p {
              font-family: Inter;
              font-size: 14px;
              font-weight: 600;
              line-height: 30px;
              letter-spacing: 0em;
              text-align: left;
            }
            @include media('<=800px') {
              h3 {
                font-size: 16px;
                font-weight: 700;
                line-height: 25px;
              }
              p {
                font-size: 12px;
                font-weight: 600;
              }
            }
          }
        }
      }
    }
    .ug-textpanel {
      top: -150px !important;
      left: 50px !important;
      height: auto !important;
      @include media('<=800px') {
        left: 0 !important;
      }
      .ug-textpanel-bg {
        background: none !important;
      }
      .ug-textpanel-textwrapper {
        position: initial !important;
        height: auto !important;
        width: 580px !important;
        color: white;
        @include media('<=800px') {
          margin: auto !important;
          width: 300px !important;
          .tag_text {
            justify-content: center;
          }
        }
        h3 {
          font-family: Inter;
          font-size: 30px;
          font-weight: 800;
          line-height: 45px;
          letter-spacing: 0em;
          text-align: left;
        }
        p {
          font-family: Inter;
          font-size: 18px;
          font-weight: 600;
          line-height: 30px;
          letter-spacing: 0em;
          text-align: left;
        }
        @include media('<=800px') {
          h3 {
            font-size: 25px;
            font-weight: 700;
            line-height: 39px;
            text-align: center;
          }
          p {
            font-size: 12px;
            font-weight: 600;
            line-height: 30px;
          }
        }
      }
    }
  }
  .ug-slider-wrapper {
    height: 360px !important;
    overflow: hidden;
    .ug-slider-inner,
    .ug-slide-wrapper,
    .ug-item-wrapper {
      height: 100% !important;
    }
    .ug-slider-control,
    .ug-slider-preloader,
    .ug-button-videoplay {
      display: none !important;
    }
    .ug-item-wrapper {
      img {
        width: 100% !important;
        height: auto !important;
        left: 0 !important;
      }
      @include media('<=800px') {
        img {
          width: auto !important;
          height: 110% !important;
          left: -25% !important;
        }
      }
    }
  }
}

.highlights_latest {
  margin: 0 auto;
  & .inner {
    padding: 55px 0 50px;
    max-width: 1110px;
    display: flex;

    @include media('<=800px') {
      display: block;
      max-width: 90%;
      .highlights,
      .latest {
        width: 100%;
      }
    }
  }
  &__headers {
    padding-left: 30px;
    border-left: 5px solid $primary-background-color;
    margin-bottom: 40px;
    & h2 {
      font-weight: 700;
      font-size: 30px;
    }
  }
  & .latest {
    width: 30%;
    margin-left: 20px;
    border-left: 1px solid #f1f1f1;

    @include media('<=800px') {
      margin-left: 0;
      border-left: none;
    }
  }
  & .highlights {
    width: 68%;
  }
  &__latest_feed {
    &_single_item {
      padding: 25px 0 25px 33px;
      display: flex;
      border-bottom: 1px solid #f1f1f1;
      & span {
        width: 33%;
        font-size: 12px;
        font-weight: 700;
        color: $background-text-color;
      }
      & strong {
        width: 66%;
        margin-left: 20px;
        font-size: 12px;
        font-weight: 600;
        color: $paragraph-text-color;
      }
    }
  }
  .highlights &__news_feed {
    display: flex;
    flex-wrap: wrap;
    &_single_item {
      flex: 50%;
      text-align: left;
      &_inner {
        display: flex;
        flex-direction: column;
        height: 595px;
        margin-right: 30px;
        margin-bottom: 30px;
        overflow: hidden;
      }

      @include media('<=800px') {
        flex: 100%;

        &_inner {
          margin-right: 0;
        }
      }
    }
    &_image {
      min-height: 350px;
      background-size: cover;
      background-position: center;
    }
    &_info {
      padding-top: 20px;
      &_tag a {
        font-size: 16px;
        font-weight: 600;
        color: $accent-text-color;
      }
      &_date {
        font-size: 16px;
        font-weight: 400;
        color: $background-text-color;
      }
      &_text {
        & h4 {
          margin-top: 20px;
          font-family: Inter;
          font-size: 18px;
          font-weight: 700;
          line-height: 28px;
          letter-spacing: 0em;
          text-align: left;
          color: #292929;
        }
        & p {
          margin-top: 10px;
          font-size: 16px;
          font-weight: 400;
          color: #292929;
        }
      }
    }
  }
}

.site_launches {
  margin: 0 auto;
  & .inner {
    padding: 0 0 50px;
    max-width: 1110px;

    @include media('<=800px') {
      max-width: 90%;
    }
  }
  &__headers {
    padding-left: 30px;
    border-left: 5px solid $primary-background-color;
    margin-bottom: 40px;
    & h2 {
      font-weight: 700;
      font-size: 30px;
    }
  }
  &__news_feed {
    display: flex;
    flex-wrap: wrap;
    &_single_item:nth-child(5) div {
      margin-right: 0;
    }
    &_single_item:nth-child(2) {
      flex: 66.66%;

      @include media('<=800px') {
        & .site_launches__news_feed_single_item_inner {
          height: 415px;
          flex-direction: column;
          gap: 18px;
        }
      }

      & .site_launches__news_feed_image {
        min-height: 415px;
      }
      & .site_launches__news_feed_info a p,
      & .site_launches__news_feed_info span:nth-child(1),
      & .site_launches__news_feed_info span:nth-child(2) {
        display: none;
      }
      & .site_launches__news_feed_info_text {
        & h4 {
          font-size: 30px;
          line-height: 50px;
          font-weight: 700;
          z-index: 2;
          position: relative;
          top: -180px;
          margin: 0 50px;
          color: $tetiary-background-color;
          text-shadow: 2px 2px 3px #0000008c;

          @include media('<=800px') {
            font-size: 23px !important;
            font-weight: 600;
            line-height: 28px !important;
            margin: 0 27px;
            text-align: center;
            font-style: italic;
          }
        }
      }
      & div {
        margin-right: 0;
      }
    }
    &_single_item {
      flex: 33.33%;
      text-align: left;
      &_inner {
        display: flex;
        flex-direction: column;
        height: 415px;
        margin-right: 30px;
        margin-bottom: 30px;
        overflow: hidden;
      }
      @include media('<=800px') {
        flex: 100% !important;
        width: 100%;
        &_inner {
          flex-direction: row;
          margin-right: 0;
          height: auto;
          margin-bottom: 20px;
          gap: 18px;
        }
      }
    }
    &_image {
      min-height: 190px;
      background-size: cover;
      background-position: center;
      @include media('<=800px') {
        min-width: 106px;
        min-height: 106px;
      }
    }
    &_info {
      padding-top: 20px;
      @include media('<=800px') {
        display: flex;
        flex-direction: column;
        padding-top: 0;

        &_tag {
          display: flex;
          flex-direction: column;
        }

        &_date {
          font-size: 10px !important;
        }
      }
      &_tag a {
        font-size: 16px;
        font-weight: 600;
        color: $accent-text-color;
      }
      &_date {
        font-size: 16px;
        font-weight: 400;
        color: $background-text-color;
      }
      &_text {
        @include media('<=800px') {
          & h4 {
            font-size: 14px !important;
            line-height: 20px !important;
            font-weight: 600 !important;
          }
          & p {
            display: none;
          }
        }
        & h4 {
          margin-top: 20px;
          font-family: Inter;
          font-size: 18px;
          font-weight: 700;
          line-height: 28px;
          letter-spacing: 0em;
          text-align: left;
          color: #292929;
        }

        & p {
          margin-top: 10px;
          font-size: 16px;
          font-weight: 400;
          color: #292929;
        }
      }
    }
  }
}

.interesting .site_launches__news_feed_single_item:nth-child(2) {
  flex: 33%;

  @include media('<=800px') {
    & .site_launches__news_feed_single_item_inner {
      height: auto;
      flex-direction: row;
      gap: 18px;
    }
  }

  & .site_launches__news_feed_image {
    min-height: 190px;

    @include media('<=800px') {
      min-width: 106px;
      min-height: 106px;
    }
  }

  & .site_launches__news_feed_info a p,
  & .site_launches__news_feed_info span:nth-child(1),
  & .site_launches__news_feed_info span:nth-child(2) {
    display: flex;
  }

  & .site_launches__news_feed_info_text h4 {
    position: unset;
    margin-top: 20px;
    font-family: Inter;
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    color: #292929;
    text-shadow: none;
    margin: 20px 0 0;

    @include media('<=800px') {
      font-size: 14px !important;
      line-height: 20px !important;
      font-weight: 600 !important;
      font-style: normal !important;
    }
  }

  & div {
    margin-right: 15px;

    @include media('<=800px') {
      margin-right: 0;
    }
  }
}

.most-popular {
  margin: 50px auto;
  & .inner {
    box-shadow: #0000003f 5px 5px 15px 0;
    border-radius: 20px;
    padding: 55px 50px 50px;
    max-width: 1110px;

    @include media('<=800px') {
      max-width: 90%;
    }
  }
  &__headers {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 20px;
  }
  &__news_feed {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 25px;
    &_single_item {
      text-align: left;
      &_inner {
        display: flex;
        flex-direction: row;
        border-bottom: 1px solid #e8e8e8;
        padding-bottom: 24px;
      }
    }
    &_image {
      border-radius: 5px;
      height: 100px;
      min-width: 100px;
      background-size: cover;
      background-position: center;
    }
    &_info {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-left: 15px;
      &_text {
        flex: 0 1 100%;
        & h4 {
          font-size: 16px;
          font-weight: 500;
          text-decoration: none;
          color: #18191f;
          &:hover {
            color: #737b7d;
          }
        }
      }
      &_date,
      &_name {
        font-size: 10px;
        font-weight: 400;
        color: #18191f;
        margin-top: 10px;
      }
      &_name {
        margin-left: 15px;
        & a {
          color: #737b7d;
          &:hover {
            text-decoration: underline;
          }
        }
        color: #737b7d;
      }
    }
  }
}

.tag_list {
  margin: 0 auto;
  & .inner {
    padding: 0 0 50px;
    max-width: 1110px;
    display: flex;

    @include media('<=800px') {
      flex-direction: column;
      max-width: 90%;
    }
  }
  &__headers_title {
    margin-bottom: 30px;
    margin-right: 30px;
    font-weight: 700;
    font-size: 25px;
    border-bottom: 2px solid #f1f1f1;
    padding-bottom: 20px;
    color: black;
    @include media('<=800px') {
      border: none;
    }
    & svg {
      margin-left: 15px;
    }
  }
  & .tags {
    width: 100%;
  }
  .tags &__news_feed {
    display: flex;
    flex-wrap: wrap;

    &_banner {
      position: relative;
      margin-bottom: 30px;
      & span {
        position: absolute;
        margin-top: 14%;
        margin-left: 40px;
        font-family: Inter;
        font-size: 30px;
        font-weight: 700;
        line-height: 45px;
        color: #f1f1f1;
        max-width: 35%;
        text-shadow: 2px 2px 3px #0000008c;

        @include media('<=800px') {
          max-width: 100%;
          font-size: 23px !important;
          font-weight: 600;
          line-height: 28px !important;
          text-align: center;
          font-style: italic;
          margin: 0;
          bottom: 20px;
          width: 90%;
          left: 50%;
          transform: translate(-50%, 0);
        }
      }
      & img {
        width: 766px;

        @include media('<=800px') {
          width: 100%;
        }
      }
    }
    &_single_item {
      flex: 50%;
      text-align: left;
      margin-bottom: 10px;

      @include media('<=800px') {
        flex: 100%;

        &_inner {
          margin-right: 0 !important;
        }

        &_more {
          margin-right: 0 !important;
        }
      }

      &_inner {
        display: flex;
        flex-direction: column;
        height: 389px;
        margin-right: 30px;
        margin-bottom: 30px;
        overflow: hidden;
        @include media('<=800px') {
          height: auto;
          margin-bottom: 5px;
        }
      }
      &_more {
        height: 80px;
        border-top: 2px solid #f1f1f1;
        margin-right: 30px;
        display: flex;
        align-items: center;
        @include media('<=800px') {
          height: 70px;
        }
        & h4 {
          font-family: Inter;
          font-size: 14px;
          font-weight: 600;
          color: $paragraph-text-color;
          text-decoration: none;
        }
      }
      &_more:nth-child(5) {
        margin-bottom: 30px;
      }
    }
    &_image {
      min-height: 202px;
      background-size: cover;
      background-position: center;
    }
    &_info {
      &_tag a {
        font-size: 16px;
        font-weight: 600;
        color: $accent-text-color;
      }
      &_date {
        font-size: 16px;
        font-weight: 400;
        color: $background-text-color;
      }
      &_text {
        @include media('<=800px') {
          & a p {
            display: none;
          }
          & p {
            display: none;
          }
          & h4 {
            font-size: 16px !important;
            line-height: 26px !important;
          }
        }
        & a {
          color: #292929;
        }
        & h4 {
          margin-top: 20px;
          margin-bottom: 15px;
          font-family: Inter;
          font-size: 18px;
          font-weight: 700;
          line-height: 28px;
          letter-spacing: 0em;
          text-align: left;
          color: #292929;
        }
        & p {
          font-size: 16px;
          font-weight: 400;
          color: #292929;
        }
      }
    }
  }
  .choice &__headers {
    padding-left: 30px;
    border-left: 5px solid $primary-background-color;
    margin-bottom: 40px;
    margin-bottom: 0;
    & h2 {
      font-weight: 700;
      font-size: 30px;
      padding-bottom: 15px;
    }
  }
  .choice &__news_feed {
    display: flex;
    flex-direction: column;
    border-left: 2px solid #f1f1f1;

    @include media('<=800px') {
      flex-direction: row;
      overflow: auto;
      border: none;

      &_single_item {
        min-width: 243px;
        margin: 0 15px 0 0 !important;
      }
    }

    &_single_item {
      width: 275px;
      text-align: left;
      margin: 0 0 15px 37px;
      &_inner {
        display: flex;
        flex-direction: column;
        overflow: hidden;
      }
    }
    &_image {
      height: 255px;
      max-width: 280px;
      background-size: cover;
      background-position: center;
    }
    &_info {
      margin-top: 5px;
      &_tag a {
        font-size: 16px;
        font-weight: 600;
        color: $accent-text-color;
      }
      &_text {
        & h4 {
          margin-top: 10px;
          font-family: Inter;
          font-size: 18px;
          font-weight: 700;
          line-height: 28px;
          color: #292929;
        }
        & p {
          margin-top: 10px;
          font-size: 16px;
          font-weight: 400;
          color: #292929;
        }
      }
    }
  }
}

.guides-section {
  margin: 0 auto;
  & .nav-tab {
    font-size: 20px;
    font-weight: 600;
  }
  & .nav-tab.nav-tab-active {
    border: none;
    color: $paragraph-text-color;

    @include media('<=800px') {
      background: #864cd7;
      color: white;
      border-radius: 84px;
    }
  }
  & .guides-nav-tab-wrapper {
    display: flex;
    overflow: auto;
  }
  & .inner {
    padding: 0 0 50px;
    max-width: 1110px;

    @include media('<=800px') {
      max-width: 90%;
    }
  }
  &__headers {
    padding-left: 30px;
    border-left: 5px solid $primary-background-color;
    margin-bottom: 40px;
    & h2 {
      font-weight: 700;
      font-size: 30px;
    }
  }
  &__guides_feed {
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    &_single_item {
      flex: 33%;
      text-align: left;

      @include media('<=800px') {
        flex: 100% !important;
        width: 100%;
        &_inner {
          flex-direction: row !important;
          margin-right: 0 !important;
          height: auto;
          margin-bottom: 20px;
          gap: 18px;
        }
        &_info p {
          display: none;
        }
      }

      &_inner {
        display: flex;
        flex-direction: column;
        margin-right: 30px;
        margin-bottom: 30px;
        overflow: hidden;
      }
      &_image {
        min-height: 202px;
        background-size: cover;
        background-position: center;

        @include media('<=800px') {
          min-width: 106px;
          min-height: 106px;
        }
      }
      &_info a {
        @include media('<=800px') {
          & h4 {
            margin-top: 0 !important;
          }
          & span {
            display: none;
          }
        }
        & h4 {
          margin: 20px 0 15px;
          font-family: Inter;
          font-size: 18px;
          font-weight: 700;
          line-height: 28px;
          letter-spacing: 0em;
          text-align: left;
          color: #292929;
        }
        & span {
          font-family: Inter;
          font-size: 16px;
          font-weight: 400;
          line-height: 23px;
          letter-spacing: 0em;
          text-align: left;
          color: #292929;
        }
      }
    }
    &_single_item:nth-child(1) {
      flex: 66%;

      @include media('<=800px') {
        & .guides-section__guides_feed_single_item_inner {
          flex-direction: column !important;
          gap: 18px;
        }
      }

      & .guides-section__guides_feed_single_item_image {
        min-height: 476px;
      }
      & .guides-section__guides_feed_single_item_info {
        & a h4 {
          font-size: 30px;
          line-height: 50px;
          font-weight: 700;
          z-index: 2;
          position: relative;
          top: -180px;
          margin: 0 50px;
          color: $tetiary-background-color;
          text-shadow: 2px 2px 3px #0000008c;

          @include media('<=800px') {
            font-size: 23px;
            font-weight: 600;
            line-height: 28px;
            max-width: 100%;
            margin-left: 0;
            margin: 0 27px;
            text-align: center;
            font-style: italic;
          }
        }
        & a span {
          display: none;
        }
      }
    }
  }
}

.news_card {
  margin: 0 auto;
  & .inner {
    padding: 30px 0 50px;
    max-width: 1110px;
    display: flex;
    flex-wrap: wrap;

    @include media('<=1100px') {
      max-width: 90%;
    }

    & .news {
      flex: 60%;
      @include media('<=1100px') {
        flex: 100%;
      }
    }

    & .latest {
      flex: 40%;
      padding-left: 50px;
      @include media('<=1100px') {
        flex: 100%;
        margin-top: 50px;
        padding-left: 0;
      }
    }
  }

  &__headers {
    margin-bottom: 20px;
    & h1 {
      font-family: Inter;
      font-size: 35px;
      font-weight: 700;
      line-height: 47px;
      letter-spacing: 0em;
      text-align: left;
      @include media('<=1100px') {
        font-size: 23px;
        line-height: 33px;
      }
    }
  }
  &__news_tag a {
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    line-height: 30px;
    color: $paragraph-text-color;
  }
  &__news_date {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 30px;
    color: $background-text-color;
  }
  &__news {
    margin-top: 20px;
    &_content {
      & p {
        margin: 15px auto;
        font-family: Inter;
        font-size: 16px;
        font-weight: 400;
        line-height: 23px;
        letter-spacing: 0em;
        text-align: left;
      }
      & img {
        width: 100% !important;
        height: auto !important;
      }
      & a {
        color: $accent-color;
        font-weight: 600;
        text-decoration: underline;
      }
      & h2 {
        span {
          color: #292929 !important;
        }
      }
    }
    &_author.bottom {
      display: grid;

      & .news_card__news_author_icon,
      & .news_card__news_author_info {
        display: block;
      }
    }
    &_author {
      margin: 20px auto;
      display: grid;
      grid-template-columns: 80px 36% 50%;
      align-items: center;
      justify-content: space-between;
      @include media('<=1100px') {
        display: flex;
      }
      &_social {
        text-align: right;
        @include media('<=1100px') {
          width: 100%;
          display: flex;
          justify-content: space-between;
        }
      }
      & .author-name {
        font-family: Inter;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        color: $paragraph-text-color;
      }
      &_info {
        @include media('<=1100px') {
          display: none;
        }
        &_date {
          font-family: Inter;
          font-size: 14px;
          font-weight: 500;
          line-height: 30px;
          letter-spacing: 0em;
          text-align: left;
          color: $background-text-color;
          & .fa-circle {
            font-size: 0.5em;
            line-height: 0.2em;
            vertical-align: 0.2em;
            margin: 0 10px;
          }
        }
        &_date {
          font-family: Inter;
          font-size: 14px;
          font-weight: 500;
          line-height: 30px;
          letter-spacing: 0em;
          text-align: left;
          color: $background-text-color;
          & .fa-circle {
            font-size: 0.5em;
            line-height: 0.2em;
            vertical-align: 0.2em;
            margin: 0 10px;
          }
        }
      }

      &_icon {
        @include media('<=1100px') {
          display: none;
        }
        .bottom & img {
          border: 4px solid $tetiary-color;
          box-shadow: #0000003f 3px 3px 20px 0;
        }
        & img {
          width: 65px;
          height: 65px;
          border-radius: 100px;
          object-fit: cover;
        }
        & svg {
          background: $background-text-color;
        }
      }
    }
    &_content {
      & h1,
      h2,
      h3 {
        font-family: Inter;
        font-size: 20px;
        font-weight: 600;
        line-height: 23px;
        letter-spacing: 0em;
        text-align: left;
      }
    }
  }
  &__news_image img {
    width: 100%;
  }

  .latest &__headers {
    padding-left: 30px;
    border-left: 5px solid $primary-background-color;
    margin-bottom: 40px;
    & h2 {
      font-weight: 700;
      font-size: 30px;
    }
  }
  .latest &__latest_feed {
    display: flex;
    flex-direction: column;
    border-left: 2px solid #f1f1f1;
    @include media('<=1100px') {
      border: none;
    }
    &_single_item {
      width: 275px;
      text-align: left;
      margin: 0 0 20px 37px;
      padding-bottom: 20px;
      border-bottom: 2px solid #f1f1f1;
      @include media('<=1100px') {
        margin-left: 0;
      }
      &_inner {
        display: flex;
        flex-direction: row;
        overflow: hidden;
      }
    }
    &_image {
      height: 74px;
      min-width: 86px;
      background-size: cover;
      background-position: center;
    }
    &_date {
      font-family: Inter;
      font-size: 12px;
      font-weight: 400;
      line-height: 10px;
      letter-spacing: 0em;
      text-align: left;
      color: $background-text-color;
    }
    &_info {
      margin-left: 15px;
      &_text {
        & h4 {
          font-family: Inter;
          font-size: 14px;
          font-weight: 600;
          line-height: 18px;
          letter-spacing: 0em;
          text-align: left;
          color: #292929;
        }
      }
    }
  }
}

.guides_feed {
  margin: 40px auto;
  & .inner {
    display: flex;
    flex-wrap: wrap;
    padding: 0 20px;
  }
  &__headers {
    margin-bottom: 40px;
    & h2 {
      font-weight: 700;
      font-size: 30px;
      margin-bottom: 5px;
    }
    & p {
      font-family: Inter;
      font-size: 18px;
      font-weight: 400;
      line-height: 38px;
      text-align: left;
      @include media('<=800px') {
        font-size: 16px;
        line-height: 23px;
      }
      & a {
        color: $primary-background-color;
        text-decoration: underline;
      }
    }
  }
  &__categories {
    flex: 30%;
    @include media('<=800px') {
      flex: 100%;
      order: 2;
    }
    &_headers {
      border-left: 5px solid #8354d4;
      text-align: left;
      padding-left: 20px;
      margin-bottom: 20px;
      display: block;
      &_title {
        font-family: Inter;
        font-size: 28px;
        font-weight: 700;
        margin: 5px 0;
      }
    }
    &_item {
      padding: 15px 0 15px 33px;
      display: flex;
      border-bottom: 1px solid #f1f1f1;
      flex-direction: row;
      font-family: Inter;
      font-size: 16px;
      font-weight: 400;
      line-height: 30px;
      color: $primary-background-color;
      @include media('<=800px') {
        display: inline-block !important;
        padding: 0 10px 0;
      }
    }
  }
  &__single_item {
    text-align: left;
    &_inner {
      display: flex;
      flex-direction: row;
      margin-right: 30px;
      margin-bottom: 30px;
      overflow: hidden;
    }
    &_image {
      min-width: 254px;
      min-height: 231px;
      background-size: cover;
      background-position: center;
    }
    &_info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 20px 10px 20px 30px;
      border: 1px solid #f1f1f1;
      &_title h4 {
        font-family: Inter;
        font-size: 18px;
        font-weight: 700;
        line-height: 30px;
        color: $paragraph-text-color;
      }
      &_date,
      &_text {
        & a {
          text-decoration: underline;
          color: $primary-background-color;
        }
        font-family: Inter;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        color: $paragraph-text-color;
      }
    }
  }
  &__feed {
    flex: 70%;
    @include media('<=800px') {
      flex: 100%;
      order: 0;
    }
  }
  & .pagination {
    @include media('<=800px') {
      margin-bottom: 20px;
    }
  }
}

.tag_feed {
  margin: 40px auto;
  display: flex;
  flex-wrap: wrap;
  border-left: 1px solid #f1f1f1;
  @include media('<=800px') {
    flex-wrap: wrap-reverse;
    border: none;
  }
  .news &__headers {
    padding-left: 30px;
    border-left: 5px solid $primary-background-color;
    margin-bottom: 40px;
    & h2 {
      font-weight: 700;
      font-size: 30px;
    }
  }
  &__news {
    flex: 30%;
    @include media('<=800px') {
      flex: 100%;
      padding: 0 20px;
    }
  }
  &__feed {
    flex: 70%;
    padding-left: 50px;
    @include media('<=800px') {
      flex: 100%;
      padding: 0 20px;
    }
  }
  &__headers {
    margin-bottom: 40px;
    & h2 {
      font-weight: 700;
      font-size: 30px;
    }
  }
  &__latest_feed {
    &_single_item {
      padding: 25px 0 25px 33px;
      display: flex;
      border-bottom: 1px solid #f1f1f1;
      flex-direction: row;
      @include media('<=800px') {
        padding-left: 0;
      }
      & span {
        width: 33%;
        font-size: 12px;
        font-weight: 700;
        color: $background-text-color;
      }
      & strong {
        width: 66%;
        margin-left: 20px;
        font-size: 12px;
        font-weight: 600;
        color: $paragraph-text-color;
      }
    }
  }
  &__single_item {
    text-align: left;
    &_inner {
      display: flex;
      flex-direction: row;
      margin-right: 30px;
      margin-bottom: 30px;
      overflow: hidden;
      @include media('<=800px') {
        flex-direction: column;
        margin-right: 0;
      }
    }
    &_image {
      min-width: 254px;
      min-height: 231px;
      background-size: cover;
      background-position: center;
    }
    &_info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 20px 45px 20px 30px;
      border: 1px solid #f1f1f1;
      @include media('<=800px') {
        padding: 20px 22px 20px 22px;
        gap: 12px;
      }
      &_title h4 {
        font-family: Inter;
        font-size: 18px;
        font-weight: 700;
        line-height: 30px;
        color: $paragraph-text-color;
      }
      &_date,
      &_text {
        & a {
          text-decoration: underline;
          color: $primary-background-color;
        }
        font-family: Inter;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        color: $paragraph-text-color;
      }
    }
  }
}


.article {
  @include media('<=800px') {
    .site_launches__news_feed {
      &_single_item_inner {
        flex-direction: column !important;
      }
      &_image {
        min-height: 180px !important;
      }
      &_info {
        &_date {
          display: none !important;
        }
        &_text {
          font-size: 16px !important;
        }
      }
    }
  }
}
