@import '../vars';
@import '../reset';
@import '../breakpoints';

/// it helps to adjust search bar
#wpadminbar{
  //display: none;
}

.search__feed.guides {
  padding: 0;
  & .guides {
    @include media ('<=tablet'){
      padding: 0 20px;
      &__list{
        display: grid;
        grid-template-columns: 100%;
        grid-gap: 5px;
      }
    }

    & .inner {
      padding-bottom: 20px;
    }
    &__item img{
      margin-bottom: 10px;
      width: 100%;
    }

    &__title {
      padding: 30px 0 10px;
    }

    & .our-picks__casino {
      &_single {
        box-shadow: 0 0 8px rgb(196, 196, 196);
        border-radius: 7px;
        padding: 20px 20px 0 20px;
      }

      &_first,
      &_descr,
      &_btns {
        padding-top: 10px;
      }
    }
  }
}

.search {
  &__header {
    background-color: $secondary-background-color;
    padding: 36px 0;
  }

  &__result{
    width: 100%;
    ul {
      display: flex;
      flex-direction: row;
      align-items: stretch;
      justify-content: flex-start;
      margin: 0;
      padding: 0;

      & li {
        list-style-type: none
      }
  }
}

  &__result-link {
    display: block;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    border-bottom: 3px solid transparent;
    padding: 15px 15px;
    color: #151515;
    text-decoration: none;
    transition: all .3s ease-in-out;

    &:hover {
      border-color: #f73e42;
      text-decoration: none;
      color: #151515
    }

    &.active {
      border-color: #f73e42;
      text-decoration: none;
      color: #151515
    }
  }

  &__result {
    background-color: #fff;
    box-shadow: 0 0 25.0476px rgba(0, 0, 0, .0425185), 0 0 12.7787px rgba(0, 0, 0, .035), 0 0 6.40753px rgba(0, 0, 0, .0274815), 0 0 3.09455px rgba(0, 0, 0, .0168519);
    padding: 0 15px
  }

  @include media ('<=tablet'){
    &__result ul{
      display: flex;
      justify-content: space-evenly;
      & li a{
        height: 2.5em;
        font-size: 1.1em;
        padding: 0.7em 0;
      }
    }
    &__header {
      padding: 40px 20px
    }

    &__title {
      font-size: 28px;
      margin-bottom: 15px
    }

    &__result {
      padding: 0
    }

    &__result-link {
      font-size: 14px;
      line-height: 16px;
      padding: 10px
    }
  }
}

