@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@300;500;600;800&display=swap");
@import url("https://fonts.cdnfonts.com/css/gilroy-bold");
h1, h2 {
  font-family: 'Inter', sans-serif;
  font-weight: 700; }

h3, h4 {
  font-family: 'Inter', sans-serif;
  font-weight: 400; }

p,
a,
button,
input,
select,
option,
ul,
li,
table,
td,
tr,
th,
tbody,
thead,
div,
header,
section,
aside,
figure,
label,
textarea,
::placeholder {
  font-family: 'Inter', sans-serif;
  font-weight: 400; }

span {
  font-family: 'Inter', sans-serif;
  font-weight: 700; }

/**
*
* Testing
*
**/
.expandable {
  overflow: hidden;
  transition: all 0.5s ease-in-out;
  height: 0; }

.expand .expandable {
  display: flex;
  height: 435px;
  transition: all 0.5s ease-in-out; }
  @media (max-width: 800px) {
    .expand .expandable {
      height: auto; } }

.breadcrumb.ghost {
  margin: 20px auto;
  padding-left: 0; }
  @media (max-width: 1100px) {
    .breadcrumb.ghost {
      max-width: 90%; } }
  .breadcrumb.ghost .breadcrumb-item {
    display: flex;
    position: relative;
    white-space: nowrap; }
    .breadcrumb.ghost .breadcrumb-item-current {
      overflow: hidden;
      text-overflow: ellipsis;
      display: block; }
  .breadcrumb.ghost .breadcrumb-item > * {
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0px;
    text-align: left;
    color: #4b4b4b; }
  .breadcrumb.ghost .separator {
    color: #FCC13E;
    margin-left: 8px; }

.site-content .breadcrumb li + li::before {
  content: '';
  margin: 0 5px;
  opacity: 1; }

.star-rating {
  padding-bottom: 5px; }

.star-rating-text {
  font-size: 16px;
  font-weight: 700;
  color: #292929;
  padding-left: 10px;
  padding-top: 5px; }
  .star-rating-text span {
    color: #7f8c8d;
    font-weight: 400; }

.star-rating .star-full:before {
  content: '\f155'; }

.star-rating .star-half:before {
  content: '\f459'; }

.star-rating .star-empty:before {
  content: '\f154'; }

.star-rating .star {
  color: #FCC13E;
  display: inline-block;
  font-family: dashicons;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  height: 20px;
  text-align: center;
  text-decoration: inherit;
  vertical-align: top;
  width: 20px; }

/*

.inner .kk-star-ratings.kksr-disabled .kksr-stars .kksr-stars-active .kksr-star .kksr-icon,
.inner .kk-star-ratings:not(.kksr-disabled) .kksr-stars:hover .kksr-star .kksr-icon{
    background-image: url("/wp-content/themes/gecko/img/selected.svg");
}
*/
/*  NEWS CATEGORY FEED */
.news-section-category {
  margin: 50px auto; }
  .news-section-category .inner {
    box-shadow: #0000003f 5px 5px 15px 0;
    border-radius: 20px;
    padding: 55px 50px 40px;
    max-width: 1110px; }
  .news-section-category__headers {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 20px; }
  .news-section-category__news_feed {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    grid-gap: 25px; }
    .news-section-category__news_feed_single_item {
      text-align: left; }
      .news-section-category__news_feed_single_item_inner {
        height: 100%; }
      .news-section-category__news_feed_single_item:first-child {
        grid-row: 1 / 3;
        grid-column: 1; }
        .news-section-category__news_feed_single_item:first-child .news-section-category__news_feed_info {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          gap: 0 20px; }
          .news-section-category__news_feed_single_item:first-child .news-section-category__news_feed_info_date, .news-section-category__news_feed_single_item:first-child .news-section-category__news_feed_info_name {
            order: unset; }
          .news-section-category__news_feed_single_item:first-child .news-section-category__news_feed_info_text {
            flex: 0 1 100%; }
            .news-section-category__news_feed_single_item:first-child .news-section-category__news_feed_info_text h4 {
              font-size: 22px; }
        .news-section-category__news_feed_single_item:first-child .news-section-category__news_feed_image {
          height: 460px;
          min-width: 460px; }
    .news-section-category__news_feed_image {
      border-radius: 5px;
      height: 60%;
      background-size: cover;
      background-position: center; }
    .news-section-category__news_feed_info {
      display: flex;
      flex-direction: column;
      min-height: 110px;
      align-items: baseline; }
      .news-section-category__news_feed_info_text h4 {
        font-size: 14px;
        font-weight: 600;
        text-decoration: none;
        color: #18191f;
        margin-top: 15px;
        text-align: left; }
        .news-section-category__news_feed_info_text h4:hover {
          color: #737b7d; }
      .news-section-category__news_feed_info_date, .news-section-category__news_feed_info_name {
        font-size: 12px;
        font-weight: 400;
        color: #18191f;
        margin-top: 10px; }
      .news-section-category__news_feed_info_name {
        color: #737b7d; }
        .news-section-category__news_feed_info_name a {
          color: #737b7d; }
          .news-section-category__news_feed_info_name a:hover {
            text-decoration: underline; }
      .news-section-category__news_feed_info span:nth-child(2) {
        order: -1; }

/* END OF NEWS CATEGORY FEED */
/*   TABS STYLE  */
.nav-tab {
  color: #7f8c8d;
  text-align: center;
  font-size: 20px;
  padding: 5px 20px;
  white-space: nowrap;
  line-height: 32px; }

.nav-tab:hover {
  color: #585858; }

.nav-tab.nav-tab-active {
  border-bottom: 4px solid #8354D4;
  color: #8354D4; }

.tab-target {
  display: none;
  position: relative; }

.tab-target.tab-target-active {
  display: flex;
  justify-content: space-between;
  flex-direction: column; }

/*   END TABS STYLE  */
.round_button {
  padding: 15px 35px;
  text-align: center;
  border-radius: 30px;
  color: #FFFFFF;
  background-color: #8354D4;
  cursor: pointer; }

.inner {
  max-width: 1110px;
  margin: 0 auto; }

.breadcrumbs {
  font-family: Inter;
  font-size: 12px;
  font-weight: 400; }
  .breadcrumbs p a {
    color: #FFFFFF;
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    text-decoration: none; }
  .breadcrumbs span.last {
    color: #FFFFFF;
    font-family: Inter;
    font-size: 12px;
    font-weight: 400; }
  .breadcrumbs span.separator {
    color: #FCC13E;
    font-family: Inter;
    font-size: 12px;
    font-weight: 400; }

.section_title_repeat {
  text-align: center;
  font-size: 30px;
  font-weight: 800;
  line-height: 60px; }
  @media (max-width: 1366px) {
    .section_title_repeat {
      font-size: 30px; } }
  @media (max-width: 768px) {
    .section_title_repeat {
      font-size: 25px;
      line-height: 30px; } }

.section_paragraph_repeat {
  max-width: 700px;
  text-align: center;
  margin: 0 auto;
  line-height: 28px;
  font-weight: 500;
  font-size: 16px; }
  @media (max-width: 1366px) {
    .section_paragraph_repeat {
      padding: 0 30px; } }

.banner-title-repeat {
  font-size: 34px;
  line-height: 35px;
  padding-bottom: 10px; }

.banner-paragraph-repeat {
  font-size: 16px;
  line-height: 24px; }

.section_padding_repeat {
  padding: 70px 0 50px; }

.related_casinos {
  padding: 40px 0; }
  .related_casinos__container {
    max-width: 1000px;
    margin: 0 auto; }
    @media (max-width: 1366px) {
      .related_casinos__container {
        padding: 0 20px; } }
  .related_casinos__box {
    display: grid;
    grid-template-columns: 30% 30% 30%;
    grid-gap: 20px;
    justify-content: flex-start;
    padding: 30px 0; }
    @media (max-width: 850px) {
      .related_casinos__box {
        grid-template-columns: 50% 50%;
        padding-right: 20px; } }
    @media (max-width: 560px) {
      .related_casinos__box {
        grid-template-columns: 70%;
        justify-content: center; } }
    @media (max-width: 420px) {
      .related_casinos__box {
        grid-template-columns: 100%;
        padding-right: 0; } }
  .related_casinos__header {
    font-size: 30px;
    text-transform: capitalize;
    text-align: left; }
  .related_casinos__single {
    background-color: #FFFFFF;
    padding: 20px;
    border-radius: 20px; }
  .related_casinos__first_row {
    display: grid;
    grid-template-columns: 20% 80%; }
  .related_casinos__thumb {
    display: flex;
    justify-content: center;
    align-items: center; }
    .related_casinos__thumb img {
      width: 80%;
      border-radius: 50%; }
  .related_casinos__preview_text {
    font-weight: 700;
    font-size: 15px;
    padding: 10px 0; }
  .related_casinos__second_row {
    font-size: 12px;
    display: grid;
    grid-template-columns: 50% 50%;
    justify-content: center;
    grid-gap: 20px 10px;
    text-align: center; }
    @media (max-width: 360px) {
      .related_casinos__second_row {
        display: grid;
        grid-template-columns: 100%;
        grid-gap: 5px; } }
    .related_casinos__second_row a:first-child {
      color: #FFFFFF;
      background: #8354D4;
      padding: 12px 14px;
      border-radius: 20px;
      line-height: 10px;
      transition: all ease 0.5s; }
    .related_casinos__second_row a:first-child:hover,
    .related_casinos__second_row a:nth-child(2):hover {
      opacity: 0.7;
      transition: all ease 0.5s; }
    .related_casinos__second_row a:nth-child(2) {
      color: #FFFFFF;
      background-color: #7e53dd;
      padding: 12px 14px;
      border-radius: 20px;
      line-height: 10px;
      transition: all ease 0.5s; }
  .related_casinos__third_row {
    text-align: center;
    padding-top: 10px; }
    .related_casinos__third_row a {
      font-size: 13px;
      text-decoration: underline;
      color: grey; }
    .related_casinos__third_row a:hover {
      color: #7e53dd; }

.faq__inner {
  max-width: 900px;
  margin: 0 auto;
  padding: 50px 0 70px;
  width: 100%; }
  .faq__inner_head {
    text-align: center;
    margin: 25px 0; }
  @media (max-width: 1100px) {
    .faq__inner {
      padding: 50px 20px 70px; } }

.faq__single {
  border-radius: 10px;
  background: #8045d30d;
  margin-bottom: 20px; }

.faq__single_title.active {
  border-bottom: 1px solid #ebebeb; }

.faq__single_title {
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
  cursor: pointer; }
  @media (max-width: 768px) {
    .faq__single_title {
      font-size: 17px; } }

.faq__single_title.active {
  text-decoration: none; }

.faq__single_head {
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-weight: 500;
  display: flex; }
  @media (max-width: 800px) {
    .faq__single_head::before {
      margin: 0 10px !important; } }
  @media (max-width: 768px) {
    .faq__single_head {
      font-size: 17px; } }

.faq__single_body {
  display: none;
  padding: 10px 0 30px;
  line-height: 30px;
  font-size: 16px;
  font-family: 'Inter', sans-serif;
  margin-left: 32px;
  margin-right: 20px; }
  .faq__single_body a {
    font-weight: bold;
    color: #8354D4;
    text-decoration: underline; }
    .faq__single_body a:hover {
      text-decoration: underline;
      color: #FCC13E;
      transition: all ease 0.5s; }

.faq__single_head:before {
  content: '\f055';
  font-family: 'Font Awesome 6 Free';
  font-weight: 900;
  color: #8354D4;
  font-size: 22px;
  margin: 0 15px 0 30px; }

.active .faq__single_head:before {
  content: '\f056'; }

.show {
  display: block; }

.related-guides__container {
  max-width: 1100px;
  margin: 0 auto;
  padding: 90px 20px;
  border-top: 1px solid #eee; }

.related-guides__heading {
  font-size: 30px;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase; }

.related-guides__guides_box {
  display: grid;
  max-width: inherit;
  grid-template-columns: repeat(3, 31%);
  grid-gap: 0 20px;
  margin: 0 auto;
  padding: 20px 0;
  justify-content: center; }
  @media (max-width: 768px) {
    .related-guides__guides_box {
      grid-template-columns: 100%;
      grid-gap: 30px 0; } }

.related-guides__single_item_img img {
  width: 100%;
  filter: drop-shadow(2px 2px 4px #c5c5c5);
  border-radius: 10px;
  transition: all ease 0.4s; }

.related-guides__single_item_img img:hover {
  filter: drop-shadow(2px 2px 6px #949494);
  transition: all ease 0.4s; }

.related-guides__single_item_info {
  padding-top: 20px; }
  .related-guides__single_item_info span:first-child {
    display: block;
    font-weight: 600;
    font-size: 19px;
    padding-bottom: 3px; }
    .related-guides__single_item_info span:first-child a {
      color: #8354D4;
      transition: all ease 0.4s; }
    .related-guides__single_item_info span:first-child a:hover {
      color: #FCC13E;
      text-decoration: underline;
      transition: all ease 0.4s; }
  .related-guides__single_item_info span:nth-child(2) {
    font-size: 15px; }
  .related-guides__single_item_info span:nth-child(3) p {
    font-size: 14px;
    line-height: 25px;
    padding-top: 5px; }

.latest_guides {
  padding: 90px 40px;
  line-height: 1.7em; }
  .latest_guides__box {
    display: grid;
    grid-gap: 20px 0; }
  .latest_guides__box_guides {
    display: flex;
    margin: 0 auto;
    justify-content: center;
    padding: 30px 0 20px; }
    @media (max-width: 768px) {
      .latest_guides__box_guides {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 20px 0; } }
    @media (max-width: 550px) {
      .latest_guides__box_guides {
        grid-template-columns: repeat(1, 1fr); } }
  .latest_guides__single {
    margin: 0 10px;
    max-width: 300px;
    background-color: #FFFFFF;
    border-radius: 20px;
    box-shadow: 0 0 8px #c4c4c4;
    transition: all ease 0.5s; }
  .latest_guides__single:hover {
    box-shadow: 0 0 12px #9e9e9e;
    transition: all ease 0.5s; }
  .latest_guides__img {
    width: 100%; }
    .latest_guides__img img {
      width: 100%;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      box-shadow: 0 2px 2px #ececec; }
  .latest_guides__single_title {
    padding: 10px 20px 5px;
    color: #8354D4;
    text-transform: capitalize; }
  .latest_guides__author_date {
    padding: 0 20px 10px;
    font-size: 15px;
    color: #8354D4; }
    .latest_guides__author_date b {
      color: #7e53dd; }
  .latest_guides__paragraph {
    padding: 0 20px 30px;
    font-size: 15px;
    color: #8354D4; }
  .latest_guides__box_btn {
    text-align: center; }
    .latest_guides__box_btn a {
      background-color: #7e53dd;
      color: #FFFFFF;
      padding: 7px 16px;
      border-radius: 20px;
      font-size: 13px;
      text-transform: uppercase;
      transition: all ease 0.5s; }
    .latest_guides__box_btn a:hover {
      filter: saturate(2);
      transition: all ease 0.5s; }

.subscribe-form {
  background-color: #FCC13E;
  display: grid;
  justify-content: center;
  align-items: center; }
  .subscribe-form__inner {
    max-width: 800px;
    padding: 60px 0;
    margin: 0;
    text-align: center; }
    .subscribe-form__inner .wpcf7-not-valid-tip {
      padding-top: 10px;
      color: #7e53dd; }
    .subscribe-form__inner .wpcf7 form.invalid .wpcf7-response-output {
      margin-top: -15px;
      color: #7e53dd;
      border: 1px solid #7e53dd;
      font-weight: 500; }
  .subscribe-form__title {
    font-size: 26px;
    line-height: 35px; }
    @media (max-width: 768px) {
      .subscribe-form__title {
        padding: 0 20px;
        font-size: 20px; } }
  .subscribe-form__paragraph {
    font-size: 15px;
    line-height: 30px; }
    @media (max-width: 768px) {
      .subscribe-form__paragraph {
        padding: 0 20px;
        font-size: 14px;
        line-height: 20px; } }
  .subscribe-form__form {
    max-width: 390px;
    overflow-x: hidden;
    margin: 0 auto; }
  .subscribe-form .es-field-wrap {
    max-width: 500px;
    margin: 1em auto 0; }
    @media (max-width: 768px) {
      .subscribe-form .es-field-wrap {
        padding: 0 40px; } }
    .subscribe-form .es-field-wrap input[type='email'] {
      color: #8354D4;
      font-size: 16px;
      width: 100%; }
    .subscribe-form .es-field-wrap input[type='email']::placeholder {
      color: grey; }
    .subscribe-form .es-field-wrap input {
      padding: 12px 20px;
      border: none;
      border-radius: 20px; }
      .subscribe-form .es-field-wrap input::placeholder {
        color: grey; }
      @media (max-width: 420px) {
        .subscribe-form .es-field-wrap input {
          width: 100%; } }
  .subscribe-form input.es_submit_button {
    padding: 9px 18px;
    border: none;
    background-color: #7e53dd;
    color: #FFFFFF;
    border-radius: 20px;
    text-transform: uppercase;
    margin-top: 10px;
    font-size: 14px;
    cursor: pointer; }
  .subscribe-form input.es_submit_button:hover {
    filter: saturate(2); }
  .subscribe-form .wpcf7-response-output {
    margin-top: -20px;
    display: none; }

.casinos_block_green {
  background-color: green; }

.casinos_block_red {
  background-color: red; }

.scroll-to-top-btn {
  position: fixed;
  bottom: 75px;
  right: 10px;
  width: 45px;
  height: 45px;
  margin: 0 20px 20px 0;
  z-index: 100; }
  .scroll-to-top-btn svg {
    width: 100%;
    height: 100%;
    cursor: pointer;
    filter: drop-shadow(0px 0px 8px white); }
    @media screen and (max-width: 567px) {
      .scroll-to-top-btn svg {
        width: 80%;
        height: 80%; } }
  .scroll-to-top-btn .scroll-to-top-btn-show {
    opacity: 1;
    visibility: visible; }

.scroll-to-top-btn.scroll-to-top-btn-show {
  opacity: 1;
  visibility: visible; }

.anchor__menu {
  background-color: #fff;
  box-shadow: 0px 12px 14px rgba(196, 193, 193, 0.4);
  transition: all ease 1s;
  margin: 0 auto; }
  @media (max-width: 480px) {
    .anchor__menu {
      width: 95%; } }

.anchor__menu_container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 15px 0; }
  @media (max-width: 700px) {
    .anchor__menu_container {
      flex-wrap: nowrap;
      text-align: center;
      overflow-x: auto;
      justify-content: flex-start;
      margin: 0 auto; } }
  @media (max-width: 480px) {
    div .anchor__menu_container li a {
      font-family: Inter;
      font-size: 14px;
      font-weight: 700;
      line-height: 19px;
      padding: 7px 20px;
      color: #4b4b4b; } }
  .anchor__menu_container li {
    flex: auto;
    color: #7f8c8d;
    text-align: center;
    list-style-type: none;
    border-left: 1px solid #d2d2d2;
    padding: 5px 0; }
    .anchor__menu_container li:first-child {
      border: none; }
    .anchor__menu_container li a {
      color: #7f8c8d;
      font-weight: 500;
      font-size: 14px;
      text-decoration: none; }
      @media (max-width: 700px) {
        .anchor__menu_container li a {
          font-size: 11px; } }
    @media (max-width: 700px) {
      .anchor__menu_container li {
        flex: none;
        font-size: 11px;
        text-align: center;
        padding: 5px 14px; } }
  .anchor__menu_container a:last-child {
    border-right: none; }
  .anchor__menu_container a:hover {
    color: #7e53dd; }

.home .anchor__menu.anchor-menu-fixed,
.anchor__menu.anchor-menu-fixed {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  transition: all ease 1s;
  max-width: unset;
  border-radius: 0; }

.filter_conntent_block {
  border-radius: 15px;
  box-shadow: 0px 5px 19px rgba(0, 0, 0, 0.17);
  margin-bottom: 54px;
  padding: 28px 40px; }
  .filter_conntent_block.games {
    position: relative;
    top: -60px;
    max-width: 1100px;
    margin: 0 auto;
    background: white;
    padding-bottom: 20px; }
    @media (max-width: 800px) {
      .filter_conntent_block.games {
        top: 10px; } }
  .filter_conntent_block-filters-providers, .filter_conntent_block-filters-title, .filter_conntent_block-filters-game_type, .filter_conntent_block-sort-title {
    margin-right: 1rem; }
    @media (max-width: 800px) {
      .filter_conntent_block-filters-providers, .filter_conntent_block-filters-title, .filter_conntent_block-filters-game_type, .filter_conntent_block-sort-title {
        width: 100%;
        text-align: center;
        margin-bottom: 10px; } }
  .filter_conntent_block-filters-title, .filter_conntent_block-sort-title {
    font-weight: 600;
    font-size: 18px;
    color: #525252; }
  .filter_conntent_block-body {
    display: flex;
    justify-content: space-between; }
    @media (max-width: 800px) {
      .filter_conntent_block-body {
        flex-direction: column;
        gap: 20px; } }
  .filter_conntent_block-sort, .filter_conntent_block-filters {
    display: flex;
    align-items: center; }
    @media (max-width: 800px) {
      .filter_conntent_block-sort, .filter_conntent_block-filters {
        flex-direction: column; } }
  @media (max-width: 800px) {
    .filter_conntent_block-sort-list {
      width: 100%;
      margin-right: 0 !important; }
      .filter_conntent_block-sort-list select {
        width: 100%;
        margin: 0 auto; } }
  .filter_conntent_block-clear {
    margin-left: 3rem; }
    .filter_conntent_block-clear-button {
      font-weight: normal;
      font-size: 14px;
      color: #864cd7;
      display: block;
      padding: 5px;
      cursor: pointer; }
  .filter_conntent_block select {
    background: white;
    border: 1px solid #f1f1f1;
    border-radius: 15px;
    padding: 6px 12px;
    min-width: 150px; }
    @media (max-width: 800px) {
      .filter_conntent_block select {
        width: 100%; } }
  .filter_conntent_block-sort-order {
    margin-left: 1em;
    display: block;
    padding: 5px 10px;
    cursor: pointer; }
  @media (max-width: 800px) {
    .filter_conntent_block-form {
      width: 100% !important; } }

.target-to-load {
  padding-top: 30px; }

.error-509,
.error-404 {
  background-color: #8354D4; }
  .error-509__container,
  .error-404__container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 50px 20px; }
  .error-509__info h1,
  .error-404__info h1 {
    font-size: 64px;
    color: #FFFFFF;
    font-weight: 700; }
  .error-509__info h2,
  .error-404__info h2 {
    font-size: 58px;
    color: #FCC13E;
    font-weight: 700; }
  .error-509__info_paragraph,
  .error-404__info_paragraph {
    margin-top: 10px;
    font-size: 30px;
    color: #FFFFFF;
    font-weight: 400; }
    .error-509__info_paragraph a,
    .error-404__info_paragraph a {
      color: #FCC13E;
      text-decoration: underline; }
  .error-509__image img,
  .error-404__image img {
    width: 100%; }

.detail-icon {
  transition: all ease 0.5s; }

.rotate_dropdown_icon {
  transform: rotate(180deg); }

.clearfix {
  content: '';
  display: table;
  clear: both; }

.clearfix:before,
.clearfix:after {
  content: '';
  display: table;
  clear: both; }

.guidess__slider {
  height: 600px; }
  @media (max-width: 500px) {
    .guidess__slider {
      height: 550px; } }

.guidess__slider_title_heading {
  padding-top: 50px;
  margin: 0 auto; }

.guidess__slider_container {
  display: grid;
  justify-content: center;
  width: 700px;
  margin: 0 auto;
  position: relative; }
  @media (max-width: 768px) {
    .guidess__slider_container {
      max-width: 500px;
      left: 0; } }
  @media (max-width: 500px) {
    .guidess__slider_container {
      width: 400px;
      left: -10px; } }

.guidess__slider_single_box {
  background-color: lightgreen;
  width: 700px;
  margin: 0 auto;
  position: relative; }
  @media (max-width: 768px) {
    .guidess__slider_single_box {
      width: 500px;
      left: 20px; } }
  @media (max-width: 500px) {
    .guidess__slider_single_box {
      width: 400px;
      margin-right: 20px; } }

.guidess__slider_single_item {
  margin-top: 80px;
  display: none;
  position: absolute;
  top: 30px;
  left: 0;
  margin: 0;
  background-color: #fff; }

.guidess__slider_single_item.active {
  display: block; }

.guidess__slider_image {
  width: 100%; }
  .guidess__slider_image img {
    filter: drop-shadow(3px 3px 3px #d8d8d8);
    width: 100%;
    height: 100%; }
    @media (max-width: 768px) {
      .guidess__slider_image img {
        width: 100%; } }

.guidess__slider_info {
  padding-top: 20px; }
  .guidess__slider_info h2 {
    color: #8354D4;
    padding-bottom: 10px; }
  .guidess__slider_info p {
    color: #8354D4; }

.guidess__slider_info:hover {
  filter: drop-shadow(); }

.guidess__slider_info:hover h2,
.guidess__slider_info:hover p {
  color: #FCC13E; }

.guidess__sliders_btns {
  position: absolute;
  width: 100%;
  margin-top: 27%;
  padding: 0 20px; }
  .guidess__sliders_btns p:first-child {
    left: 8px; }
    @media (max-width: 768px) {
      .guidess__sliders_btns p:first-child {
        left: 40px; } }
    @media (max-width: 500px) {
      .guidess__sliders_btns p:first-child {
        left: 20px; } }
  .guidess__sliders_btns p:nth-child(2) {
    right: 5px; }
    @media (max-width: 768px) {
      .guidess__sliders_btns p:nth-child(2) {
        right: 0; } }
    @media (max-width: 500px) {
      .guidess__sliders_btns p:nth-child(2) {
        right: 5px; } }

.guidess__sliders_btns p {
  position: absolute;
  border-radius: 50px;
  font-size: 30px;
  font-weight: 900;
  cursor: pointer;
  color: #FCC13E; }

/* TODO Cleanup old sections new ones gose after ths */
.industry-updates {
  margin: 50px auto; }
  .industry-updates .inner {
    box-shadow: #0000003f 5px 5px 15px 0;
    border-radius: 20px;
    padding: 55px 50px 50px;
    max-width: 1110px; }
  .industry-updates__headers {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 20px; }
  .industry-updates__news_feed {
    display: flex;
    gap: 25px; }
    .industry-updates__news_feed_single_item {
      width: 25%; }
      .industry-updates__news_feed_single_item_inner {
        height: 220px; }
    .industry-updates__news_feed_image {
      border-radius: 5px;
      height: 60%;
      background-size: cover;
      background-position: center; }
    .industry-updates__news_feed_info {
      display: flex;
      flex-direction: column;
      min-height: 110px;
      text-align: left; }
      .industry-updates__news_feed_info_text h4 {
        font-size: 14px;
        font-weight: 600;
        text-decoration: none;
        color: #18191f;
        margin-top: 15px; }
        .industry-updates__news_feed_info_text h4:hover {
          color: #737b7d; }
      .industry-updates__news_feed_info_date, .industry-updates__news_feed_info_name {
        font-size: 12px;
        font-weight: 400;
        color: #18191f;
        margin-top: 10px; }
      .industry-updates__news_feed_info_name {
        color: #737b7d; }
        .industry-updates__news_feed_info_name a {
          color: #737b7d; }
          .industry-updates__news_feed_info_name a:hover {
            text-decoration: underline; }

.categories-block__inner {
  max-width: 1110px;
  display: flex;
  flex-direction: row;
  gap: 0 40px;
  margin: 50px auto; }

.categories-block__icons_inner {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  text-align: center;
  align-items: center;
  padding: 25px 20px 0; }
  .categories-block__icons_inner_icon_image {
    border-radius: 15px;
    box-shadow: #0000003f 5px 5px 15px 0;
    height: 120px;
    width: 120px;
    margin: 10px auto; }
    .categories-block__icons_inner_icon_image img {
      margin-top: 15px; }
  .categories-block__icons_inner p {
    font-size: 16px;
    font-weight: 600;
    color: black;
    padding-bottom: 20px; }

.categories-block__join, .categories-block__icons {
  padding: 55px 60px 50px;
  box-shadow: #0000003f 5px 5px 15px 0;
  flex: 50%;
  border-radius: 20px; }

.categories-block__join {
  text-align: center;
  background-color: #8354D4;
  color: #FFFFFF; }
  .categories-block__join h2 {
    margin: 50px 20px 25px;
    font-weight: 700;
    font-size: 32px; }
    .categories-block__join h2 span {
      color: #FCC13E; }
  .categories-block__join p {
    margin-bottom: 35px;
    font-size: 20px; }
  .categories-block__join .es_subscription_message.success {
    color: #FCC13E; }
  .categories-block__join .emaillist form {
    display: flex;
    justify-content: center; }
    .categories-block__join .emaillist form .es-field-wrap {
      width: 80%; }
    .categories-block__join .emaillist form .es_required_field {
      height: 76px;
      width: 100%;
      border-radius: 5px 0 0 5px;
      border: none;
      font-size: 18px;
      padding-left: 20px; }
    .categories-block__join .emaillist form .es_subscription_form_submit {
      font-family: 'Font Awesome 6 Free';
      font-weight: 900;
      width: 76px;
      height: 76px;
      color: #FFFFFF;
      background-color: #FCC13E;
      border-radius: 0 5px 5px 0;
      border: none;
      font-size: 30px;
      transition: all ease 0.5s; }
      .categories-block__join .emaillist form .es_subscription_form_submit:hover {
        padding-left: 10px;
        background-color: #ffcd5d;
        cursor: pointer;
        transition: all ease 0.2s; }

.categories-block-row__inner {
  max-width: 1110px;
  display: flex;
  flex-direction: row;
  gap: 0 40px;
  margin: 50px auto;
  text-align: center; }

.categories-block-row__title {
  margin: 90px auto 30px; }
  .categories-block-row__title svg {
    margin-bottom: 20px; }
  .categories-block-row__title h3 {
    font-size: 30px;
    font-weight: 700;
    color: #292929;
    padding-bottom: 12px; }

.categories-block-row__icons_inner {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  text-align: center;
  align-items: baseline;
  padding: 25px 20px 0;
  gap: 20px; }
  @media (max-width: 800px) {
    .categories-block-row__icons_inner {
      display: grid;
      grid-template-columns: repeat(2, 45%);
      gap: 30px 10px;
      justify-content: center; } }
  @media (max-width: 500px) {
    .categories-block-row__icons_inner {
      grid-template-columns: 100%; } }
  .categories-block-row__icons_inner_icon_image {
    border-radius: 30px;
    box-shadow: #0000003f 2px 2px 15px 0;
    height: 120px;
    width: 120px;
    margin-bottom: 13px; }
    .categories-block-row__icons_inner_icon_image img {
      margin-top: 15px; }
    @media (max-width: 800px) {
      .categories-block-row__icons_inner_icon_image {
        display: flex;
        justify-content: center;
        margin: 0 auto; } }
  .categories-block-row__icons_inner strong {
    font-weight: 600;
    font-size: 18px;
    text-align: left; }
  .categories-block-row__icons_inner p {
    color: #292929;
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    margin-top: 20px; }
    @media (max-width: 800px) {
      .categories-block-row__icons_inner p {
        text-align: center; } }

.default__banner {
  background: #8354D4;
  color: #f7f7f7; }

.default__heading {
  font-size: 42px;
  font-weight: 700;
  padding: 8px 0 0;
  z-index: 10; }
  @media (max-width: 800px) {
    .default__heading {
      font-size: 22px; } }
  @media (max-width: 500px) {
    .default__heading {
      width: 200px; } }

.default__subheading {
  font-size: 16px;
  font-weight: 600;
  color: #FCC13E;
  padding: 10px 0 20px;
  z-index: 10; }

.default__paragraph {
  font-weight: 400;
  font-size: 16px;
  padding-bottom: 30px;
  line-height: 30px; }
  .default__paragraph a {
    color: #fcc13e;
    text-decoration: underline; }
  @media (max-width: 800px) {
    .default__paragraph {
      padding-top: 0; } }

.default__title {
  width: 50%; }

.default__img {
  position: absolute;
  z-index: 100;
  padding-left: 649px;
  top: 60px; }
  .default__img img {
    height: 342px; }
  .default__img.games {
    padding-left: 596px;
    top: 87px; }

.default__banner_container {
  display: grid;
  grid-template-columns: repeat(2, 48%);
  padding: 20px 20px 0;
  justify-content: space-between; }
  @media (max-width: 800px) {
    .default__banner_container {
      grid-template-columns: 100%; } }

.default__banner_left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start; }

.default__banner_img {
  display: grid;
  justify-content: center;
  align-items: center; }
  .default__banner_img img {
    width: 100%; }
    @media (max-width: 800px) {
      .default__banner_img img {
        width: 100%; } }

.default__shape {
  width: 100%;
  overflow: hidden; }

.default__shape-top {
  background-color: #8354D4;
  width: 100%;
  height: 50px;
  border-radius: 50% / 75%;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #8354D4;
  transform: scale(1.05, -1); }

.default .anchor__menu {
  max-width: none;
  border: none;
  border-radius: 0;
  z-index: 100;
  position: relative; }

.default__image {
  display: flex;
  flex-direction: column;
  align-items: center; }
  .default__image img {
    width: 160px;
    border-radius: 15px; }

.anchor__menu {
  z-index: 100;
  position: relative; }

.default_repeater {
  padding: 60px 0;
  text-align: center;
  margin-bottom: 100px;
  margin-top: 50px; }
  .default_repeater__title h3 {
    font-family: Inter;
    font-weight: 700;
    font-size: 25px; }
  .default_repeater__options {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 70px; }
    .default_repeater__options_item {
      margin-top: 20px; }
      .default_repeater__options_item img {
        height: 110px; }
      .default_repeater__options_item_title {
        text-transform: uppercase;
        font-weight: 600;
        font-size: 20px;
        margin: 15px; }
      .default_repeater__options_item_text {
        font-weight: 400;
        font-size: 16px; }

.breadcrumb {
  display: flex;
  list-style: none;
  padding: 0; }

.breadcrumb li + li::before {
  content: '/';
  margin: 0 0.5em;
  opacity: 0.5; }

.breadcrumb-item-current {
  font-weight: 500; }

.repeater {
  margin: 60px 30px; }
  .repeater__inner_head h2 {
    color: #f7f7f7;
    font-family: Inter;
    font-size: 30px;
    font-weight: 700;
    line-height: 56px; }
  .repeater__single {
    display: flex;
    align-items: center;
    margin: 30px 0; }
    @media (max-width: 800px) {
      .repeater__single {
        display: grid; } }
    .repeater__single_head {
      color: #f7f7f7;
      font-family: Inter;
      font-size: 20px;
      font-weight: 700;
      display: flex;
      gap: 9px; }
      .repeater__single_head span {
        display: block;
        width: 28px;
        height: 28px;
        background: #FCC13E;
        color: #8354D4;
        border-radius: 15px;
        text-align: center; }
    .repeater__single_subtitle {
      color: #f7f7f7;
      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      padding: 15px 0; }
    .repeater__single_description,
    .repeater__single_description p {
      color: #f7f7f7;
      font-family: Inter;
      font-size: 16px;
      font-weight: 400;
      padding-top: 10px; }
  @media (max-width: 800px) {
    .repeater__single_icon {
      text-align: center; } }

.ad_disclosure {
  position: relative;
  text-align: end;
  top: 25px; }

.category_ribbon {
  position: relative;
  height: 35px;
  display: block;
  border-radius: 0 10px 10px 0;
  padding: 8px 0 8px 16px;
  left: -25px;
  top: 37px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700; }
  @media (max-width: 800px) {
    .category_ribbon {
      left: -17px; } }
  .category_ribbon svg {
    margin-right: 5px; }
  .category_ribbon div {
    overflow: hidden;
    width: 25px;
    position: relative;
    left: -16px;
    top: 7px; }
    .category_ribbon div div {
      display: block;
      width: 53px;
      height: 42px;
      top: -15px;
      left: 20px;
      background: #e6b34c;
      transform: skewX(60deg);
      margin: 0 0 0 -10px;
      position: relative; }
  .category_ribbon.top_pick_ribbon {
    color: #8354D4;
    width: 130px;
    background-color: #FCC13E; }
    .category_ribbon.top_pick_ribbon div div {
      background: #e6b34c; }
  .category_ribbon.best_of_best {
    color: #f7f7f7;
    width: 200px;
    background-color: #7e53dd; }
    .category_ribbon.best_of_best div div {
      background: #8354D4; }

.blue {
  background-color: #8354D4; }

.three_col_feed {
  padding: 50px 10px 70px; }
  @media (max-width: 800px) {
    .three_col_feed {
      padding: 0 10px 20px; } }
  .three_col_feed .inner {
    text-align: center; }
  .three_col_feed__title {
    margin: 0 auto 30px; }
    .three_col_feed__title svg {
      margin-bottom: 20px; }
    .three_col_feed__title h3 {
      font-size: 42px;
      font-weight: 700;
      color: #292929;
      padding-bottom: 12px; }
      @media (max-width: 800px) {
        .three_col_feed__title h3 {
          font-size: 30px; } }
    .three_col_feed__title_sub {
      font-size: 16px;
      font-weight: 400;
      color: #292929;
      line-height: 30px;
      margin: 0 7vw; }
  .three_col_feed__content {
    display: grid;
    grid-template-columns: repeat(3, 30%);
    justify-content: space-between;
    gap: 30px;
    padding-top: 20px; }
    @media (max-width: 800px) {
      .three_col_feed__content {
        display: flex;
        overflow-x: auto; } }
    .three_col_feed__content_item {
      background-color: #FFFFFF;
      border-radius: 7px;
      box-shadow: 1px 1px 10px rgba(196, 193, 193, 0.53); }
      @media (max-width: 800px) {
        .three_col_feed__content_item {
          min-width: 85%; } }
      .three_col_feed__content_item_thumb {
        width: 100%;
        background-size: cover;
        background-position: center;
        border-radius: 10px 10px 0 0; }
        .three_col_feed__content_item_thumb img {
          width: 100%;
          border-radius: 10px 10px 0 0; }
      .three_col_feed__content_item_desc {
        padding: 20px 20px 25px; }
      .three_col_feed__content_item_date {
        color: #585858;
        font-family: Inter;
        font-size: 14px;
        font-weight: 400;
        line-height: 8px;
        margin-bottom: 10px;
        text-align: left; }
      .three_col_feed__content_item_title {
        color: #292929;
        text-align: left;
        font-family: Inter;
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 10px; }
      .three_col_feed__content_item_text {
        color: #585858;
        text-align: left;
        font-family: Inter;
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 25px; }
        @media (max-width: 800px) {
          .three_col_feed__content_item_text {
            margin-bottom: 18px; } }
      .three_col_feed__content_item_author {
        text-align: left; }
        .three_col_feed__content_item_author a {
          color: #585858;
          font-family: Inter;
          font-size: 14px;
          font-weight: 400;
          line-height: 13px; }
          .three_col_feed__content_item_author a span {
            color: #292929;
            font-family: Inter;
            font-size: 14px;
            font-weight: 500;
            line-height: 13px; }

.add_space {
  padding-bottom: 50px; }

.banner_btn_play_now {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
  color: #8354D4;
  background-color: #FCC13E;
  text-transform: uppercase;
  padding: 10px 50px;
  display: inline-block;
  border-radius: 20px;
  margin-bottom: 10px; }

.banner_btn_play_now:hover {
  filter: saturate(2); }

.overflow-bar::-webkit-scrollbar-thumb {
  background-color: #925edb;
  border-radius: 10px; }

.overflow-bar::-webkit-scrollbar {
  width: 10px;
  height: 5px; }

.overflow-bar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #dfd2f2;
  border-radius: 10px; }

.overflow-bar::-webkit-scrollbar-thumb:hover {
  background: #925edb; }

.padding-buffer {
  padding: 15px 0; }
  @media (max-width: 800px) {
    .padding-buffer {
      padding: 5px 0 0; } }

.ad_disclosure:hover .ad_disclosure_popup {
  display: block; }

.ad_disclosure_popup {
  max-width: 50vh;
  width: 300px;
  display: none;
  position: absolute;
  right: 0;
  text-align: left;
  background: white;
  padding: 20px;
  border-radius: 15px;
  border: solid 2px #8354D4;
  z-index: 10; }
  .ad_disclosure_popup span {
    color: #8354D4; }
  @media (max-width: 800px) {
    .ad_disclosure_popup {
      width: 90%; } }
