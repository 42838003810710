@import "../vars";
@import "../reset";
@import "../breakpoints";

// Styling the banner section for the home page
.home .anchor {
  &__menu {
    background-color: #fff;
    box-shadow: 2px 10px 27px rgba(196, 193, 193, 0.4);
    transition: all ease 1s;
    max-width: 1185px;
    margin: 0 auto;
    border-radius: 0 0 20px 20px;
  }
}

.home .banner {
  & rs-bullets.tp-bullets {
    transform: translate(0, -13px);
    top: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 auto;
  }
  &
    rs-module.revslider-initialised
    rs-bullets.tp-bullets.persephone
    rs-bullet.tp-bullet.selected {
    background: white !important;
  }
  &
    rs-module.revslider-initialised
    rs-bullets.tp-bullets.persephone
    rs-bullet.tp-bullet {
    width: 380px !important;
    height: 3px !important;
    border: none !important;
    background: rgba(127, 140, 141, 0.49) !important;
    @include media("<=800px") {
      width: 28vw !important;
      height: 10px !important;
      border-radius: 5px;
    }
  }
}

.triangle-topright {
  width: 0;
  height: 0;
  border-top: 150px solid $primary-background-color;
  border-left: 100vw solid white;
}

.bingo-games {
  padding: 60px 0;
  text-align: center;
  padding-bottom: 30px;
  &__container {
    min-width: 370px;
  }
  &__title h3 {
    font-weight: 700;
    font-size: 42px;

    @include media("<=800px") {
      font-size: 30px;
    }
  }
  &__box {
    width: 100%;
    overflow-x: auto;

    &::-webkit-scrollbar-thumb {
      background-color: #925edb;
      border-radius: 10px;
    }
    &::-webkit-scrollbar {
      width: 10px;
      height: 5px;
    }
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #925edb;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #925edb;
    }
  }
  &__options {
    display: grid;
    grid-template-columns: repeat(3, 30%);
    justify-content: center;
    justify-content: space-around;
    margin-top: 15px;
    min-width: 1000px;
    padding-bottom: 10px;

    @include media("<=800px") {
      min-width: 800px;
    }
    &_item {
      & img {
        width: 110px;

        @include media("<=800px") {
          width: 80px;
        }
      }
      &_engage {
        font-size: 14px;
        background-color: #925edb;
        color: #fff;
        border-radius: 20px;
        padding: 10px 20px;
        transition: all ease 0.5s;
        display: inline-block;
        margin: 10px 0 0;

        @include media("<=800px") {
          padding: 7px 10px;

          &:hover {
            filter: saturate(2);
            transition: all ease 0.5s;
          }
        }
      }
      &_title {
        text-transform: uppercase;
        font-weight: 600;
        font-size: 25px;
        margin: 15px;

        @include media("<=800px") {
          font-size: 18px;
        }
      }
      &_types {
        & ul {
          display: flex;
          flex-direction: column;
          align-items: center;
          & li {
            width: fit-content;
            padding: 10px 20px;
            margin: 7px;
            box-shadow: 2px 5px 10px #c4c1c166;
            list-style: none;
            border-radius: 20px;

            @include media("<=800px") {
              padding: 7px 10px;
            }
            & a {
              font-size: 16px;
              font-weight: 400;
              color: $paragraph-text-color;

              @include media("<=800px") {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
}

.best-bingo-sites {
  padding: 90px 20px 100px;
  @include media("<=800px") {
    padding-bottom: 120px;
  }
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  background-image: url("../../img/home/best-bingo-background.png");
  &__title {
    text-align: center;
    & i {
      color: $secondary-background-color;
      font-size: 60px;
      padding-bottom: 20px;
    }
    &_h3 {
      font-size: 42px;
      font-weight: 700;
      color: $tetiary-color;
      padding-bottom: 12px;

      @include media("<=900px") {
        font-size: 30px;
        font-weight: 600;
      }
    }
    &_sub {
      font-size: 16px;
      font-weight: 400;
      color: $tetiary-color;
      line-height: 30px;
      margin: 0 7vw;
    }
  }
  &__content {
    display: grid;
    grid-template-columns: repeat(2, 45%);
    justify-content: center;
    gap: 30px;
    padding-top: 20px;

    @include media("<=900px") {
      grid-template-columns: 100%;
    }
    &_left {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    &_item {
      background-color: $tetiary-color;
      border-radius: 7px;
      display: grid;
      grid-template-columns: 75% 25%;
      align-items: center;
      padding: 20px;

      @include media("<=990px") {
        grid-template-columns: 100%;
      }

      &_stars {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
      }
      &_img {
        width: 100px;
        margin-right: 15px;
      }
      &_desc {
        text-align: left;
      }
      &_title {
        font-size: 23px;
        font-weight: 600;
      }
      &_text {
        font-size: 15px;
        font-weight: 500;
        margin-top: 10px;
      }
      div &_engage {
        display: inline;
        padding: 10px 20px;
        text-transform: uppercase;
        font-size: 18px;
        font-weight: 600;
        color: $primary-background-color;
        background-color: $secondary-background-color;

        @include media("<=990px") {
          width: 80%;
          margin: 0 auto;
          font-size: 16px;
          padding: 8px 20px;
          margin-top: 10px;
        }

        @include media("<=450px") {
          width: 100%;
        }
      }
    }
  }
  &__see_more {
    padding-top: 40px;
    div &_button {
      text-transform: uppercase;
      font-weight: 700;
      padding: 15px 30px;
      background-color: $light-background-color;
      display: flex;
      width: fit-content;
      margin: 0 auto;
      & svg {
        margin-left: 10px;
      }
    }
  }
}

.hot-sites {
  padding: 50px 0;
  @include media("<=800px") {
    padding-top: 0;
  }
  & .inner {
    text-align: center;
  }
  &__title {
    margin: 0 auto 30px;
    & svg {
      margin-bottom: 20px;
    }
    &_h3 {
      font-size: 42px;
      font-weight: 700;
      color: $paragraph-text-color;
      padding-bottom: 12px;
      @include media("<=900px") {
        font-size: 30px;
        font-weight: 600;
      }
    }
    &_sub {
      font-size: 16px;
      font-weight: 400;
      color: $paragraph-text-color;
      line-height: 30px;
      margin: 0 7vw;
    }
  }
  &__content {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    padding: 20px;
    justify-content: center;

    @include media("<=800px") {
      grid-template-columns: repeat(2, 46%);
      gap: 10px 10px;
    }
    &_item {
      background-color: $tetiary-color;
      border-radius: 7px;
      padding: 20px 20px 30px;
      box-shadow: 1px 1px 10px rgba(196, 193, 193, 0.53);
      text-align: center;
      &_stars {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: center;
        align-items: flex-start;
        .star-rating-text {
          @include media("<=800px") {
            font-size: 12px;
            padding-top: 0;
          }
        }
        .star-rating .star {
          @include media("<=800px") {
            width: 12px;
            height: 11px;
            font-size: 12px;
          }
        }
      }
      &_img {
        width: 136px;
        height: 85px;
        margin-bottom: 7px;
        @include media("<=800px") {
          width: 90px;
          height: 56px;
        }
      }
      &_desc {
        text-align: center;
        height: 145px;
        @include media("<=800px") {
          height: 100px;
        }
      }
      &_title {
        color: $accent-text-color;
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 22px;
        text-decoration: underline;
      }
      &_text {
        margin: 8px 0;
        font-size: 16px;
        font-weight: 500;
        @include media("<=800px") {
          font-size: 12px;
        }
      }
      div &_engage {
        display: inline;
        padding: 7px 50px;
        text-transform: uppercase;
        font-size: 18px;
        font-weight: 600;
        color: $primary-background-color;
        background-color: $secondary-background-color;

        @include media("<=990px") {
          width: 80%;
          margin: 0 auto;
          font-size: 16px;
          padding: 8px 20px;
          margin-top: 10px;
        }

        @include media("<=450px") {
          width: 100%;
        }
      }
    }
  }
  &__see_more {
    padding-top: 70px;
    div &_button {
      text-transform: uppercase;
      font-weight: 700;
      background-color: $primary-background-color;
      padding: 15px 30px;
      display: flex;
      width: fit-content;
      margin: 0 auto;
      & svg {
        margin-left: 10px;
      }
    }
  }
}

.our-advantages {
  padding: 90px 0;
  background-image: url("../../img/home/advantages-main-bg-visible.png");
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  background-position: bottom;

  @include media("<=800px") {
    background-image: url("../../img/home/advantages-main-bg.png");
    padding: 40px 0 80px;
  }
  &__container {
    display: grid;
  }
  &__title {
    margin: 70px auto 0;
    @include media("<=800px") {
      margin-top: 0;
    }
    text-align: center;
    img {
      display: none;
      @include media("<=800px") {
        display: flex;
        margin: 0 auto;
      }
    }
    &_h3 {
      font-size: 42px;
      font-weight: 700;
      color: $paragraph-text-color;
      padding-bottom: 12px;
      @include media("<=800px") {
        font-size: 22px;
      }
    }
  }
  &__content {
    display: grid;
    grid-template-columns: repeat(2, 45%);

    @include media("<=800px") {
      grid-template-columns: 100%;
    }
    &_item {
      text-align: center;
      &_img {
        height: 80px;
        margin: 0 auto 11px;
      }
      &_desc {
        text-align: center;
      }
      &_title {
        font-size: 18px;
        font-weight: 700;
        @include media("<=800px") {
          margin-bottom: 6px;
        }
      }
      &_text {
        font-size: 16px;
        font-weight: 400;
      }
    }
  }
  &__content_left {
    img {
      width: 50%;
      left: 0;
      bottom: 0;
      transform: rotate(4deg);
      position: absolute;
      visibility: hidden;

      @include media("<=800px") {
        display: none;
      }
    }
  }
  &__content_right {
    display: grid;
  }
  &__content_right_body {
    display: grid;
    grid-template-columns: repeat(2, 45%);
    justify-content: center;
    gap: 10px;
  }
}

.online-casinos {
  padding: 50px 0 30px;
  &__inner {
    text-align: center;
  }
  &__title {
    margin: 40px auto 30px;

    @include media("<=800px") {
      margin-top: 0;
    }
    & i {
      color: $primary-background-color;
      font-size: 60px;
      padding-bottom: 20px;
    }
    &_h3 {
      font-size: 42px;
      font-weight: 700;
      color: $paragraph-text-color;
      padding-bottom: 12px;
      @include media("<=800px") {
        font-size: 22px;
      }
    }
    &_sub {
      font-size: 16px;
      font-weight: 400;
      color: $paragraph-text-color;
      line-height: 30px;
      margin: 0 7vw;
    }
  }
  &__content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
    padding: 20px 20px 0;

    @include media("<=700px") {
      grid-template-columns: 100%;
    }
    &_left {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      @include media("<=800px") {
        display: flex;
      }
    }
    &_item {
      background-color: $tetiary-color;
      border-radius: 7px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 20px;
      box-shadow: 1px 1px 10px 0 #00000033;

      @include media("<=1000px") {
        display: grid;
        grid-template-columns: 100%;
      }
      &_stars {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
      }
      &_img {
        width: 100px;
        margin-right: 15px;
      }
      &_desc {
        text-align: left;
      }
      &_title {
        font-size: 22px;
        font-weight: 700;

        @include media("<=1000px") {
          font-size: 16px;
          font-weight: 700;
        }
      }
      &_text {
        font-size: 16px;
        font-weight: 500;
        padding: 10px 0 0;
      }
      div &_engage {
        padding: 10px 20px;
        height: 44px;
        text-transform: uppercase;
        font-size: 18px;
        font-weight: 600;
        color: $primary-background-color;
        background-color: $secondary-background-color;

        @include media("<=1000px") {
          margin: 5px auto 0;
          width: 80%;
        }
      }
    }
  }
  &__see_more {
    padding-top: 70px;
    div &_button {
      text-transform: uppercase;
      font-weight: 700;
      background-color: $light-background-color;
      padding: 15px 30px;
      display: flex;
      width: fit-content;
      margin: 0 auto;
      & svg {
        margin-left: 10px;
      }
    }
  }
}

.playing-guides {
  padding: 50px 0 150px;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 100px));
  background: #864cd7;
  @include media("<=1000px") {
    padding: 50px 0 100px;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 30px));
    margin-bottom: 100px;
  }
  &__title {
    margin: 90px auto 30px;
    @include media("<=800px") {
      margin-top: 10px;
    }
    text-align: center;
    &_h3 {
      font-size: 42px;
      font-weight: 700;
      color: $tetiary-color;
      padding-bottom: 12px;
      @include media("<=800px") {
        font-size: 22px;
      }
    }
    &_sub {
      font-size: 16px;
      line-height: 30px;
      font-weight: 400;
      color: $tetiary-color;
      margin: 0 7vw;
    }
  }
  &__container {
    min-width: 370px;
    overflow-x: auto;
    overflow-y: hidden;
    padding: 0 20px 20px;

    &::-webkit-scrollbar-thumb {
      background-color: #fff;
      border-radius: 10px;
    }
    &::-webkit-scrollbar {
      width: 10px;
      height: 5px;
    }
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #fff;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #fff;
    }
  }
  &__content {
    display: flex;
    flex-direction: row;
    padding-top: 20px;
    justify-content: space-between;
    min-width: 1000px;

    &_item {
      background-image: url("/wp-content/themes/gecko/img/guides_tile1.jpg");
      border-radius: 7px;
      border: 2px solid white;
      padding: 60px 10px 40px;
      height: fit-content;
      width: 32%;
      &_desc {
        text-align: left;
      }
      &_title {
        color: white;
        font-size: 35px;
        text-transform: uppercase;
        font-weight: 700;
        line-height: 37px;
        text-align: center;
        & p {
          font-size: 41px;
          font-weight: 800;
        }
        div {
          display: block;
          width: 50px;
          height: 2px;
          background: white;
          margin: 25px auto;
        }
      }
      &_type {
        display: block;
        padding: 10px 20px;
        background-color: #925edb;
        border-radius: 30px;
        margin: 15px auto;
        width: fit-content;
        color: white;
        font-size: 16px;
        text-align: center;
      }
    }
  }
  &__see_more {
    padding-top: 70px;
    div &_button {
      text-transform: uppercase;
      font-weight: 700;
      background-color: $light-background-color;
      padding: 15px 30px;
      display: flex;
      width: fit-content;
      margin: 0 auto;
      & svg {
        margin-left: 10px;
      }
    }
  }
}
.news-section {
  //padding: 50px 0;
  text-align: center;
  width: 100%;
  & .nav-tab-wrapper {
    @include media("<=820px") {
      display: flex;
      overflow-x: auto;
    }
  }
  &__news_feed_container {
    min-width: 370px;
    overflow-x: auto;
    overflow-y: hidden;
    padding-bottom: 20px;

    &::-webkit-scrollbar-thumb {
      background-color: #925edb;
      border-radius: 10px;
    }
    &::-webkit-scrollbar {
      width: 10px;
      height: 5px;
    }
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #925edb;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #925edb;
    }
  }
  &__news_feed {
    display: grid;
    grid-template-columns: 45% 25% 25%;
    gap: 20px;
    align-items: center;
    min-width: 1000px;

    &::-webkit-scrollbar-thumb {
      background-color: #925edb;
      border-radius: 10px;
    }
    &::-webkit-scrollbar {
      width: 10px;
      height: 5px;
    }
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #925edb;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #925edb;
    }

    &_image {
      height: 155px;
      overflow: hidden;
      border-radius: 5px;
      & img {
        height: 100%;
      }
    }

    @include media("<=800px") {
      display: flex;
    }
  }

  &__news_feed_single_item {
    @include media("<=800px") {
      min-width: 220px;
    }
    &:nth-child(1) {
      grid-row: 1/3;
      grid-column: 1;
      overflow: hidden;
      height: fit-content;
      & .news-section__news_feed_image {
        height: 450px;
        display: flex;
        justify-content: center;
        @include media("<=800px") {
          height: 155px;
        }
      }
      @include media("<=800px") {
        overflow: visible;
      }
      img {
        height: 100%;
        @include media("<=800px") {
          width: 100%;
        }
      }
    }
  }
  & .news-section-category {
    margin: 50px auto 0;
    &__news_feed {
      width: 95%;
      margin: 0 auto;
      &_info_text h4 {
        //font-size: 22px;
      }
    }
  }
  &__title {
    margin: 0 auto 30px;
    @include media("<=1000px") {
      margin-top: -60px;
    }
    text-align: center;
    & svg {
      margin-bottom: 20px;
    }
    &_h3 {
      font-size: 42px;
      font-weight: 700;
      color: $paragraph-text-color;
      padding-bottom: 12px;
      @include media("<=800px") {
        font-size: 22px;
      }
    }
    &_sub {
      font-size: 16px;
      font-weight: 400;
      color: $paragraph-text-color;
      line-height: 30px;
      margin: 0 7vw;
    }
  }
}

.showcase {
  padding: 50px 0;
  @include media("<=1000px") {
    padding-bottom: 0;
  }
  & rs-slide {
    border-radius: 20px;
  }
  & .inner {
    text-align: center;
  }
  &__title {
    margin: 0 auto 30px;
    & svg {
      margin-bottom: 20px;
    }
    &_h3 {
      font-size: 42px;
      font-weight: 700;
      color: $paragraph-text-color;
      padding-bottom: 12px;
      @include media("<=800px") {
        font-size: 22px;
      }
    }
    &_sub {
      font-size: 16px;
      font-weight: 400;
      color: $paragraph-text-color;
      line-height: 30px;
      margin: 0 7vw;
    }
  }
}

.home {
  & .faq {
    & .section_title_repeat {
      font-size: 42px;
    }
  }
}
