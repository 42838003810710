@import "../vars";
@import "../reset";
@import "../breakpoints";

.best_casinos {
  padding: 50px 0 0;
  &__inner {
    max-width: 1100px;
    text-align: center;
  }
  &__title {
    margin: 90px auto 30px;
    &_h3 {
      font-size: 42px;
      font-weight: 700;
      color: $paragraph-text-color;
      padding-bottom: 12px;
    }
    &_sub {
      font-size: 16px;
      font-weight: 400;
      color: $paragraph-text-color;
      line-height: 30px;
      margin: 0 7vw;
    }
  }
  &__content {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 30px;
    padding-top: 20px;
    &_item {
      border-radius: 15px;
      box-shadow: #0000003f 2px 2px 15px 0;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      align-items: center;
      padding: 20px;

      @include media("<=800px") {
        grid-template-columns: 100%;
        margin: 10px 15px;
      }
      &_stars {
        border-left: 1px solid #e6e6e6;
        border-right: 1px solid #e6e6e6;

        @include media("<=800px") {
          border: none;
        }
        & p {
          font-size: 40px;
          font-weight: 500;
        }
      }
      &_img {
        width: 160px;
        margin: 0 auto;

        @include media("<=800px") {
          margin: 0 auto;
        }
      }
      &_desc {
        text-align: center;
        color: $background-text-color;
        & a {
          font-family: Inter;
          font-size: 15px;
          font-weight: 400;
          line-height: 10px;
          letter-spacing: 0em;
          text-align: left;
          color: #585858;
          text-decoration: underline;

          @include media("<=800px") {
            display: none;
          }
        }

        @include media("<=800px") {
          margin: 0 auto;
          text-align: center;
        }
      }
      &_title {
        font-size: 23px;
        font-weight: 600;
        color: $paragraph-text-color;

        @include media("<=800px") {
          text-align: center;
        }
      }
      &_text {
        font-size: 20px;
        font-weight: 500;
      }
      div &_engage {
        padding: 10px 65px;
        height: 44px;
        width: 200px;
        text-transform: uppercase;
        font-size: 18px;
        font-weight: 600;
        color: $primary-background-color;
        background-color: $secondary-background-color;
      }
      &_links {
        @include media("<=800px") {
          margin-top: 15px;
        }
      }
      &_links div {
        margin-top: 15px;
        & a {
          color: $accent-text-color;
          text-decoration: underline;
          margin-left: 10px;
        }
        & a:first-child {
          display: none;
          @include media("<=800px") {
            display: inline;
          }
        }
      }
    }
  }
  &__see_more {
    padding-top: 70px;
    div &_button {
      text-transform: uppercase;
      font-weight: 700;
      padding: 15px 30px;
      background-color: $light-background-color;
      display: flex;
      width: fit-content;
      margin: 0 auto;
      & svg {
        margin-left: 10px;
      }
    }
  }
}

.casino_list {
  &__casinos {
    margin: 40px auto;
  }
  &__single_item {
    width: 255px;
    background: #ffffff;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 10px 15px 0 #00000033;
    transition: all 0.3s ease-in-out;
    &_title h3 {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 10px;
      text-align: center;
      height: 45px;
      @include media("<=800px") {
        font-size: 14px;
      }
    }
    &_thumb {
      width: 100%;
      height: 133px;
      background-size: 90%;
      background-position: center;
      background-repeat: no-repeat;
      border-radius: 10px 10px 0 0;
    }
    &_info {
      margin-top: 20px;
      width: 77%;
    }
    &-corner_bage {
      color: white;
      padding: 3px 15px 2px;
      border-radius: 6px 0 0 0;
      width: 85px;
      overflow: hidden;
      & span {
        position: relative;
        font-size: 12px;
        font-weight: 400;
        z-index: 2;
      }
    }
    &_gameplay,
    &_provider {
      display: flex;
      margin: 0;
      justify-content: space-between;
      min-height: 45px;
      align-items: center;
      & img {
        max-width: 80px;
        margin-right: 5px;
      }
      &_title {
        font-size: 16px;
        font-weight: 600;
        flex: auto;
        @include media("<=800px") {
          font-size: 14px;
          font-weight: 500;
        }
      }
      &_type {
        font-size: 16px;
      }
    }
    &-background {
      transform: skewX(338deg) translate(-32px, -23px);
      width: 85px;
      height: 25px;
      z-index: 0;
    }
    & .sb-avatar {
      position: absolute;
      top: 4px;
      left: 15px;
      display: inline-block;
      width: 50px;
      height: 50px;
      border-radius: 500px;
      & > div {
        width: 50px;
        height: 50px;
        line-height: 36px;
      }
    }
    &_btns {
      &-button {
        & a {
          display: block;
          border-radius: 23px;
          width: 205px;
          height: 48px;
          background: linear-gradient(180deg, #94c81f 0%, #a2d532 100%);
          color: white;
          text-align: center;
          font-size: 18px;
          margin: 12px 10px 30px;
          padding: 12px 0;
          font-weight: 600;
          text-transform: uppercase;
        }
      }
    }

    & .scoreCircle {
      width: 80px;
      margin: 80px -20% 0 0;
      position: absolute;
      & svg {
        display: block;
        margin: initial;
        width: initial;
        height: initial;
        & text {
          font-size: 27px;
          font-weight: 300;
          text-anchor: middle;
          fill: #818a91;
          &.heavy {
            font-weight: 600;
            font-size: 45px;
          }
        }
        & circle {
          transition: stroke-dashoffset 2s cubic-bezier(0.16, 0.79, 0.46, 1.03);
          fill: white;
          transform: translate(100px, 100px) rotate(-89.9deg);
        }
      }
    }
    & .arc-background {
      stroke-width: 3px;
      stroke: rgba(0, 0, 0, 0.05);
    }

    & .arc {
      stroke-width: 8px;
      stroke: #279fdc;
    }
  }
  &__casinos_box {
    display: flex;
    flex-wrap: wrap;
    gap: 35px 20px;
    margin-bottom: 60px;
    justify-content: space-around;
    @include media("<=800px") {
      flex-wrap: nowrap;
      max-width: 380px;
      overflow: auto;
      justify-content: flex-start;
      margin-left: 20px;
    }
  }
}
