@import '../vars';
@import '../reset';
@import '../breakpoints';

// this is the style sheet for the guides archive page

.guides{

    &__banner{
        background-color: $primary-background-color;
        padding: 50px 20px 40px;
    }
    &__banner_heading{
        font-size: 34px;
        padding-top: 10px;
    }
    &__banner_paragraph{
        max-width: 1100px;
    }
    &__banner_btns{
        padding: 20px 0;

        @include media ('<=500px'){
            display: grid;
            grid-gap: 10px 0;
            text-align: center;
        }

        a{
            font-size: 14px;
            background-color: $accent-color;
            color: $tetiary-color;
            padding: 8px 20px;
            border-radius: 20px;
            transition: all ease 0.5s;

            @include media ('<=500px'){
                display: inline-block;
                margin: 0 auto;
            }
        }
        a:hover{
            transition: all ease 0.5s;
            filter: saturate(2);
        }
    }

    // Styling for the guides slider
    &__slider{
        overflow-x: hidden;
    }
    // styling for the guides post list
    &__guides_container{
        max-width: 1000px;
        margin: 90px auto;
        padding: 0 20px;
    }
    &__guides_single{
        display: grid;
        grid-template-columns: 35% 60%;
        grid-gap: 0 20px;
        margin: 40px 0;

        @include media ('<=tablet'){
            grid-template-columns: 100%;
            grid-gap: 20px 0;
        }
    }
    &__guides_image{
        //display: flex;
        //justify-content: left;
        //align-content: flex-start;
        //align-items: center;
        img{
            width: 100%;
            border-radius: 10px;
            filter: drop-shadow(2px 2px 4px #c5c5c5);
        }

        img:hover{
            filter: drop-shadow(2px 2px 4px #949494);
        }
    }
    &__guides_info{
        display: grid;
        justify-content: flex-start;
        align-content: center;
        align-items: center;

        > h3 a{
            color: $primary-background-color;
            font-size: 18px;
            text-transform: uppercase;
            font-weight: 700;
            padding-bottom: 4px;
            transition: all ease 0.5s;
            font-family: "Montserrat", sans-serif;
        }
        h3 a:hover{
            color: $secondary-background-color;
            text-decoration: underline;
            transition: all ease 0.5s;
        }
    }
    &__guides_info{
        a{
            font-size: 17px;
            font-weight: 600;
        }
    }
    &__author_descr{
        span a{
            font-size: 16px;
            color: $accent-text-color;
            text-decoration: none;
            cursor: pointer;
            &:hover{
                color: $secondary-background-color;
                text-decoration: underline;
                transition: all ease 0.5s;
            }
        }
    }
    &__author_excerpt{
        padding-top: 5px;
        font-size: 16px;

        + p{
            line-height: 25px;
        }
    }
    &__author_read_more{
        display: flex;
        align-content: center;
        align-items: center;
        padding-top: 10px;
        cursor: pointer;

        a{
            color: $primary-background-color;
            font-family: "Montserrat", sans-serif;
            font-size: 14px;
        }
        a:hover{
            color: $secondary-background-color;
        }

        span{
            transform: rotate(270deg);
            margin-left: 10px;
        }
    }
}