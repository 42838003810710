@import 'scss/vars';
@import 'scss/breakpoints';

@include media ('<=800px'){
    .center-text {
        text-align: center;
        display: flex;
        justify-content: center;
        margin: 0 auto;
    }

    .remove-padding-btm{
        padding-bottom: 0!important;
    }

    .setHeight{
        height: 200px;
        overflow: hidden;
    }

    .setPosition{
        position: relative;
    }

    .mobile-overflow{
        display: flex;
        overflow-x: hidden;
        min-width: 390px;
        flex-direction: row;
    }

    .guides_tag_feed_mobile{
        display: grid;
        width: 100%;
    }

    .padding-pagination{
        padding: 20px 0;
    }

    .breadcrumb-mobile{
        display: flex;
        flex-wrap: wrap;
    }

    .mobile_grid_inner{
        display: grid;
        grid-template-columns: 100%;
        padding: 0 20px;
    }

    .mobile_tag_feed_inner{
        display: grid!important;
        grid-template-columns: 100%;
        width: 100%!important;
    }

    .mobile-change-order-to-1{
        order: -1;
    }

    .mobile-games-archive{
        + div{
            width: unset;
            margin-right: 0;
            min-width: unset;
        }
    }
}

@include media ('>=800px'){
    .hide-desktop{
        display: none;
    }
}