@import "../vars";
@import "../reset";
@import "../breakpoints";

.page_compare {
  background-color: #fcfcfc;
  & .default__banner_img {
    position: relative;
    margin-bottom: -70px;
    z-index: 1000;
    img {
      width: 400px;
      @include media("<=800px") {
        width: 100%;
      }
    }
  }

  &__categories_box {
    margin: 70px auto 50px;
    position: relative;
    overflow: hidden;
    &_title {
      text-align: center;
      h3 {
        font-family: Inter;
        font-size: 30px;
        font-weight: 700;

        @include media("<=800px") {
          max-width: 90%;
          margin: 0 auto;
        }
      }
      & p {
        font-family: Inter;
        font-size: 16px;
        font-weight: 400;
        margin: 20px 20px 40px;
        color: $accent-text-color;
      }
    }
    &_container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      gap: 32px;
      padding-bottom: 20px;

      @include media("<=800px") {
        padding: 12px 0;
        &::-webkit-scrollbar {
          display: none;
        }
        overflow-x: auto;
        flex-wrap: nowrap;
        justify-content: flex-start;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */

        &::-webkit-scrollbar-thumb {
          background-color: #925edb;
          border-radius: 10px;
        }
        &::-webkit-scrollbar {
          width: 10px;
          height: 5px;
        }
        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 5px #dacaf0;
          border-radius: 10px;
        }
        &::-webkit-scrollbar-thumb:hover {
          background: #925edb;
        }
      }
      &_item {
        width: 30%;
        border-radius: 10px;
        background-color: white;
        box-shadow: #e7e7e7 2px 2px 15px 0;
        padding: 30px;
        display: flex;
        flex-direction: column;
        & a:first-child {
          min-height: 185px;
          margin-bottom: 13px;
        }
        @include media("<=800px") {
          min-width: 80%;
          &:first-child {
            margin-left: 32px;
          }

          &:last-child {
            margin-right: 32px;
          }
        }
        &_title {
          font-family: Inter;
          font-size: 18px;
          line-height: 22px;
          font-weight: 600;
          margin-bottom: 8px;
          color: $paragraph-text-color;
          height: 44px;
        }
        &_subtitle {
          font-family: Inter;
          font-size: 14px;
          line-height: 22px;
          font-weight: 400;
          color: $paragraph-text-color;
        }
      }
      &_item_top_pick {
        border-radius: 10px;
        background-color: #f9f6fd;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        padding: 15px 5px;
        & img {
          max-width: 120px;
        }
        &_icon {
          display: flex;
          align-items: center;
        }
        &_info {
          text-align: center;
          &_title {
            font-family: Inter;
            font-size: 18px;
            font-weight: 700;
            color: $paragraph-text-color;
          }
          &_rating {
            color: $accent-color;
            & strong {
              font-size: 22px;
            }
          }
        }
      }
    }
  }
  &__filter_box_container {
    background-color: white;
    border-radius: 10px;
    box-shadow: #e7e7e7 2px 2px 15px 0;
    padding: 52px 40px 118px;

    @include media("<=800px") {
      border-radius: unset;
    }

    &_title {
      text-align: center;
      & h3 {
        font-family: Inter;
        font-size: 30px;
        font-weight: 700;
      }
      & p {
        font-family: Inter;
        font-size: 16px;
        font-weight: 400;
        margin: 20px 20px 40px;
        color: $accent-text-color;
      }
    }
    &_filters {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-basis: 100%;
      gap: 30px;

      @include media("<=800px") {
        flex-direction: column;
      }
      &_item {
        padding: 50px;
        text-align: center;
        border-radius: 10px;
        flex: 1;
        box-shadow: #e7e7e7 2px 2px 15px 0;

        @include media("<=800px") {
          padding: 24px 20px 44px 20px;
        }

        & h4 {
          font-family: Inter;
          font-size: 24px;
          font-weight: 600;
          margin-top: 15px;
        }
        & p {
          font-family: Inter;
          font-size: 16px;
          font-weight: 400;
          color: $accent-text-color;
          min-height: 100px;
          margin: 12px 0 22px;
        }
        &_engage.round_button {
          padding: 8px 44px;
          width: fit-content;
          margin: 0 auto;
          cursor: default;

          @include media("<=800px") {
            width: 100%;
          }

          & a {
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: Inter;
            font-size: 16px;
            line-height: 34px;
            font-weight: 600;
            padding: 0px;
            text-transform: uppercase;

            & svg {
              margin-bottom: 2px;
              margin-left: 8px;
            }
          }
        }
      }
    }
  }
}
