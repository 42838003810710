@import '../vars';
@import '../reset';
@import '../breakpoints';

.footer{
    background-color: $primary-background-color;
    padding: 50px 0;
    overflow-x: hidden;
    &__inner{
        color: $tetiary-color;
        display: grid;
        grid-template-columns: 100%;
        justify-content: center;
        margin: 0 auto;
    }
    &__first_row{
        display: grid;
        justify-content: space-between;
        align-items: center;
        grid-template-columns: 73% 27%;
        border-bottom: 1px solid #c8bdf1;
        padding-bottom: 40px;
        @include media ('<=600px'){
            display: flex;
            flex-flow: column;
            justify-content: flex-start;
            align-items: flex-start;
            border-bottom: none;
        }
        &_inner{
            display: flex;
            justify-content: space-between;

            @include media ('<=800px'){
                display: none;
            }
        }
        &_inner > div{
            flex: auto;
            max-width: 150px;
        }
        &_join{
            text-align: left;
            background-color: $light-background-color;
            color: $tetiary-color;
            padding: 40px 20px;
            margin: 20px 0 0 20px;

            @include media ('<=800px'){
                margin: 0 auto;
                padding: 40px 50px;
            }
            & h2{
                margin-left: 10px;
                margin-bottom: 15px;
                font-weight: 700;
                font-size: 16px;
            }
            & p{
                margin-bottom: 35px;
                font-size: 20px;
            }
            & .es_subscription_message.success{
                color: $secondary-background-color;
            }
            & .emaillist form{
                display: flex;
                justify-content: center;
                & .es-field-wrap{
                    width: 80%;
                }
                & .es_required_field{
                    height: 50px;
                    width: 100%;
                    border-radius: 5px 0 0 5px;
                    border: none;
                    font-size: 14px;
                    padding-left: 20px;
                }
                & input.es_subscription_form_submit.disabled{
                    background-color: $accent-text-color;
                    &:hover{
                        padding-left: 0;
                        background-color: $accent-text-color;
                        cursor: pointer;
                        transition: none;
                    }
                }
                & input.es_subscription_form_submit{
                    font-family: "Font Awesome 6 Free";
                    font-weight: 900;
                    width: 50px;
                    height: 50px;
                    color: $tetiary-color;
                    background-color: $secondary-background-color;
                    border-radius: 0 5px 5px 0;
                    border: none;
                    font-size: 20px;
                    transition: all ease 0.5s;
                    &:hover{
                        padding-left: 10px;
                        background-color: #ffcd5d;
                        cursor: pointer;
                        transition: all ease 0.2s;
                    }
                }
            }
        }
    }

    &__mobile_menu_row{
        margin: 20px auto;
        text-align: center;
    }

    &__footer_title{
        padding-bottom: 10px;
        font-weight: 700;
        font-size: 16px;

        @include media ('<=800px'){
            padding-bottom: 0;
        }
        
        @include media ('<=400px'){
            font-size: 18px;
            padding-bottom: 10px;
        }
        h3{
            font-size: 20px;
            font-weight: 700;
            @include media ('<=400px'){
                font-size: 17px;
            }
        }
    }

    &__footer_title + ul li{
        list-style-type: none;
    }

    &__footer_title + ul li a{ //styling the footer menu
        color: #c8bdf1;
        font-size: 16px;

        @include media ('<=400px'){
            font-size: 14px;
        }
    }

    &__footer_title + ul li a:hover{
        color: $tetiary-color;
    }

    &__footer_title + ul li{ //styling for the footer menu
        padding: 0 0 5px;
    }

    &__second_row{
        padding: 40px 0 20px;
        display: flex;
        justify-content: space-between;

        @include media ('<=800px'){
            display: grid;
            justify-content: center;
            text-align: center;
            padding: 0;
        }
        &_social{
            display: flex;
            flex-direction: row;
            @include media ('<=800px'){
                margin: 0 auto;
            }
            & a{
                display: block;
                width: 40px;
                height: 40px;
                text-align: center;
                border-radius: 50%;
                border: 1px solid #c8bdf1;
                color: $tetiary-color;
                font-size: 20px;
                margin: 0 5px;
                & i{
                    margin-top: 10px;
                }
            }
        }
        &_inline_menu{
            @include media ('<=800px'){
                display: none;
            }
        }
        &_inline_menu a{
            margin: 10px 15px;
            color: $tetiary-color;
            text-align: center;
        }

    }

    &__about_us{
        padding: 20px;
        font-size: 16px;
        display: none;

        @include media ('<=800px'){
            display: block;
        }
    }

    &__second_inline_menu_repeat{
        text-align: center;
        margin: 0 auto;
        border-top: 1px solid rgb(229, 229, 229);
        padding: 20px 0;
        @include media ('>=800px'){
            display: none;
        }
        a{
            color: #fff;
            font-size: 12px;
            padding: 0 2px;
        }
    }

    &__mobile_menu_row{
        @include media ('>=800px'){
            display: none;
        }
    }

    &__first_menu_mobile{
        ul{
            list-style-type: none;
            padding-bottom: 20px;
            display: none;

            li:not(first-child) {
                padding: 5px 0;
            }

            li a{
                color: #fff;
                font-size: 18px;
                font-weight: 400;
            }
        }
    }

    &__first_menu_mobile_title{
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center;
        align-content: center;
        margin: 10px auto 0;
        font-size: 20px;
        
        h3{
            padding: 0 10px 0 0;
        }
    }

    &__third_row{
        text-align: center;
        padding-bottom: 30px;

        @include media ('<=800px'){
            display: flex;
            margin: 0 auto;
            padding-top: 20px;
        }
        & img{
            padding: 0 20px;
            @include media ('<=800px'){
                padding: 0 5px;
                &:first-child{
                    width: 90px;
                    height: 25px;
					align-items:center;
                }
    
                &:nth-child(2){
                    max-width: 150px;
                    height: 30px;
                }
    
                &:nth-child(3){
                    width: 40px;
                    height: 35px;
                }

                &:nth-child(4){
                    width: 40px;
                    height: 35px;
                }
            }
        }
    }

    &__fourth_row{
        text-align: center;
        p{
            font-size: 14px;
            font-weight: 400;
        }
    }
    .es_subscription_form{
        & .es-field-wrap{
            flex: 1 55%;
        }
        & .es_subscription_form_submit{
            flex: 1;
        }
        flex-wrap: wrap;
        &_agree{
            flex: 2 100%;
            display: flex;
            margin-bottom: 0;
            & label{
                font-family: Inter;
                font-size: 12px;
                font-weight: 300;
                & a{
                    color: $secondary-background-color;
                    text-decoration: underline;
                }
            }
            &_cheker{
                width: 20px;
                height: 20px;
                margin: 5px 15px 5px 0;
                flex: 1 20%;
                border-radius: 3px;
                &:checked {
                    accent-color: $accent-color;
                }
            }
        }
    }
}