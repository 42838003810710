@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@300;500;600;800&display=swap");
@import url("https://fonts.cdnfonts.com/css/gilroy-bold");
h1, h2 {
  font-family: 'Inter', sans-serif;
  font-weight: 700; }

h3, h4 {
  font-family: 'Inter', sans-serif;
  font-weight: 400; }

p,
a,
button,
input,
select,
option,
ul,
li,
table,
td,
tr,
th,
tbody,
thead,
div,
header,
section,
aside,
figure,
label,
textarea,
::placeholder {
  font-family: 'Inter', sans-serif;
  font-weight: 400; }

span {
  font-family: 'Inter', sans-serif;
  font-weight: 700; }

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none; }

a {
  text-decoration: none; }

ul,
ol,
li {
  margin: 0;
  padding: 0; }

html {
  scroll-behavior: smooth; }

small {
  font-size: inherit; }

/**
*
* Testing
*
**/
.top_pick {
  margin: 100px auto; }
  @media (max-width: 800px) {
    .top_pick {
      margin: 10px auto; } }
  .top_pick__title {
    font-family: Inter;
    line-height: 50px;
    margin: 50px auto 30px; }
    .top_pick__title h2,
    .top_pick__title h1 {
      font-size: 40px;
      font-weight: 700; }
      @media (max-width: 800px) {
        .top_pick__title h2,
        .top_pick__title h1 {
          font-size: 30px;
          line-height: 35px; } }
    .top_pick__title h3 {
      font-size: 22px;
      font-weight: 600; }
  .top_pick__description,
  .top_pick__description p {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 27px;
    margin: 20px auto; }
  .top_pick .nav-tab-wrapper {
    margin: 20px auto;
    display: flex;
    justify-content: center;
    gap: 128px; }
    .top_pick .nav-tab-wrapper .nav-tab {
      font-family: Inter;
      font-size: 14px;
      font-weight: 500;
      display: block;
      color: #292929;
      background: #c4b9ef;
      width: fit-content;
      border-radius: 20px;
      border: none; }
      .top_pick .nav-tab-wrapper .nav-tab.nav-tab-active {
        color: #f7f7f7;
        background: #8354D4; }
  .top_pick .tab-target {
    height: 270px;
    justify-content: space-around; }

.best_of_best_block {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 60px;
  margin-bottom: 30px; }
  @media (max-width: 800px) {
    .best_of_best_block {
      grid-template-columns: 100%; } }
  .best_of_best_block.bottom_offset {
    padding-bottom: 210px; }
  @media (max-width: 800px) {
    .best_of_best_block__left {
      padding: 0 20px; } }
  .best_of_best_block__right {
    padding-bottom: 50px; }
  .best_of_best_block .archive_casinos__single_item_terms {
    font-family: Inter;
    font-size: 10px;
    font-weight: 300;
    line-height: 15px;
    letter-spacing: 0em; }
  .best_of_best_block .archive_casinos__text {
    margin: 15px auto; }
  .best_of_best_block .archive_casinos__engage_play {
    padding: 11px 130px; }
  .best_of_best_block .archive_casinos__single_item_inner {
    padding: 45px 40px 20px; }
  .best_of_best_block .archive_casinos__single_item_head {
    align-items: center;
    flex-direction: column; }
  .best_of_best_block .archive_casinos__image img {
    max-height: 90px;
    width: auto; }
  .best_of_best_block .archive_casinos__info {
    width: 246;
    height: 95px;
    padding-top: unset; }
  .best_of_best_block.blue_inner.screen_offset .nav-tab.nav-tab-active {
    color: #f7f7f7;
    background: #8354D4; }
  .best_of_best_block.blue_inner {
    display: flex; }
    .best_of_best_block.blue_inner .best_of_best_block__right > * {
      color: #f7f7f7; }
    .best_of_best_block.blue_inner .nav-tab-wrapper {
      gap: 20px; }
    .best_of_best_block.blue_inner .nav-tab.nav-tab-active {
      color: #292929;
      background: #f7f7f7; }
    .best_of_best_block.blue_inner .tab-target.tab-target-active {
      display: block; }
    .best_of_best_block.blue_inner .category_screenshots .tab-target {
      height: 400px;
      max-width: 480px;
      text-align: end; }
      .best_of_best_block.blue_inner .category_screenshots .tab-target img:nth-child(1) {
        z-index: 2;
        position: inherit;
        max-width: 440px; }
      .best_of_best_block.blue_inner .category_screenshots .tab-target img:nth-child(2) {
        top: -30px;
        width: 249px;
        position: relative; }
  .best_of_best_block.screen_offset {
    flex-direction: column; }
    div .best_of_best_block.screen_offset.blue_inner .category_screenshots .tab-target.tab-target-active {
      display: flex;
      height: 270px;
      max-width: unset; }
      div .best_of_best_block.screen_offset.blue_inner .category_screenshots .tab-target.tab-target-active > img {
        position: relative;
        max-width: unset;
        top: unset;
        width: unset; }
    .best_of_best_block.screen_offset .category_screenshots {
      position: relative;
      height: 140px;
      top: -221px; }
    .best_of_best_block.screen_offset .tab-target {
      height: 270px;
      justify-content: space-around; }

.author-review {
  background: #8354D4; }
  @media (max-width: 800px) {
    .author-review .hot-sites__content {
      display: grid;
      grid-template-columns: 100%; } }
  .author-review .hot-sites__content_item {
    box-shadow: none; }
  .author-review__inner {
    padding: 20px 10px 40px; }
  .author-review__shape {
    width: 100%;
    overflow-x: hidden; }
  .author-review__shape-bottom {
    transform: scale(1.05, 1); }
  .author-review__shape-bottom, .author-review__shape-top {
    background-color: white;
    width: 100%;
    height: 50px;
    border-radius: 50% / 75%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border: 1px solid white; }
  .author-review__shape-top {
    transform: scale(1.05, -1); }
  .author-review__header-title {
    color: #FFFFFF;
    font-weight: 800;
    font-size: 30px;
    margin: 70px 0 50px; }
  .author-review__header-author {
    display: flex; }
    .author-review__header-author-data {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly; }
      .author-review__header-author-data > * {
        font-size: 16px;
        color: #FFFFFF; }
    .author-review__header-author img {
      border-radius: 45px;
      border: 4px solid #a87ee2;
      max-width: 68px;
      margin-right: 10px; }
  .author-review__best-bonus-title {
    font-size: 22px;
    font-weight: 700;
    color: #FFFFFF;
    padding-bottom: 15px; }
  .author-review__author-articles {
    background-color: #a87ee2;
    border-radius: 5px;
    padding: 60px 128px; }
    @media (max-width: 800px) {
      .author-review__author-articles {
        padding: 20px 5px; } }
    .author-review__author-articles-block {
      display: flex;
      flex-direction: column; }
  .author-review__content_item {
    background-color: #FFFFFF;
    border-radius: 5px;
    margin-bottom: 15px;
    display: flex;
    padding: 21px 85px;
    align-items: center;
    justify-content: space-between; }
    @media (max-width: 800px) {
      .author-review__content_item {
        padding: 21px 25px; } }
    .author-review__content_item_welcome_offer {
      font-weight: bold; }
    .author-review__content_item div {
      font-size: 20px; }
    .author-review__content_item img {
      height: 75px; }
  .author-review__author-articles-title {
    display: flex;
    justify-content: space-around;
    padding: 15px; }
    .author-review__author-articles-title span {
      font-weight: 700;
      font-size: 20px;
      color: #FFFFFF; }
  .author-review__content_item_img {
    display: flex;
    flex-direction: column;
    align-items: center; }
    .author-review__content_item_img a {
      text-decoration: underline;
      color: #585858;
      font-size: 14px;
      padding: 5px; }
  .author-review__foreword {
    line-height: 35px; }
    .author-review__foreword div {
      background-color: #a87ee2;
      border-radius: 5px;
      padding: 60px 128px; }
      @media (max-width: 800px) {
        .author-review__foreword div {
          padding: 20px 0; } }
      .author-review__foreword div table {
        width: 100%;
        background: none; }
        .author-review__foreword div table th {
          font-weight: 700;
          font-size: 20px;
          color: #fff;
          text-align: center; }
        .author-review__foreword div table tr:first-child {
          background: none;
          border: none;
          height: 50px; }
          .author-review__foreword div table tr:first-child td {
            font-weight: 700; }
        .author-review__foreword div table tr {
          background-color: #fff;
          height: 150px; }
          .author-review__foreword div table tr td {
            border-top: 5px solid #a87ee2;
            border-bottom: 5px solid #a87ee2;
            color: #292929;
            font-size: 20px;
            text-align: center; }
          .author-review__foreword div table tr td:first-child {
            border-left: 5px solid #a87ee2;
            border-bottom-left-radius: 20px;
            border-top-left-radius: 20px; }
            .author-review__foreword div table tr td:first-child img {
              height: 80px; }
            .author-review__foreword div table tr td:first-child a {
              text-decoration: underline;
              color: #585858;
              font-size: 14px;
              padding: 5px; }
          .author-review__foreword div table tr td:last-child {
            border-right: 5px solid #a87ee2;
            border-bottom-right-radius: 20px;
            border-top-right-radius: 20px;
            font-weight: 700; }
    .author-review__foreword br {
      content: "";
      margin: 3em;
      display: block;
      font-size: 24%; }
    .author-review__foreword a {
      color: #FCC13E;
      text-decoration: underline; }
    .author-review__foreword li {
      margin-left: 20px;
      margin-bottom: 10px; }
    .author-review__foreword h2.heading {
      font-weight: 800;
      font-size: 30px; }
    .author-review__foreword h2,
    .author-review__foreword h3 {
      margin: 20px 0 15px; }
  .author-review__foreword, .author-review__afterword, .author-review__final {
    margin-top: 30px; }
    .author-review__foreword,
    .author-review__foreword p, .author-review__afterword,
    .author-review__afterword p, .author-review__final,
    .author-review__final p {
      font-size: 16px;
      color: #FFFFFF;
      margin-bottom: 45px; }
