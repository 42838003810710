@import '../vars';
@import '../reset';
@import '../breakpoints';

.our_contacts{
    & .inner{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    &__container{
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2,50%);

        @include media ('<=800px'){
            grid-template-columns: 100%;
        }
    }

    &__mobile_banner_img{
        margin: auto;
        margin-top: 30px;
        @include media ('>=800px'){
            display: none;
        }
    }
    &__banner{
        & .about__banner_heading{
            @include media ('<=800px'){
                display: none;
            }
            &.mobile{
                display: block;
                font-size: 22px;
                @include media ('>=800px'){
                    display: none;
                }
            }
        }
    }
    & .default_repeater{
        &__options{
            grid-template-columns: repeat(2,45%);
            grid-gap: 30px;

            @include media ('<=800px'){
                grid-template-columns: 100%;
            }
            &_item{
                margin-top: 10px;
                & img{
                    height: unset;
                }
                &_title{
                    text-transform: unset;
                    font-size: 18px;
                    margin: 10px;
                }
                &:last-child{
                    & p{
                        width: 130px;
                        margin: auto;
                    }
                }
            }
        }
        &__left{
            @include media ('<=800px'){
                box-shadow: 1px 1px 18px rgba(0, 0, 0, 0.199);
                background-color: #fff;
                border-radius: 10px;
                margin: 50px 15px;
            }
        }
        &__right{
            background-color: white;
            border-radius: 15px;
            box-shadow: #0000003F 2px 2px 15px 0;
            margin: -220px 20px 20px;
            z-index: 20;
            padding: 40px;
            height: fit-content;

            @include media ('<=800px'){
                margin-top: -30px;
                order: -10;
                padding: 25px;
            }
            & .wpcf7{
                p{
                    width: 100%;
                    text-align: center;
                    display: flex;
                    flex-direction: column;
                }

                &-spinner {
                    display: none;
                }

                & .title{
                    text-align: center;
                    margin: 20px;
                    font-weight: 600;
                    font-size: 20px;
                    margin: 15px;
                    @include media ('<=800px'){
                        font-size: 16px;
                    }

                }
                & input[type="submit"]{
                    width: max-content;
                    background: $secondary-background-color;
                    border-radius: 30px;
                    padding: 14px 44px;
                    color: #864CD7;
                    text-transform: uppercase;
                    margin-left: auto;
                    margin-right: auto;
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 1;

                    @include media ('<=800px'){
                        width: 100%;
                    }
                }
                & input{
                    ::placeholder{
                        color: #9A9A9A;
                    }
                    padding-left: 10px;
                    background: white;
                    border: 1px solid #E3E3E3;
                    height: 47px;
                    font-size: 17px;
                    width: 100%;
                    border-radius: 5px;
                    @include media ('<=800px'){
                        font-size: 14px;
                        ::placeholder{
                            color: #7D7D7D;
                        }
                    }
                }
                & textarea{
                    ::placeholder{
                        color: #9A9A9A;
                    }
                    padding: 15px;
                    background: white;
                    border: 1px solid #E3E3E3;
                    border-radius: 5px;
                    font-size: 17px;
                    width: 100%;
                    margin-bottom: 20px;
                    @include media ('<=800px'){
                        font-size: 14px;
                        ::placeholder{
                            color: #7D7D7D;
                        }
                    }
                }
            }
        }
    }
    &__img_left{
        position: absolute;
        margin-top: -62px;
        margin-left: 343px;

        @include media ('<=800px'){
            display: none;
        }
    }

    &__img_right{
        transform: rotate(-36deg) scale(.5,.5);
        position: absolute;
        margin-top: -44px;
        margin-left: -154px;

        @include media ('<=800px'){
            display: none;
        }
    }
}