@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@300;500;600;800&display=swap");
@import url("https://fonts.cdnfonts.com/css/gilroy-bold");
h1, h2 {
  font-family: 'Inter', sans-serif;
  font-weight: 700; }

h3, h4 {
  font-family: 'Inter', sans-serif;
  font-weight: 400; }

p,
a,
button,
input,
select,
option,
ul,
li,
table,
td,
tr,
th,
tbody,
thead,
div,
header,
section,
aside,
figure,
label,
textarea,
::placeholder {
  font-family: 'Inter', sans-serif;
  font-weight: 400; }

span {
  font-family: 'Inter', sans-serif;
  font-weight: 700; }

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none; }

a {
  text-decoration: none; }

ul,
ol,
li {
  margin: 0;
  padding: 0; }

html {
  scroll-behavior: smooth; }

small {
  font-size: inherit; }

/**
*
* Testing
*
**/
.single_casino {
  overflow: hidden; }
  @media (max-width: 800px) {
    .single_casino .inner {
      max-width: 90%; } }
  .single_casino__body {
    margin-top: 50px; }
  .single_casino__banner {
    background-color: #8354D4; }
  .single_casino__banner_box {
    display: flex;
    gap: 50px;
    padding-top: 20px; }
    @media (max-width: 800px) {
      .single_casino__banner_box {
        display: grid;
        gap: 0;
        padding-top: 0; } }
  .single_casino__we_recommend {
    margin: 40px auto;
    border-radius: 20px;
    padding: 30px 45px;
    box-shadow: 0 0 12px rgba(158, 158, 158, 0.55); }
    @media (max-width: 800px) {
      .single_casino__we_recommend {
        margin: 40px 0;
        padding: 30px 30px; } }
    .single_casino__we_recommend_title {
      margin: 25px auto;
      font-family: Inter;
      font-size: 30px;
      font-weight: 700; }
      @media (max-width: 800px) {
        .single_casino__we_recommend_title {
          font-size: 20px;
          text-align: center; } }
      @media (max-width: 800px) {
        .single_casino__we_recommend_title svg {
          width: 17px;
          height: 16px; } }
    .single_casino__we_recommend_feed {
      margin: 20px auto;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 40px; }
      @media (max-width: 800px) {
        .single_casino__we_recommend_feed {
          grid-template-columns: repeat(1, 1fr); } }
      .single_casino__we_recommend_feed_single_item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 15px; }
        @media (max-width: 800px) {
          .single_casino__we_recommend_feed_single_item {
            flex-wrap: wrap;
            gap: 10px; } }
        .single_casino__we_recommend_feed_single_item_logo img {
          max-width: 110px; }
        .single_casino__we_recommend_feed_single_item_rating {
          flex: 1; }
          .single_casino__we_recommend_feed_single_item_rating_title {
            font-family: Inter;
            font-size: 23px;
            font-weight: 600; }
            @media (max-width: 800px) {
              .single_casino__we_recommend_feed_single_item_rating_title {
                font-weight: 700;
                font-size: 16px; } }
        @media (max-width: 800px) {
          .single_casino__we_recommend_feed_single_item_button {
            flex-basis: 100%; }
            .single_casino__we_recommend_feed_single_item_button a {
              display: block; } }
  .single_casino__banner_info_introduction, .single_casino__banner_info_author {
    font-family: Inter;
    font-size: 18px;
    font-weight: 400; }
  @media (max-width: 800px) {
    .single_casino__banner_info {
      order: -1; }
      .single_casino__banner_info .breadcrumbs {
        padding-bottom: 10px; } }
  .single_casino__banner_info > * {
    color: #f7f7f7;
    padding-bottom: 20px; }
  .single_casino__banner_title {
    font-family: Inter;
    font-size: 42px;
    font-weight: 700; }
    @media (max-width: 800px) {
      .single_casino__banner_title {
        font-size: 22px; } }
  .single_casino__banner_info a {
    color: #FCC13E;
    text-decoration: underline; }
  .single_casino__banner_subheading,
  .single_casino__banner_subheading p {
    font-family: Inter;
    font-size: 23px;
    font-weight: 700;
    line-height: 33px;
    letter-spacing: 0em;
    text-align: center; }
  .single_casino__banner_image {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    display: block;
    min-height: 420px;
    background: white;
    min-width: 320px;
    max-width: 320px;
    z-index: 5;
    margin: 0 auto -90px;
    border-radius: 30px 30px 0 0;
    padding: 20px 30px 0; }
    .single_casino__banner_image > * {
      padding-bottom: 20px; }
  .single_casino__banner_thumb {
    width: 180px; }
  .single_casino__banner_btn_tc {
    display: block;
    padding-top: 5px;
    font-size: 11px;
    text-align: center;
    text-decoration: underline;
    color: #292929; }
    .single_casino__banner_btn_tc.muted {
      text-decoration: none;
      font-weight: 500;
      color: #7f8c8d;
      font-size: 16px; }
  .single_casino__banner_terms_condition {
    font-size: 14px; }
  .single_casino__banner_no_play {
    background-color: #f3eefb;
    border-radius: 10px;
    padding: 15px; }
    .single_casino__banner_no_play_title {
      font-family: Mulish;
      font-size: 14px;
      font-weight: 700;
      color: #8354D4;
      margin-bottom: 10px; }
    .single_casino__banner_no_play_subtitle {
      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px; }
  .single_casino__single_item_details {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    margin-left: 20px;
    align-items: center;
    width: 100%; }
    @media (max-width: 800px) {
      .single_casino__single_item_details {
        grid-template-columns: 100%; } }
    .single_casino__single_item_details p {
      padding-top: 15px;
      display: flex;
      align-items: center; }
      .single_casino__single_item_details p span {
        font-family: Inter;
        font-size: 18px;
        font-weight: 400;
        line-height: 31px;
        width: 80%;
        margin-left: 10px; }
    .single_casino__single_item_details i {
      color: #fff;
      display: block;
      height: 36px;
      width: 36px;
      border-radius: 20px;
      margin-right: 10px;
      text-align: center;
      padding-top: 6px;
      font-size: 25px; }
    .single_casino__single_item_details__pros {
      margin-bottom: 20px; }
      .single_casino__single_item_details__pros i {
        background: #a2d532; }
    .single_casino__single_item_details__cons {
      margin-bottom: 20px; }
      .single_casino__single_item_details__cons i {
        background: #ee4d4d; }
  .single_casino__single_item_inner {
    margin: 20px auto;
    box-shadow: 0 0 12px rgba(158, 158, 158, 0.55);
    padding: 45px 65px 20px;
    border-radius: 10px; }
  .single_casino__info {
    width: 195px;
    height: 135px;
    background: #f0eefc;
    border-radius: 10px;
    text-align: center;
    padding-top: 20px;
    margin-top: 15px; }
    .single_casino__info h3 {
      font-weight: 500;
      color: #8354D4;
      font-size: 20px; }
      .single_casino__info h3 strong {
        font-size: 30px; }
  .single_casino__text {
    width: 40%;
    margin-top: 20px;
    font-size: 20px;
    font-weight: 60;
    text-align: center; }
  .single_casino__single_item_terms {
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    margin-top: 1em; }
  .single_casino__single_item_details_features {
    flex: 1 1 50%;
    margin: 50px auto; }
    .single_casino__single_item_details_features .nav-tab-wrapper {
      border-radius: 20px 20px 0 0; }
      @media (max-width: 800px) {
        .single_casino__single_item_details_features .nav-tab-wrapper {
          white-space: nowrap;
          overflow: auto; } }
    .single_casino__single_item_details_features .nav-tab.nav-tab-active {
      border: none;
      background-color: #8354D4;
      color: #f7f7f7;
      border-radius: 20px 20px 0 0; }
      @media (max-width: 800px) {
        .single_casino__single_item_details_features .nav-tab.nav-tab-active {
          border-radius: 12px 12px 0 0; } }
    .single_casino__single_item_details_features .nav-tab {
      font-size: 20px;
      font-weight: 600;
      padding: 0;
      color: #292929;
      display: inline-block;
      height: 60px;
      padding: 16px 40px; }
      @media (max-width: 800px) {
        .single_casino__single_item_details_features .nav-tab {
          padding: 8px;
          font-size: 14px;
          font-weight: 600;
          height: auto; } }
    .single_casino__single_item_details_features .tab-target {
      padding: 25px 50px;
      min-height: 340px;
      border-radius: 0 20px 20px 20px;
      box-shadow: 5px 8px 15px rgba(196, 193, 193, 0.8); }
  .single_casino__single_item_bonus {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 50px 53px;
    row-gap: 40px;
    column-gap: 135px; }
    @media (max-width: 800px) {
      .single_casino__single_item_bonus {
        grid-template-columns: 100%;
        padding: 50px 0;
        column-gap: 0; } }
    .single_casino__single_item_bonus_details {
      display: flex;
      align-items: center; }
      @media (max-width: 800px) {
        .single_casino__single_item_bonus_details {
          justify-content: center; } }
      .single_casino__single_item_bonus_details img {
        width: 57px;
        height: 57px;
        margin-right: 33px; }
    .single_casino__single_item_bonus_title_wrapper {
      flex-grow: 1;
      display: flex;
      justify-content: space-between;
      min-height: 57px;
      align-items: center;
      border-bottom: 1px solid rgba(134, 76, 215, 0.12);
      text-align: center; }
      @media (max-width: 800px) {
        .single_casino__single_item_bonus_title_wrapper {
          flex-grow: 0;
          width: 150px;
          flex-direction: column;
          margin-top: 1.5px;
          border-bottom: 1px solid transparent; } }
    .single_casino__single_item_bonus_title {
      font-family: Inter;
      font-size: 20px;
      font-weight: 500;
      line-height: 31px; }
      @media (max-width: 800px) {
        .single_casino__single_item_bonus_title {
          font-size: 16px;
          font-weight: 600; } }
      .single_casino__single_item_bonus_title::first-letter {
        text-transform: uppercase; }
    .single_casino__single_item_bonus_value {
      font-family: Inter;
      font-size: 25px;
      font-weight: 700;
      color: #8354D4; }
      @media (max-width: 800px) {
        .single_casino__single_item_bonus_value {
          font-size: 16px;
          font-weight: 600; } }
  .bonuses_list .single_casino__single_item_details_total {
    display: block; }
  .single_casino__single_item_details_total {
    display: grid;
    grid-template-columns: 40% 60%;
    width: 100%; }
    @media (max-width: 800px) {
      .single_casino__single_item_details_total {
        grid-template-columns: 100%; } }
    .single_casino__single_item_details_total li {
      list-style: none;
      line-height: 36px;
      font-size: 12px; }
    .single_casino__single_item_details_total_number {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center; }
      .single_casino__single_item_details_total_number p {
        font-family: Inter;
        font-size: 85px;
        font-weight: 500;
        line-height: 122px;
        justify-content: center;
        color: #8354D4; }
        @media (max-width: 800px) {
          .single_casino__single_item_details_total_number p {
            font-size: 40px;
            font-weight: 600;
            line-height: 50px; } }
      .single_casino__single_item_details_total_number span {
        font-family: Inter;
        font-size: 30px;
        font-weight: 500;
        color: #292929; }
        @media (max-width: 800px) {
          .single_casino__single_item_details_total_number span {
            font-size: 20px;
            color: #18191f6e; } }
    .single_casino__single_item_details_total_content ul li {
      font-family: Inter;
      font-size: 20px;
      font-weight: 500;
      border-bottom: 1px solid #c4b9ef;
      width: 370px;
      display: flex;
      justify-content: space-between;
      padding: 10px 0; }
      .single_casino__single_item_details_total_content ul li strong {
        font-family: Inter;
        font-size: 25px;
        font-weight: 700;
        color: #8354D4; }
      .single_casino__single_item_details_total_content ul li:last-child {
        border: none; }
    .single_casino__single_item_details_total_content, .single_casino__single_item_details_total_logos, .single_casino__single_item_details_total_icon_list {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      gap: 20px;
      align-content: center; }
      .single_casino__single_item_details_total_content div, .single_casino__single_item_details_total_logos div, .single_casino__single_item_details_total_icon_list div {
        display: flex;
        flex-direction: column;
        align-items: center; }
  .single_casino__our_verdict {
    padding: 30px 0 50px; }
    @media (max-width: 800px) {
      .single_casino__our_verdict_title {
        font-size: 20px !important;
        text-align: left !important; }
      .single_casino__our_verdict_container {
        padding: 20px 25px !important; }
        .single_casino__our_verdict_container p {
          font-size: 16px !important; } }
    .single_casino__our_verdict_title {
      font-family: Inter;
      font-size: 30px;
      font-weight: 700;
      text-align: center;
      margin-bottom: 20px; }
    .single_casino__our_verdict_container {
      padding: 25px 50px;
      min-height: 340px;
      border-radius: 20px;
      box-shadow: 5px 8px 15px rgba(196, 193, 193, 0.8); }
      .single_casino__our_verdict_container p {
        font-family: Inter;
        font-size: 18px;
        font-weight: 400;
        text-align: left;
        margin-bottom: 15px; }
      .single_casino__our_verdict_container a {
        color: #864cd7;
        text-decoration: underline; }
      .single_casino__our_verdict_container h2 {
        font-family: Inter;
        font-size: 22px;
        font-weight: 700;
        line-height: 27px;
        letter-spacing: 0em;
        text-align: left;
        margin: 20px 0; }
        @media (max-width: 800px) {
          .single_casino__our_verdict_container h2 {
            font-size: 20px;
            line-height: 32px; } }
    .single_casino__our_verdict_score_group {
      padding: 30px 0 50px;
      display: grid;
      grid-template-columns: 33% 66%; }
      @media (max-width: 800px) {
        .single_casino__our_verdict_score_group {
          grid-template-columns: 100%;
          justify-content: center; } }
      .single_casino__our_verdict_score_group h4 {
        font-family: Inter;
        font-size: 22px;
        font-weight: 600; }
      .single_casino__our_verdict_score_group .scoreCircle {
        width: 250px;
        margin: 0 auto 10px; }
        .single_casino__our_verdict_score_group .scoreCircle svg {
          display: block;
          margin: initial;
          width: initial;
          height: initial; }
          .single_casino__our_verdict_score_group .scoreCircle svg text {
            font-weight: 700;
            text-anchor: middle;
            fill: #292929; }
            .single_casino__our_verdict_score_group .scoreCircle svg text.heavy {
              font-weight: 600;
              font-size: 45px; }
          .single_casino__our_verdict_score_group .scoreCircle svg circle {
            transition: stroke-dashoffset 2s cubic-bezier(0.16, 0.79, 0.46, 1.03);
            fill: white;
            transform: translate(100px, 100px) rotate(-89.9deg); }
      .single_casino__our_verdict_score_group .arc-background {
        stroke-width: 3px;
        stroke: rgba(0, 0, 0, 0.05); }
      .single_casino__our_verdict_score_group .arc {
        stroke-width: 10px;
        stroke: #fcc13e; }
      @media (max-width: 800px) {
        .single_casino__our_verdict_score_group_categories {
          margin-top: 38px; } }
      @media (max-width: 800px) {
        .single_casino__our_verdict_score_group_categories h4 {
          display: none; } }
      .single_casino__our_verdict_score_group_lines:last-child {
        margin-bottom: 0; }
      @media (max-width: 800px) {
        .single_casino__our_verdict_score_group_lines {
          margin-bottom: 24px; } }
    .single_casino__our_verdict_score_details a {
      color: #864cd7;
      text-decoration: underline; }
    .single_casino__our_verdict_score_details .archive_casinos__text {
      font-family: Inter;
      font-size: 20px;
      font-weight: 600; }
    .single_casino__our_verdict_score_details_item_title {
      font-family: Inter;
      font-size: 25px;
      font-weight: 700;
      text-align: left;
      padding: 40px 0 15px; }
    .single_casino__our_verdict_score_details_banner {
      padding: 40px 0 60px; }
      .single_casino__our_verdict_score_details_banner .archive_casinos__single_item_head {
        align-items: center; }

@media (max-width: 800px) {
  .archive_casinos__single_item_head {
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    text-align: center; } }

@media (max-width: 800px) {
  .archive_casinos__info {
    margin: 10px auto 0; } }

@media (max-width: 800px) {
  .archive_casinos__text {
    margin: 0 auto; } }

.list_of_reviews {
  margin: 50px 0; }
  .list_of_reviews__container_title {
    font-family: Inter;
    font-size: 30px;
    font-weight: 700;
    text-align: center; }
  .list_of_reviews__container_search svg {
    top: 63px;
    left: 20px;
    position: relative; }
  .list_of_reviews__container_search input {
    margin: 20px 0;
    font-size: 16px;
    font-weight: 400;
    width: 100%;
    border-radius: 30px;
    border: none;
    box-shadow: 0 2px 9px rgba(158, 158, 158, 0.25);
    padding: 18px 20px 18px 60px; }
    .list_of_reviews__container_search input::placeholder {
      color: #a8a8a8; }
  .list_of_reviews__container_feed {
    margin: 20px 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 50px;
    row-gap: 20px; }
    @media (max-width: 800px) {
      .list_of_reviews__container_feed {
        grid-template-columns: 100%; } }
    .list_of_reviews__container_feed_item {
      display: none;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      height: 75px;
      border-radius: 40px;
      border: none;
      box-shadow: 0 2px 9px rgba(158, 158, 158, 0.25);
      padding: 0 20px;
      column-gap: 15px; }
      .list_of_reviews__container_feed_item.active {
        display: flex; }
      .list_of_reviews__container_feed_item_img {
        max-height: 40px; }
      .list_of_reviews__container_feed_item_title {
        flex-grow: 2; }
      .list_of_reviews__container_feed_item_engage {
        background: #FCC13E;
        display: block;
        width: 34px;
        height: 34px;
        text-align: center;
        padding: 9px;
        border-radius: 20px; }
  .list_of_reviews__container_pagination {
    margin: 50px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 25px; }
    div .list_of_reviews__container_pagination_page_dot {
      background: #8354D4;
      cursor: default;
      box-shadow: 0 2px 8px rgba(158, 158, 158, 0.4);
      display: block;
      width: 13px;
      height: 13px;
      border-radius: 7px; }
    .list_of_reviews__container_pagination_page_dot.clickable {
      cursor: pointer;
      background: #FCC13E; }
    .list_of_reviews__container_pagination_right {
      transform: scale(-1, 1); }
    .list_of_reviews__container_pagination_right, .list_of_reviews__container_pagination_left {
      cursor: pointer;
      background: #8354D4;
      display: block;
      width: 30px;
      height: 30px;
      text-align: center;
      padding: 8px;
      border-radius: 20px; }

.rotate_faq__single_icon {
  transform: rotate(270deg);
  transition: all ease 0.5s; }
