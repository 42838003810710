@import '../vars';
@import '../reset';
@import '../breakpoints';


.author_page {
  margin: 40px auto;
  padding-left: 50px;
  @include media('<=800px') {
    margin: 10px auto;
    padding: 0 10px;
    flex-direction: column;
    align-items: center;
  }
  & .author_nav-tab-wrapper{
    padding-bottom: 40px;
    text-align: center;
    @include media('<=800px') {
      overflow-x: auto;
      padding: 25px 0;
    }
    & .nav-tab{
      padding: 5px 30px;
      margin-left: 20px;
    }
    & .nav-tab-active{
      border: none;
      background-color: $accent-color;
      color: white;
      padding: 7px 30px;
      border-radius: 20px;
    }
  }
  &.interesting{
    padding: 0;
  }
  &.interesting .site_launches__news_feed{
    align-self: center;
    justify-content: center;
    &_info_text .post-card-image-link p{
      display: block;
    }
  }
  &.interesting .site_launches__news_feed_single_item{
    max-width: 350px;
    & div.site_launches__news_feed_image{
      min-height: 198px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      & img{
        max-height: 198px;
      }
    }
  }
  &.interesting .site_launches__news_feed_single_item:nth-child(2) .site_launches__news_feed_info a p{
    display: block;
  }
  #site-main .post-feed &.interesting .site_launches__news_feed_info_tag {
    display: block;
  }
  &.interesting .site_launches__news_feed_info_tag {
    display: block;
    @include media('<=800px') {
      flex-direction: row;
      flex-wrap: wrap;
      & span{
        font-size: 16px!important;
      }
    }
  }
  & .site_launches__news_feed_single_item:nth-child(5) div {
    margin-right: 15px;
  }
  &.interesting .site_launches__news_feed_single_item div.site_launches__news_feed_single_item_inner {
    height: 420px;
    @include media('<=800px') {
      height: auto;
      flex-direction: column;
      padding: 10px;
    }
  }
  &__icon {
    float: left;
    margin: 0 40px 0 20px;
    @include media('<=800px') {
      margin: 0 15px;
    }
  }
  &__icon img {
    width: 200px;
    height: 200px;
    border-radius: 100px;
    object-fit: cover;
    border: 4px solid $tetiary-color;
    box-shadow: #0000003f 3px 3px 20px 0;
    @include media('<=800px') {
      width: 90px;
      height: 90px;
    }
  }
  &__text {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-left: 35px;
    &_social {
      margin-left: 30px;
      @include media('<=800px') {
        margin-left: 0;
      }
    }
    &_name {
      font-family: Inter;
      font-size: 40px;
      font-weight: 700;
      line-height: 53px;
      letter-spacing: 0px;
      text-align: left;
      display: flex;
      margin: 20px 0;
      @include media('<=800px') {
        flex-direction: column;
        font-size: 31px;
      }
    }
    &_description {
      font-family: Inter;
      font-size: 18px;
      font-weight: 400;
      line-height: 30px;
      letter-spacing: 0px;
      text-align: left;
      margin: 20px 0;
      min-height: 120px;
      @include media('<=800px') {
        font-size: 16px;
      }
    }
  }
}