@import '../vars';
@import '../reset';
@import '../breakpoints';

.casino_categories{
    overflow: hidden;
    &__banner{
        background-color: $primary-background-color;
    }
    &__games_banner_container {
        display: grid;
        grid-template-columns: 40% 60%;

        @include media ('<=800px'){
            grid-template-columns: 100%;
            justify-content: center;
        }
    }
    &__games_banner_right{
        margin-bottom: -60px;

        @include media ('<=800px'){
            display: none;
        }
        img{
            width: 100%;
        }
    }
    &__games_banner_right.mobile{
        display: none;

        @include media ('<=800px'){
            display: block;
            margin-bottom: 0;
        }
        img{
            width: 100%;
        }
    }
    &__games_banner_left{
        @include media ('<=800px'){
            padding: 10px 15px 0;
        }

    }
    &__banner_container{
        display: grid;
        grid-template-columns: 30% 60%;
        justify-content: flex-start;
        padding: 50px 0;

        @include media ('<=970px'){
            grid-template-columns: 30% 70%;
            padding: 50px 20px;
        }

        @include media ('<=800px'){
            grid-template-columns: 100%;

            .casino_categories__banner_info{
                order: 0;
            }

            .casino_categories__image{
                order: 1;
                padding-top: 20px;
            }
        }
    }
    &__image{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &__banner_info{
        @include media ('<=900px'){
            padding: 0 20px;
        }
    }
    &__info_header{
        font-size: 34px;
        line-height: 40px;
        padding-top: 10px;

        @include media ('<=900px'){
            font-size: 28px;
        }
    }
    &__info_subheader{
        font-size: 22px;
        padding-bottom: 10px;
        line-height: 30px;

        @include media ('<=900px'){
            font-size: 18px;
        }
    }
    &__info_paragraph{
        font-size: 16px;
        line-height: 1.5em;
        color: $paragraph-text-color;
        & a{
            font-weight: bold;
            color: $primary-background-color;
            &:hover{
                text-decoration: underline;
                color: $accent-color;
                transition: all ease 0.5s;
            }
        }

        @include media ('<=900px'){
            font-size: 14px;
        }
    }
    &__banner_btn{
        position: relative;
        padding: 20px 0 10px;

        @include media ('<=500px'){
            display: grid;
            grid-gap: 10px 0;
        }

        a{
            padding: 8px 20px;
            background-color: $accent-color;
            font-size: 14px;
            color: $tetiary-color;
            border-radius: 20px;
            margin-right: 10px;
            transition: all ease 0.5s;

            @include media ('<=tablet'){
                font-size: 12px;
            }

            @include media ('<=500px'){
                margin: 0 auto;
            }
        }

        a:hover{
            filter: saturate(2);
            transition: all ease 0.5s;
        }
    }

    // Styling for the casinos section
    &__casinos{
        padding: 30px 0;
    }
    &__casinos_box{
        max-width: 1000px;
        margin: 0 auto;
        padding: 0 20px;
    }
    &__single_item{
        max-width: 900px;
        margin: 30px auto;
        display: grid;
        grid-template-columns: 17% 63% 17%;
        border-radius: 20px;
        box-shadow: 0 0 7px rgb(187, 187, 187);
        font-family: 'Montserrat', sans-serif;

        @include media ('<=tablet'){
            grid-template-columns: 100%;
        }
    }
    &__single_item_thumb{
        position: relative;
        

        @include media ('<=tablet'){
            max-width: 300px;
            margin: 29px auto 0;
        }
        span{
            position: absolute;
            top: -10px;
            left: 10px;
            color: $tetiary-color;
            padding: 5px;
            border-radius: 10px;
        }
        img{
            width: 100%;
            height: 100%;
            border-top-left-radius: 20px;
            border-bottom-left-radius: 20px;

            @include media ('<=tablet'){
                border-radius: 20px;
            }
        }
    }
    &__single_top{
        display: grid;
        grid-template-columns: 30% 60%;
        padding: 10px 0;
        font-family: 'Montserrat', sans-serif;
        @include media ('<=tablet'){
            display: grid;
            grid-template-columns: 100%;
            padding: 20px 20px 10px;
            text-align: center;
        }
    }
    &__single_item_title_rating{
        display: grid;
        justify-content: center;
        align-items: center;
        align-content: center;
        text-align: center;
        padding: 0 2px;
        h3{
            font-family: "Gilroy-Bold", sans-serif;
            padding:0 5px;
        }
    }
    &__all_stars{
        border: 1px solid rgb(201, 201, 201);
        margin: 3px 10px;
        width: 140px;
        border-radius: 20px;
        padding: 3px 0 6px;
    }
    &__all_stars + a{
        font-size: 10px;
        padding-top: 4px;
        color: grey;
    }
    &__all_stars + a:hover{
        color: $accent-color;
        text-decoration: underline;
    }
    &__single_item_title_bonus_descr{
        p:first-child{
            display: block;
            font-weight: 700;
            font-size: 18px;
            padding-bottom: 3px;
        }

        @include media ('<=tablet'){
            padding-top: 10px;
        }
    }
    &__bonus_text_review{
        font-size: 12px;
        font-weight: 600;
        padding: 20px 0;
    }
    &__bonus_text_review + p{
        font-size: 13px;
        padding-top: 2px;
        font-weight: 500;
    }
    &__single_item_review_introduction + p span{
        font-size: 12px;
        font-weight: 500;
    }
    &__single_bottom{
        p{
            color: $background-text-color;
            font-size: 12px;
        }
    }

    &__single_item_terms_condition{
        padding: 0 0 10px 20px;
    }
    &__single_item_btns{
        display: grid;
        justify-content: center;
        align-items: center;
        align-content: center;
        grid-gap: 10px 0;

        @include media ('<=tablet'){
            padding: 20px 0;
        }
        a{
            display: block;
            color: $tetiary-color;
            padding: 8px 15px;
            display: flex;
            align-content: center;
            justify-content: center;
            align-items: center;
            border-radius: 20px;
            font-size: 11px;
            svg{
                margin-right: 5px;
            }
        }
        a:hover{
            filter: saturate(2);
        }
        a:first-child{
            background-color: $accent-color;
            svg{
                transform: rotate(270deg);
            }
        }
        a:nth-child(2){
            background-color: $primary-background-color;
        }
    }

    // The content section
    &__content_box{
        line-height: 25px;
        max-width: 900px;
        margin: 100px auto 0;
        & a{
            font-weight: bold;
            color: $primary-background-color;
            &:hover{
                text-decoration: underline;
                color: $secondary-background-color;
                transition: all ease 0.5s;
            }
        }

        @include media ('<=desktop'){
            padding: 30px 20px;
            margin: 0 auto;
        }
    }
    &__content_title{
        text-align: left;
        padding: 50px 0 10px;
    }
    &__author_date_time{
        display: flex;

        p b{
            color: $accent-color;
        }

        p:first-child{
            padding-right: 20px;
        }
    }

    &__heading_top{
        background-image: url('../../img/miscellaneous/casino-reviews-content-background.png');
        background-size: cover;
        background-repeat: no-repeat;
        display: flex;
        justify-content: center;
        background-color: $secondary-background-color;
        position: relative;
        margin: 100px 0 20px;

        @include media ('<=900px'){
            margin: 40px 0 20px;
        }
    }
    &__heading_box{
        max-width: 1000px;
        width: 100%;
        position: relative;
        margin: 0 auto;
        display: grid;
        grid-template-columns: 50% 50%;

        @include media ('<=570px'){
            grid-template-columns: 100%;
        }

    }
    &__heading_image{
        position: relative;
        margin-right: 20px;
        @include media ('<=580px'){
            display: none;
        }

        img{
            position: absolute;
            left: 0;
            bottom: 0;
            top: -55%;
            height: 260%;
            width: 100%;
            object-fit: contain;

            @include media ('<=900px'){
                top: -25%;
                position: absolute;
            }
            @include media ('<=800px'){
                top: -15%;
            }
            @include media ('<=800px'){
                top: -5%;
            }
        }
    }
    &__heading_contents_top{
        position: relative;
        padding: 30px 0;
        width: 100%;

        @include media ('<=570px'){
            padding: 30px 20px;
        }

        h4{
            font-size: 19px;
        }
        p{
            color: $primary-background-color;
            padding: 5px 0;
            
            span{
                font-size: 14px;
                background-color: $tetiary-color;
                border-radius: 50%;
                font-weight: 700;
                padding: 1px 5px 1px 6px;
            }
        }
        a{
            color: $primary-background-color;
        }
        a:hover{
            color: $accent-color;
        }
    }

    &__content_body{
        padding: 60px 0 40px;
        p{
            span{
                line-height: 30px;

                a{
                    text-decoration: underline;
                    color: red;
                }
            }
        }

        div{
            padding-top: 20px;

            p{
                span{
                    line-height: 30px;

                    a{
                        color: red;
                        text-decoration: underline;
                    }
                }
            }
        }
        @include media ('<=tablet'){
            padding: 20px 0;
            & li {
                margin-left: 15px;
            }
        }
    }
    &__content_body_title{
        font-size: 24px;
        line-height: 30px;
        padding: 20px 0 10px;
    }
    &__content_body_intro_text{
        font-size: 16px;
        
        @include media ('<=tablet'){
            font-size: 14px;
        }
    }
    &__content_body_paragraph{
        line-height: 1.5em;
        font-size: 16px;
    }
}