@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@300;500;600;800&display=swap");
@import url("https://fonts.cdnfonts.com/css/gilroy-bold");
h1, h2 {
  font-family: 'Inter', sans-serif;
  font-weight: 700; }

h3, h4 {
  font-family: 'Inter', sans-serif;
  font-weight: 400; }

p,
a,
button,
input,
select,
option,
ul,
li,
table,
td,
tr,
th,
tbody,
thead,
div,
header,
section,
aside,
figure,
label,
textarea,
::placeholder {
  font-family: 'Inter', sans-serif;
  font-weight: 400; }

span {
  font-family: 'Inter', sans-serif;
  font-weight: 700; }

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none; }

a {
  text-decoration: none; }

ul,
ol,
li {
  margin: 0;
  padding: 0; }

html {
  scroll-behavior: smooth; }

small {
  font-size: inherit; }

/**
*
* Testing
*
**/
.lotteries .single_casino__our_verdict {
  margin-top: 30px; }
  .lotteries .single_casino__our_verdict_title {
    font-family: Inter;
    font-size: 30px;
    font-weight: 700;
    line-height: 31px;
    text-align: center;
    margin: 35px auto; }
  .lotteries .single_casino__our_verdict_container {
    font-family: Inter;
    font-size: 18px;
    font-weight: 400;
    line-height: 29px;
    text-align: left; }
    .lotteries .single_casino__our_verdict_container p {
      font-family: Inter;
      font-size: 18px;
      font-weight: 400;
      line-height: 29px;
      text-align: left; }
    .lotteries .single_casino__our_verdict_container h3 {
      font-family: Inter;
      font-size: 25px;
      font-weight: 700;
      line-height: 31px;
      text-align: left;
      margin: 35px auto; }

.lotteries .about__banner_container {
  grid-template-columns: 60% 40%; }

.lotteries .filter_conntent_block {
  position: relative;
  max-width: 1100px;
  background: #fff;
  margin: 40px auto; }

.lotteries .expand .expandable {
  height: fit-content; }
  .lotteries .expand .expandable .archive_casinos__single_item_about {
    overflow: hidden; }

.lotteries .filter_conntent_block-sort select {
  min-width: unset; }

.lotteries .filter_conntent_block-sort-list {
  margin-right: 2rem; }

.lotteries .filter_conntent_block-form {
  display: flex;
  align-items: center;
  width: 50%; }
  .lotteries .filter_conntent_block-form svg {
    margin-right: -25px; }
  .lotteries .filter_conntent_block-form input {
    background: none;
    border-radius: 30px;
    font-size: 16px;
    font-weight: 600;
    color: #292929;
    border: solid 1px #D9D9D9;
    height: 31px;
    width: 100%;
    padding-left: 25px;
    padding-right: 150px; }
    .lotteries .filter_conntent_block-form input::placeholder {
      color: #D9D9D9; }
    .lotteries .filter_conntent_block-form input:focus {
      color: #292929; }
  .lotteries .filter_conntent_block-form-button {
    margin-left: -100px; }
    .lotteries .filter_conntent_block-form-button button {
      background-color: #8354D4;
      border: none;
      width: 150px;
      height: 30px;
      border-radius: 0 15px 15px 0;
      color: white;
      cursor: pointer; }

.lotteries .disclaimer div {
  padding: 10px 10px 30px 10px;
  border-bottom: 1px solid #f7f7f7; }

.lotteries .author-review {
  background: white; }
  .lotteries .author-review__foreword p:last-child {
    margin-bottom: 0; }
  .lotteries .author-review__foreword {
    margin-bottom: 0; }
  .lotteries .author-review__header-title, .lotteries .author-review__foreword,
  .lotteries .author-review__foreword p {
    color: #292929; }
  .lotteries .author-review__header-author-data > * {
    color: #292929; }
