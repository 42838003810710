// this scss stylesheet if for css rules that will be repeated
@import '../vars';
@import '../breakpoints';

.expandable {
  overflow: hidden;

  transition: all 0.5s ease-in-out;
  //transform-origin: left top;
  height: 0;
  //transform: scaleY(0);
}

.expand .expandable {
  display: flex;
  height: 435px;
  transition: all 0.5s ease-in-out;
  //transform-origin: left top;
  //transform: scaleY(1);
  @include media('<=800px') {
    height: auto;
  }
}
.breadcrumb.ghost {
  margin: 20px auto;
  padding-left: 0;
  @include media('<=1100px') {
    max-width: 90%;
  }
  & .breadcrumb-item {
    display: flex;
    position: relative;
    white-space: nowrap;

    &-current {
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
    }
  }
  & .breadcrumb-item > * {
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0px;
    text-align: left;
    color: #4b4b4b;
  }
  & .separator {
    color: $secondary-background-color;
    margin-left: 8px;
  }
}
.site-content .breadcrumb li + li::before {
  content: '';
  margin: 0 5px;
  opacity: 1;
}

// styling for the stars used in casino
.star-rating {
  padding-bottom: 5px;
}
.star-rating-text {
  font-size: 16px;
  font-weight: 700;
  color: $paragraph-text-color;
  padding-left: 10px;
  padding-top: 5px;
  & span {
    color: $background-text-color;
    font-weight: 400;
  }
}
.star-rating .star-full:before {
  content: '\f155';
}

.star-rating .star-half:before {
  content: '\f459';
}

.star-rating .star-empty:before {
  content: '\f154';
}

.star-rating .star {
  color: $secondary-background-color;
  display: inline-block;
  font-family: dashicons;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  height: 20px;
  text-align: center;
  text-decoration: inherit;
  vertical-align: top;
  width: 20px;
}
/*

.inner .kk-star-ratings.kksr-disabled .kksr-stars .kksr-stars-active .kksr-star .kksr-icon,
.inner .kk-star-ratings:not(.kksr-disabled) .kksr-stars:hover .kksr-star .kksr-icon{
    background-image: url("/wp-content/themes/gecko/img/selected.svg");
}
*/

/*  NEWS CATEGORY FEED */

.news-section-category {
  margin: 50px auto;
  & .inner {
    box-shadow: #0000003f 5px 5px 15px 0;
    border-radius: 20px;
    padding: 55px 50px 40px;
    max-width: 1110px;
  }
  &__headers {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 20px;
  }
  &__news_feed {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    grid-gap: 25px;
    &_single_item {
      text-align: left;
      &_inner {
        height: 100%;
      }
      &:first-child {
        grid-row: 1 / 3;
        grid-column: 1;
        & .news-section-category__news_feed_info {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          gap: 0 20px;
          &_date,
          &_name {
            order: unset;
          }
          &_text {
            flex: 0 1 100%;
            & h4 {
              font-size: 22px;
            }
          }
        }
        & .news-section-category__news_feed_image {
          height: 460px;
          min-width: 460px;
        }
      }
    }
    &_image {
      border-radius: 5px;
      height: 60%;
      background-size: cover;
      background-position: center;
    }
    &_info {
      display: flex;
      flex-direction: column;
      min-height: 110px;
      align-items: baseline;
      &_text h4 {
        font-size: 14px;
        font-weight: 600;
        text-decoration: none;
        color: #18191f;
        margin-top: 15px;
        text-align: left;
        &:hover {
          color: #737b7d;
        }
      }
      &_date,
      &_name {
        font-size: 12px;
        font-weight: 400;
        color: #18191f;
        margin-top: 10px;
      }
      &_name {
        & a {
          color: #737b7d;
          &:hover {
            text-decoration: underline;
          }
        }
        color: #737b7d;
      }
      & span:nth-child(2) {
        order: -1;
      }
    }
  }
}

/* END OF NEWS CATEGORY FEED */

/*   TABS STYLE  */
.nav-tab {
  color: $background-text-color;
  text-align: center;
  font-size: 20px;
  padding: 5px 20px;
  white-space: nowrap;
  line-height: 32px;
}
.nav-tab:hover {
  color: $accent-text-color;
}

.nav-tab.nav-tab-active {
  border-bottom: 4px solid $primary-background-color;
  color: $primary-background-color;
}

.tab-target {
  display: none;
  position: relative;
}

.tab-target.tab-target-active {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
/*   END TABS STYLE  */

.round_button {
  padding: 15px 35px;
  text-align: center;
  border-radius: 30px;
  color: $tetiary-color;
  background-color: $primary-background-color;
  cursor: pointer;
}

.inner {
  max-width: 1110px;
  margin: 0 auto;
}

// breadcrumb styling
.breadcrumbs {
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  p {
    a {
      color: $tetiary-color;
      font-family: Inter;
      font-size: 12px;
      font-weight: 400;
      text-decoration: none;
    }
  }
  span.last {
    color: $tetiary-color;
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
  }
  span.separator {
    color: $secondary-background-color;
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
  }
}

.section_title_repeat {
  text-align: center;
  font-size: 30px;
  font-weight: 800;
  line-height: 60px;

  @include media('<=desktop') {
    font-size: 30px;
  }
  @include media('<=tablet') {
    font-size: 25px;
    line-height: 30px;
  }
}

.section_paragraph_repeat {
  max-width: 700px;
  text-align: center;
  margin: 0 auto;
  line-height: 28px;
  font-weight: 500;
  font-size: 16px;
  @include media('<=desktop') {
    padding: 0 30px;
  }
}

.banner-title-repeat {
  font-size: 34px;
  line-height: 35px;
  padding-bottom: 10px;
}

.banner-paragraph-repeat {
  font-size: 16px;
  line-height: 24px;
}

// function to add padding to top and bottom of section
.section_padding_repeat {
  padding: 70px 0 50px;
}

// Styling for related casinos

.related_casinos {
  padding: 40px 0;

  &__container {
    max-width: 1000px;
    margin: 0 auto;

    @include media('<=desktop') {
      padding: 0 20px;
    }
  }

  &__box {
    display: grid;
    grid-template-columns: 30% 30% 30%;
    grid-gap: 20px;
    justify-content: flex-start;
    padding: 30px 0;

    @include media('<=850px') {
      grid-template-columns: 50% 50%;

      padding-right: 20px;
    }

    @include media('<=560px') {
      grid-template-columns: 70%;
      justify-content: center;
    }

    @include media('<=420px') {
      grid-template-columns: 100%;
      padding-right: 0;
    }
  }
  &__header {
    font-size: 30px;
    text-transform: capitalize;
    text-align: left;
  }

  &__single {
    background-color: $tetiary-color;
    padding: 20px;
    border-radius: 20px;
  }
  &__first_row {
    display: grid;
    grid-template-columns: 20% 80%;
  }
  &__thumb {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 80%;
      border-radius: 50%;
    }
  }
  &__preview_text {
    font-weight: 700;
    font-size: 15px;
    padding: 10px 0;
  }
  &__second_row {
    font-size: 12px;
    display: grid;
    grid-template-columns: 50% 50%;
    justify-content: center;
    grid-gap: 20px 10px;
    text-align: center;

    @include media('<=phone') {
      display: grid;
      grid-template-columns: 100%;
      grid-gap: 5px;
    }
    a:first-child {
      color: $tetiary-color;
      background: $primary-background-color;
      padding: 12px 14px;
      border-radius: 20px;
      line-height: 10px;
      transition: all ease 0.5s;
    }

    a:first-child:hover,
    a:nth-child(2):hover {
      opacity: 0.7;
      transition: all ease 0.5s;
    }
    a:nth-child(2) {
      color: $tetiary-color;
      background-color: $accent-color;
      padding: 12px 14px;
      border-radius: 20px;
      line-height: 10px;
      transition: all ease 0.5s;
    }
  }

  &__third_row {
    text-align: center;
    padding-top: 10px;
    a {
      font-size: 13px;
      text-decoration: underline;
      color: grey;
    }
    a:hover {
      color: $accent-color;
    }
  }
}

// Faq section styling
.faq {
  &__inner {
    max-width: 900px;
    margin: 0 auto;
    padding: 50px 0 70px;
    width: 100%;
    &_head {
      text-align: center;
      margin: 25px 0;
    }
    @include media('<=1100px') {
      padding: 50px 20px 70px;
    }
  }
  &__single {
    border-radius: 10px;
    background: #8045d30d;
    margin-bottom: 20px;
  }
  &__single_title.active {
    border-bottom: 1px solid rgb(235, 235, 235);
  }
  &__single_title {
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
    cursor: pointer;
    @include media('<=tablet') {
      font-size: 17px;
    }
  }
  &__single_title.active {
    text-decoration: none;
  }
  &__single_head {
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    font-weight: 500;
    display: flex;

    &::before {
      @include media('<=800px') {
        margin: 0 10px !important;
      }
    }

    @include media('<=tablet') {
      font-size: 17px;
    }
  }
  &__single_body {
    display: none;
    padding: 10px 0 30px;
    line-height: 30px;
    font-size: 16px;
    font-family: 'Inter', sans-serif;
    margin-left: 32px;
    margin-right: 20px;
    a {
      font-weight: bold;
      color: $primary-background-color;
      text-decoration: underline;

      &:hover {
        text-decoration: underline;
        color: $secondary-background-color;
        transition: all ease 0.5s;
      }
    }
  }
  &__single_head {
    &:before {
      content: '\f055';
      font-family: 'Font Awesome 6 Free';
      font-weight: 900;
      color: $primary-background-color;
      font-size: 22px;
      margin: 0 15px 0 30px;
    }
    .active &:before {
      content: '\f056';
    }
  }
}

.show {
  display: block;
}

// Styling for the latest guides
.related-guides {
  &__container {
    max-width: 1100px;
    margin: 0 auto;
    padding: 90px 20px;
    border-top: 1px solid #eee;
  }
  &__heading {
    font-size: 30px;
    font-weight: 500;
    text-align: center;
    text-transform: uppercase;
  }
  &__guides_box {
    display: grid;
    max-width: inherit;
    grid-template-columns: repeat(3, 31%);
    grid-gap: 0 20px;
    margin: 0 auto;
    padding: 20px 0;
    justify-content: center;

    @include media('<=tablet') {
      grid-template-columns: 100%;
      grid-gap: 30px 0;
    }
  }
  &__single_item_img {
    img {
      width: 100%;
      filter: drop-shadow(2px 2px 4px #c5c5c5);
      border-radius: 10px;
      transition: all ease 0.4s;
    }
    img:hover {
      filter: drop-shadow(2px 2px 6px #949494);
      transition: all ease 0.4s;
    }
  }
  &__single_item_info {
    padding-top: 20px;

    span:first-child {
      display: block;
      font-weight: 600;
      font-size: 19px;
      padding-bottom: 3px;

      a {
        color: $primary-background-color;
        transition: all ease 0.4s;
      }
      a:hover {
        color: $secondary-background-color;
        text-decoration: underline;
        transition: all ease 0.4s;
      }
    }

    span:nth-child(2) {
      font-size: 15px;
    }
    span:nth-child(3) p {
      font-size: 14px;
      line-height: 25px;
      padding-top: 5px;
    }
  }
}

// Styling for the latest gambling guides
.latest_guides {
  padding: 90px 40px;
  line-height: 1.7em;
  &__box {
    display: grid;
    grid-gap: 20px 0;
  }
  &__box_guides {
    display: flex;
    margin: 0 auto;
    justify-content: center;
    padding: 30px 0 20px;

    @include media('<=tablet') {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 20px 0;
    }
    @include media('<=550px') {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  &__single {
    margin: 0 10px;
    max-width: 300px;
    background-color: $tetiary-color;
    border-radius: 20px;
    box-shadow: 0 0 8px rgb(196, 196, 196);
    transition: all ease 0.5s;
  }
  &__single:hover {
    box-shadow: 0 0 12px rgb(158, 158, 158);
    transition: all ease 0.5s;
  }
  &__img {
    width: 100%;

    img {
      width: 100%;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      box-shadow: 0 2px 2px #ececec;
    }
  }
  &__single_title {
    padding: 10px 20px 5px;
    color: $primary-background-color;
    text-transform: capitalize;
  }
  &__author_date {
    padding: 0 20px 10px;
    font-size: 15px;
    color: $primary-background-color;

    b {
      color: $accent-color;
    }
  }
  &__paragraph {
    padding: 0 20px 30px;
    font-size: 15px;
    color: $primary-background-color;
  }
  &__box_btn {
    text-align: center;

    a {
      background-color: $accent-color;
      color: $tetiary-color;
      padding: 7px 16px;
      border-radius: 20px;
      font-size: 13px;
      text-transform: uppercase;
      transition: all ease 0.5s;
    }
    a:hover {
      filter: saturate(2);
      transition: all ease 0.5s;
    }
  }
}

// Styling the Subscribe Form
.subscribe-form {
  background-color: $secondary-background-color;
  display: grid;
  justify-content: center;
  align-items: center;

  &__inner {
    max-width: 800px;
    padding: 60px 0;
    margin: 0;
    text-align: center;
    .wpcf7-not-valid-tip {
      padding-top: 10px;
      color: $accent-color;
    }

    .wpcf7 form.invalid .wpcf7-response-output {
      margin-top: -15px;
      color: $accent-color;
      border: 1px solid $accent-color;
      font-weight: 500;
    }
  }
  &__title {
    font-size: 26px;
    line-height: 35px;

    @include media('<=tablet') {
      padding: 0 20px;
      font-size: 20px;
    }
  }
  &__paragraph {
    font-size: 15px;
    line-height: 30px;

    @include media('<=tablet') {
      padding: 0 20px;
      font-size: 14px;
      line-height: 20px;
    }
  }
  &__form {
    max-width: 390px;
    overflow-x: hidden;
    margin: 0 auto;
  }
  & .es-field-wrap {
    max-width: 500px;
    margin: 1em auto 0;

    @include media('<=tablet') {
      padding: 0 40px;
    }

    input[type='email'] {
      color: $primary-background-color;
      font-size: 16px;
      width: 100%;
    }
    input[type='email']::placeholder {
      color: grey;
    }
    input {
      padding: 12px 20px;
      border: none;
      border-radius: 20px;

      &::placeholder {
        color: grey;
      }

      @include media('<=420px') {
        width: 100%;
      }
    }
  }
  & input.es_submit_button {
    padding: 9px 18px;
    border: none;
    background-color: $accent-color;
    color: $tetiary-color;
    border-radius: 20px;
    text-transform: uppercase;
    margin-top: 10px;
    font-size: 14px;
    cursor: pointer;
  }
  input.es_submit_button:hover {
    filter: saturate(2);
  }
  .wpcf7-response-output {
    margin-top: -20px;
    display: none;
  }
}

// Casino Reviews
.casinos_block_green {
  background-color: green;
}
.casinos_block_red {
  background-color: red;
}

// styling for the scroll to top button
.scroll-to-top-btn {
  position: fixed;
  bottom: 75px;
  right: 10px;
  width: 45px;
  height: 45px;
  margin: 0 20px 20px 0;
  z-index: 100;
  svg {
    width: 100%;
    height: 100%;
    cursor: pointer;
    filter: drop-shadow(0px 0px 8px rgb(255, 255, 255));

    @media screen and (max-width: 567px) {
      width: 80%;
      height: 80%;
    }
  }

  .scroll-to-top-btn-show {
    opacity: 1;
    visibility: visible;
  }
}
.scroll-to-top-btn.scroll-to-top-btn-show {
  opacity: 1;
  visibility: visible;
}
//   anchor menu styling
.anchor {
  &__menu {
    background-color: #fff;
    box-shadow: 0px 12px 14px rgba(196, 193, 193, 0.4);
    transition: all ease 1s;
    margin: 0 auto;
    @include media('<=480px') {
      width: 95%;
    }
  }

  &__menu_container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 15px 0;

    @include media('<=700px') {
      flex-wrap: nowrap;
      text-align: center;
      overflow-x: auto;
      justify-content: flex-start;
      margin: 0 auto;
    }
    @include media('<=480px') {
      div & li a {
        font-family: Inter;
        font-size: 14px;
        font-weight: 700;
        line-height: 19px;
        padding: 7px 20px;
        color: #4b4b4b;
      }
    }

    li {
      flex: auto;
      color: $background-text-color;
      text-align: center;
      list-style-type: none;
      border-left: 1px solid #d2d2d2;
      padding: 5px 0;
      &:first-child {
        border: none;
      }
      a {
        color: $background-text-color;
        font-weight: 500;
        font-size: 14px;
        text-decoration: none;
        @include media('<=700px') {
          font-size: 11px;
        }
      }

      @include media('<=700px') {
        flex: none;
        font-size: 11px;
        text-align: center;
        padding: 5px 14px;
      }
    }

    a:last-child {
      border-right: none;
    }

    a:hover {
      color: $accent-color;
    }
  }
}

.home .anchor__menu.anchor-menu-fixed,
.anchor__menu.anchor-menu-fixed {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  transition: all ease 1s;
  max-width: unset;
  border-radius: 0;
}

.filter_conntent_block {
  &.games {
    position: relative;
    top: -60px;
    max-width: 1100px;
    margin: 0 auto;
    background: white;
    padding-bottom: 20px;

    @include media('<=800px') {
      top: 10px;
    }
  }
  border-radius: 15px;
  box-shadow: 0px 5px 19px rgba(0, 0, 0, 0.17);
  margin-bottom: 54px;
  padding: 28px 40px;
  &-filters-providers,
  &-filters-title,
  &-filters-game_type,
  &-sort-title {
    margin-right: 1rem;

    @include media('<=800px') {
      width: 100%;
      text-align: center;
      margin-bottom: 10px;
    }
  }
  &-filters-title,
  &-sort-title {
    font-weight: 600;
    font-size: 18px;
    color: #525252;
  }
  &-body {
    display: flex;
    justify-content: space-between;

    @include media('<=800px') {
      flex-direction: column;
      gap: 20px;
    }
  }
  &-sort,
  &-filters {
    display: flex;
    align-items: center;

    @include media('<=800px') {
      flex-direction: column;
    }
  }
  &-sort-list {
    @include media('<=800px') {
      width: 100%;
      margin-right: 0 !important;

      select {
        width: 100%;
        margin: 0 auto;
      }
    }
  }
  &-clear {
    margin-left: 3rem;
    &-button {
      font-weight: normal;
      font-size: 14px;
      color: #864cd7;
      display: block;
      padding: 5px;
      cursor: pointer;
    }
  }
  & select {
    background: white;
    border: 1px solid #f1f1f1;
    border-radius: 15px;
    padding: 6px 12px;
    min-width: 150px;

    @include media('<=800px') {
      width: 100%;
    }
  }
  &-sort-order {
    margin-left: 1em;
    display: block;
    padding: 5px 10px;
    cursor: pointer;
  }
  &-form {
    @include media('<=800px') {
      width: 100% !important;
    }
  }
}

.target-to-load {
  padding-top: 30px;
}

// Styling for the 404 page
.error-509,
.error-404 {
  background-color: $primary-background-color;
  &__container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 50px 20px;
  }
  &__info {
    & h1 {
      font-size: 64px;
      color: $tetiary-color;
      font-weight: 700;
    }
    & h2 {
      font-size: 58px;
      color: $secondary-background-color;
      font-weight: 700;
    }
    &_paragraph {
      margin-top: 10px;
      font-size: 30px;
      color: $tetiary-color;
      font-weight: 400;
      & a {
        color: $secondary-background-color;
        text-decoration: underline;
      }
    }
  }
  &__image img {
    width: 100%;
  }
}

.detail-icon {
  transition: all ease 0.5s;
}
.rotate_dropdown_icon {
  transform: rotate(180deg);
}

.clearfix {
  content: '';
  display: table;
  clear: both;
}

.clearfix:before,
.clearfix:after {
  content: '';
  display: table;
  clear: both;
}

.guidess {
  &__slider {
    height: 600px;

    @include media('<=500px') {
      height: 550px;
      // overflow-x: hidden;
    }
  }
  &__slider_title_heading {
    padding-top: 50px;
    margin: 0 auto;
  }
  &__slider_container {
    display: grid;
    justify-content: center;
    width: 700px;
    margin: 0 auto;
    position: relative;

    @include media('<=tablet') {
      max-width: 500px;
      left: 0;
    }

    @include media('<=500px') {
      width: 400px;
      left: -10px;
    }
  }
  &__slider_single_box {
    background-color: lightgreen;
    width: 700px;
    margin: 0 auto;
    position: relative;

    @include media('<=tablet') {
      width: 500px;
      left: 20px;
    }

    @include media('<=500px') {
      width: 400px;
      margin-right: 20px;
    }
  }
  &__slider_single_item {
    margin-top: 80px;
    display: none;
    position: absolute;
    top: 30px;
    left: 0;
    margin: 0;
    background-color: #fff;
  }
  &__slider_single_item.active {
    display: block;
  }
  &__slider_image {
    width: 100%;

    img {
      filter: drop-shadow(3px 3px 3px rgb(216, 216, 216));
      width: 100%;
      height: 100%;

      @include media('<=tablet') {
        width: 100%;
      }
    }
  }
  &__slider_info {
    padding-top: 20px;

    h2 {
      color: $primary-background-color;
      padding-bottom: 10px;
    }

    p {
      color: $primary-background-color;
    }
  }

  &__slider_info:hover {
    filter: drop-shadow();
  }

  &__slider_info:hover h2,
  &__slider_info:hover p {
    color: $secondary-background-color;
  }
  &__sliders_btns {
    position: absolute;
    width: 100%;
    margin-top: 27%;
    padding: 0 20px;

    p:first-child {
      left: 8px;

      @include media('<=tablet') {
        left: 40px;
      }

      @include media('<=500px') {
        left: 20px;
      }
    }
    p:nth-child(2) {
      right: 5px;

      @include media('<=tablet') {
        right: 0;
      }

      @include media('<=500px') {
        right: 5px;
      }
    }
  }
  &__sliders_btns p {
    position: absolute;
    border-radius: 50px;
    font-size: 30px;
    font-weight: 900;
    cursor: pointer;
    color: $secondary-background-color;
  }
}

/* TODO Cleanup old sections new ones gose after ths */

.industry-updates {
  margin: 50px auto;
  & .inner {
    box-shadow: #0000003f 5px 5px 15px 0;
    border-radius: 20px;
    padding: 55px 50px 50px;
    max-width: 1110px;
  }
  &__headers {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 20px;
  }
  &__news_feed {
    display: flex;
    gap: 25px;
    &_single_item {
      width: 25%;
      &_inner {
        height: 220px;
      }
    }
    &_image {
      border-radius: 5px;
      height: 60%;
      background-size: cover;
      background-position: center;
    }
    &_info {
      display: flex;
      flex-direction: column;
      min-height: 110px;
      text-align: left;
      &_text h4 {
        font-size: 14px;
        font-weight: 600;
        text-decoration: none;
        color: #18191f;
        margin-top: 15px;
        &:hover {
          color: #737b7d;
        }
      }
      &_date,
      &_name {
        font-size: 12px;
        font-weight: 400;
        color: #18191f;
        margin-top: 10px;
      }
      &_name {
        & a {
          color: #737b7d;
          &:hover {
            text-decoration: underline;
          }
        }
        color: #737b7d;
      }
    }
  }
}

.categories-block {
  &__inner {
    max-width: 1110px;
    display: flex;
    flex-direction: row;
    gap: 0 40px;
    margin: 50px auto;
  }
  &__icons_inner {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    text-align: center;
    align-items: center;
    padding: 25px 20px 0;
    &_icon_image {
      border-radius: 15px;
      box-shadow: #0000003f 5px 5px 15px 0;
      height: 120px;
      width: 120px;
      margin: 10px auto;
      & img {
        margin-top: 15px;
      }
    }
    & p {
      font-size: 16px;
      font-weight: 600;
      color: black;
      padding-bottom: 20px;
    }
  }
  &__join,
  &__icons {
    padding: 55px 60px 50px;
    box-shadow: #0000003f 5px 5px 15px 0;
    flex: 50%;
    border-radius: 20px;
  }
  &__join {
    text-align: center;
    background-color: $primary-background-color;
    color: $tetiary-color;
    & h2 {
      margin: 50px 20px 25px;
      font-weight: 700;
      font-size: 32px;
      & span {
        color: $secondary-background-color;
      }
    }
    & p {
      margin-bottom: 35px;
      font-size: 20px;
    }
    & .es_subscription_message.success {
      color: $secondary-background-color;
    }
    & .emaillist form {
      display: flex;
      justify-content: center;
      & .es-field-wrap {
        width: 80%;
      }
      & .es_required_field {
        height: 76px;
        width: 100%;
        border-radius: 5px 0 0 5px;
        border: none;
        font-size: 18px;
        padding-left: 20px;
      }
      & .es_subscription_form_submit {
        font-family: 'Font Awesome 6 Free';
        font-weight: 900;
        width: 76px;
        height: 76px;
        color: $tetiary-color;
        background-color: $secondary-background-color;
        border-radius: 0 5px 5px 0;
        border: none;
        font-size: 30px;
        transition: all ease 0.5s;
        &:hover {
          padding-left: 10px;
          background-color: #ffcd5d;
          cursor: pointer;
          transition: all ease 0.2s;
        }
      }
    }
  }
}

.categories-block-row {
  &__inner {
    max-width: 1110px;
    display: flex;
    flex-direction: row;
    gap: 0 40px;
    margin: 50px auto;
    text-align: center;
  }
  &__title {
    margin: 90px auto 30px;
    & svg {
      margin-bottom: 20px;
    }
    & h3 {
      font-size: 30px;
      font-weight: 700;
      color: $paragraph-text-color;
      padding-bottom: 12px;
    }
  }
  &__icons_inner {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    text-align: center;
    align-items: baseline;
    padding: 25px 20px 0;
    gap: 20px;
    @include media('<=800px') {
      display: grid;
      grid-template-columns: repeat(2, 45%);
      gap: 30px 10px;
      justify-content: center;
    }

    @include media('<=500px') {
      grid-template-columns: 100%;
    }
    &_icon_image {
      border-radius: 30px;
      box-shadow: #0000003f 2px 2px 15px 0;
      height: 120px;
      width: 120px;
      margin-bottom: 13px;
      & img {
        margin-top: 15px;
      }

      @include media('<=800px') {
        display: flex;
        justify-content: center;
        margin: 0 auto;
      }
    }
    & strong {
      font-weight: 600;
      font-size: 18px;
      text-align: left;
    }
    & p {
      color: $paragraph-text-color;
      font-size: 16px;
      font-weight: 400;
      text-align: left;
      margin-top: 20px;

      @include media('<=800px') {
        text-align: center;
      }
    }
  }
}

.default {
  // styling the banner section
  &__banner {
    background: $primary-background-color;
    color: $tetiary-background-color;
  }

  &__heading {
    font-size: 42px;
    font-weight: 700;
    padding: 8px 0 0;
    z-index: 10;

    @include media('<=800px') {
      font-size: 22px;
    }

    @include media('<=500px') {
      width: 200px;
    }
  }

  &__subheading {
    font-size: 16px;
    font-weight: 600;
    color: $secondary-background-color;
    padding: 10px 0 20px;
    z-index: 10;
  }

  &__paragraph {
    font-weight: 400;
    font-size: 16px;
    padding-bottom: 30px;
    line-height: 30px;

    & a {
      color: #fcc13e;
      text-decoration: underline;
    }

    @include media('<=800px') {
      padding-top: 0;
    }
  }

  &__title {
    width: 50%;
  }

  &__img_title {
    //height: 335px;
  }

  &__img {
    position: absolute;
    z-index: 100;
    padding-left: 649px;
    top: 60px;
    & img {
      height: 342px;
    }
    &.games {
      padding-left: 596px;
      top: 87px;
    }
  }
  &__banner_container {
    display: grid;
    grid-template-columns: repeat(2, 48%);
    padding: 20px 20px 0;
    justify-content: space-between;

    @include media('<=800px') {
      grid-template-columns: 100%;
    }
  }

  &__banner_left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  &__banner_img {
    display: grid;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
      @include media('<=800px') {
        width: 100%;
      }
    }
  }
  &__shape {
    width: 100%;
    overflow: hidden;
  }

  &__shape-top {
    background-color: $primary-background-color;
    width: 100%;
    height: 50px;
    border-radius: 50% / 75%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border: 1px solid $primary-background-color;
    transform: scale(1.05, -1);
  }

  & .anchor__menu {
    max-width: none;
    border: none;
    border-radius: 0;
    z-index: 100;
    position: relative;
  }

  &__image {
    display: flex;
    flex-direction: column;
    align-items: center;

    & img {
      width: 160px;
      border-radius: 15px;
    }
  }
}

.anchor__menu {
  z-index: 100;
  position: relative;
}

.default_repeater {
  padding: 60px 0;
  text-align: center;
  margin-bottom: 100px;
  margin-top: 50px;
  &__title h3 {
    font-family: Inter;
    font-weight: 700;
    font-size: 25px;
  }
  &__options {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 70px;
    &_item {
      margin-top: 20px;
      & img {
        height: 110px;
      }
      &_title {
        text-transform: uppercase;
        font-weight: 600;
        font-size: 20px;
        margin: 15px;
      }
      &_text {
        font-weight: 400;
        font-size: 16px;
      }
    }
  }
}

.breadcrumb {
  display: flex;
  list-style: none;
  padding: 0;
}

.breadcrumb li + li::before {
  content: '/';
  margin: 0 0.5em;
  opacity: 0.5;
}

.breadcrumb-item-current {
  font-weight: 500;
}

.repeater {
  margin: 60px 30px;
  &__inner_head h2 {
    color: $tetiary-background-color;
    font-family: Inter;
    font-size: 30px;
    font-weight: 700;
    line-height: 56px;
  }

  &__single {
    display: flex;
    align-items: center;
    margin: 30px 0;

    @include media('<=800px') {
      display: grid;
    }
    &_head {
      color: $tetiary-background-color;
      font-family: Inter;
      font-size: 20px;
      font-weight: 700;
      display: flex;
      gap: 9px;
      & span {
        display: block;
        width: 28px;
        height: 28px;
        background: $secondary-background-color;
        color: $primary-background-color;
        border-radius: 15px;
        text-align: center;
      }
    }
    &_subtitle {
      color: $tetiary-background-color;
      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      padding: 15px 0;
    }
    &_description,
    &_description p {
      color: $tetiary-background-color;
      font-family: Inter;
      font-size: 16px;
      font-weight: 400;
      padding-top: 10px;
    }
  }

  &__single_icon {
    @include media('<=800px') {
      text-align: center;
    }
  }
}
.ad_disclosure {
  position: relative;
  text-align: end;
  top: 25px;
}

.category_ribbon {
  position: relative;
  height: 35px;
  display: block;
  border-radius: 0 10px 10px 0;
  padding: 8px 0 8px 16px;
  left: -25px;
  top: 37px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;

  @include media('<=800px') {
    left: -17px;
  }
  & svg {
    margin-right: 5px;
  }
  & div {
    overflow: hidden;
    width: 25px;
    position: relative;
    left: -16px;
    top: 7px;
    & div {
      display: block;
      width: 53px;
      height: 42px;
      top: -15px;
      left: 20px;
      background: #e6b34c;
      transform: skewX(60deg);
      margin: 0 0 0 -10px;
      position: relative;
    }
  }
  &.top_pick_ribbon {
    color: $primary-background-color;
    width: 130px;
    background-color: $secondary-background-color;
    & div {
      & div {
        background: #e6b34c;
      }
    }
  }
  &.best_of_best {
    color: $tetiary-background-color;
    width: 200px;
    background-color: $accent-color;
    & div {
      & div {
        background: $primary-background-color;
      }
    }
  }
}

.blue {
  background-color: $primary-background-color;
}

.three_col_feed {
  padding: 50px 10px 70px;
  @include media('<=800px') {
    padding: 0 10px 20px;
  }

  & .inner {
    text-align: center;
  }

  &__title {
    margin: 0 auto 30px;

    & svg {
      margin-bottom: 20px;
    }

    h3 {
      font-size: 42px;
      font-weight: 700;
      color: $paragraph-text-color;
      padding-bottom: 12px;

      @include media('<=800px') {
        font-size: 30px;
      }
    }

    &_sub {
      font-size: 16px;
      font-weight: 400;
      color: $paragraph-text-color;
      line-height: 30px;
      margin: 0 7vw;
    }
  }

  &__content {
    display: grid;
    grid-template-columns: repeat(3, 30%);
    justify-content: space-between;
    gap: 30px;
    padding-top: 20px;

    @include media('<=800px') {
      display: flex;
      overflow-x: auto;
    }

    &_item {
      background-color: $tetiary-color;
      border-radius: 7px;
      box-shadow: 1px 1px 10px rgba(196, 193, 193, 0.53);
      @include media('<=800px') {
        min-width: 85%;
      }

      &_thumb {
        width: 100%;
        background-size: cover;
        background-position: center;
        border-radius: 10px 10px 0 0;

        img {
          width: 100%;
          border-radius: 10px 10px 0 0;
        }
      }

      &_desc {
        padding: 20px 20px 25px;
      }

      &_date {
        color: $accent-text-color;
        font-family: Inter;
        font-size: 14px;
        font-weight: 400;
        line-height: 8px;
        margin-bottom: 10px;
        text-align: left;
      }
      &_title {
        color: $paragraph-text-color;
        text-align: left;
        font-family: Inter;
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 10px;
      }

      &_text {
        color: $accent-text-color;
        text-align: left;
        font-family: Inter;
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 25px;
        @include media('<=800px') {
          margin-bottom: 18px;
        }
      }
      &_author {
        text-align: left;
        & a{
          color: $accent-text-color;
          font-family: Inter;
          font-size: 14px;
          font-weight: 400;
          line-height: 13px;
          & span {
            color: $paragraph-text-color;
            font-family: Inter;
            font-size: 14px;
            font-weight: 500;
            line-height: 13px;
          }
        }
      }
    }
  }
}

.add_space {
  padding-bottom: 50px;
}

.banner {
  &_btn_play_now {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 700;
    color: $primary-background-color;
    background-color: $secondary-background-color;
    text-transform: uppercase;
    padding: 10px 50px;
    display: inline-block;
    border-radius: 20px;
    margin-bottom: 10px;
  }
  &_btn_play_now:hover {
    filter: saturate(2);
  }
}

.overflow-bar {
  &::-webkit-scrollbar-thumb {
    background-color: #925edb;
    border-radius: 10px;
  }
  &::-webkit-scrollbar {
    width: 10px;
    height: 5px;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #dfd2f2;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #925edb;
  }
}

.padding-buffer {
  padding: 15px 0;

  @include media('<=800px') {
    padding: 5px 0 0;
  }
}

.ad_disclosure {
  &:hover .ad_disclosure_popup {
    display: block;
  }
  &_popup {
    max-width: 50vh;
    width: 300px;
    display: none;
    position: absolute;
    right: 0;
    text-align: left;
    background: white;
    padding: 20px;
    border-radius: 15px;
    border: solid 2px $primary-background-color;
    z-index: 10;
    & span {
      color: $primary-background-color;
    }
    @include media('<=800px') {
      width: 90%;
    }
  }
}
