@import "../vars";
@import "../reset";
@import "../breakpoints";

// Styling sheet for single casinos and related casinos found at /single-casinos.php

.single_casino {
  overflow: hidden;
  .inner {
    @include media("<=800px") {
      max-width: 90%;
    }
  }
  &__body {
    margin-top: 50px;
  }
  &__banner {
    background-color: $primary-background-color;
  }
  &__banner_box {
    display: flex;
    gap: 50px;
    padding-top: 20px;

    @include media("<=800px") {
      display: grid;
      gap: 0;
      padding-top: 0;
    }
  }

  &__we_recommend {
    margin: 40px auto;
    border-radius: 20px;
    padding: 30px 45px;
    box-shadow: 0 0 12px rgba(158, 158, 158, 0.55);
    @include media("<=800px") {
      margin: 40px 0;
      padding: 30px 30px;
    }
    &_title {
      margin: 25px auto;
      font-family: Inter;
      font-size: 30px;
      font-weight: 700;
      @include media("<=800px") {
        font-size: 20px;
        text-align: center;
      }
      svg {
        @include media("<=800px") {
          width: 17px;
          height: 16px;
        }
      }
    }
    &_feed {
      margin: 20px auto;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 40px;
      @include media("<=800px") {
        grid-template-columns: repeat(1, 1fr);
      }
      &_single_item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 15px;
        @include media("<=800px") {
          flex-wrap: wrap;
          gap: 10px;
        }
        &_logo img {
          max-width: 110px;
        }
        &_rating {
          flex: 1;
          &_title {
            font-family: Inter;
            font-size: 23px;
            font-weight: 600;
            @include media("<=800px") {
              font-weight: 700;
              font-size: 16px;
            }
          }
        }
        &_button {
          @include media("<=800px") {
            flex-basis: 100%;
            a {
              display: block;
            }
          }
        }
      }
    }
  }

  &__banner_info_introduction,
  &__banner_info_author {
    font-family: Inter;
    font-size: 18px;
    font-weight: 400;
  }
  &__banner_info {
    @include media("<=800px") {
      order: -1;
      & .breadcrumbs {
        padding-bottom: 10px;
      }
    }
  }
  &__banner_info > * {
    color: $tetiary-background-color;
    padding-bottom: 20px;
  }
  &__banner_title {
    font-family: Inter;
    font-size: 42px;
    font-weight: 700;
    @include media("<=800px") {
      font-size: 22px;
    }
  }
  &__banner_info a {
    color: $secondary-background-color;
    text-decoration: underline;
  }
  &__banner_subheading,
  &__banner_subheading p {
    font-family: Inter;
    font-size: 23px;
    font-weight: 700;
    line-height: 33px;
    letter-spacing: 0em;
    text-align: center;
  }
  &__banner_image {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    display: block;
    min-height: 420px;
    background: white;
    min-width: 320px;
    max-width: 320px;
    z-index: 5;
    margin: 0 auto -90px;
    border-radius: 30px 30px 0 0;
    padding: 20px 30px 0;
    & > * {
      padding-bottom: 20px;
    }
  }
  &__banner_thumb {
    //border-radius: 20px;
    width: 180px;
    //height: 110px;
  }

  &__banner_btn_tc {
    display: block;
    padding-top: 5px;
    font-size: 11px;
    text-align: center;
    text-decoration: underline;
    color: $paragraph-text-color;
    &.muted {
      text-decoration: none;
      font-weight: 500;
      color: $background-text-color;
      font-size: 16px;
    }
  }
  &__banner_terms_condition {
    font-size: 14px;
  }
  &__banner_no_play {
    background-color: #f3eefb;
    border-radius: 10px;
    padding: 15px;
    &_title {
      font-family: Mulish;
      font-size: 14px;
      font-weight: 700;
      color: $primary-background-color;
      margin-bottom: 10px;
    }
    &_subtitle {
      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }
  }

  //// tabs
  &__single_item_details {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    margin-left: 20px;
    align-items: center;
    width: 100%;

    @include media("<=800px") {
      grid-template-columns: 100%;
    }
    & p {
      padding-top: 15px;
      display: flex;
      align-items: center;
      & span {
        font-family: Inter;
        font-size: 18px;
        font-weight: 400;
        line-height: 31px;
        width: 80%;
        margin-left: 10px;
      }
    }
    & i {
      color: #fff;
      display: block;
      height: 36px;
      width: 36px;
      border-radius: 20px;
      margin-right: 10px;
      text-align: center;
      padding-top: 6px;
      font-size: 25px;
    }
    &__pros {
      margin-bottom: 20px;
      & i {
        background: #a2d532;
      }
    }
    &__cons {
      margin-bottom: 20px;
      & i {
        background: #ee4d4d;
      }
    }
  }

  &__single_item_inner {
    margin: 20px auto;
    box-shadow: 0 0 12px rgba(158, 158, 158, 0.55);
    padding: 45px 65px 20px;
    border-radius: 10px;
  }
  &__info {
    width: 195px;
    height: 135px;
    background: #f0eefc;
    border-radius: 10px;
    text-align: center;
    padding-top: 20px;
    margin-top: 15px;
    & h3 {
      font-weight: 500;
      color: $primary-background-color;
      font-size: 20px;
      & strong {
        font-size: 30px;
      }
    }
  }
  &__text {
    width: 40%;
    margin-top: 20px;
    font-size: 20px;
    font-weight: 60;
    text-align: center;
  }

  ///// tabs content
  &__single_item {
    &_terms {
      font-family: Inter;
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      margin-top: 1em;
    }
    &_details_features {
      flex: 1 1 50%;
      margin: 50px auto;
      & .nav-tab-wrapper {
        border-radius: 20px 20px 0 0;

        @include media("<=800px") {
          white-space: nowrap;
          overflow: auto;
        }
      }
      .nav-tab.nav-tab-active {
        border: none;
        background-color: $primary-background-color;
        color: $tetiary-background-color;
        border-radius: 20px 20px 0 0;
        @include media("<=800px") {
          border-radius: 12px 12px 0 0;
        }
      }
      & .nav-tab {
        font-size: 20px;
        font-weight: 600;
        padding: 0;
        color: $paragraph-text-color;
        display: inline-block;
        height: 60px;
        padding: 16px 40px;
        @include media("<=800px") {
          padding: 8px;
          font-size: 14px;
          font-weight: 600;
          height: auto;
        }
      }
      & .tab-target {
        padding: 25px 50px;
        min-height: 340px;
        border-radius: 0 20px 20px 20px;
        box-shadow: 5px 8px 15px rgba(196, 193, 193, 0.8);
      }
    }
    &_bonus {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      padding: 50px 53px;
      row-gap: 40px;
      column-gap: 135px;
      @include media("<=800px") {
        grid-template-columns: 100%;
        padding: 50px 0;
        column-gap: 0;
      }
      &_details {
        display: flex;
        align-items: center;
        @include media("<=800px") {
          justify-content: center;
        }
        & img {
          width: 57px;
          height: 57px;
          margin-right: 33px;
        }
      }
      &_title_wrapper {
        flex-grow: 1;
        display: flex;
        justify-content: space-between;
        min-height: 57px;
        align-items: center;
        border-bottom: 1px solid rgba(134, 76, 215, 0.12);
        text-align: center;
        @include media("<=800px") {
          flex-grow: 0;
          width: 150px;
          flex-direction: column;
          margin-top: 1.5px;
          border-bottom: 1px solid transparent;
        }
      }
      &_title {
        font-family: Inter;
        font-size: 20px;
        font-weight: 500;
        line-height: 31px;
        @include media("<=800px") {
          font-size: 16px;
          font-weight: 600;
        }
        &::first-letter {
          text-transform: uppercase;
        }
      }
      &_value {
        font-family: Inter;
        font-size: 25px;
        font-weight: 700;
        color: $primary-background-color;
        @include media("<=800px") {
          font-size: 16px;
          font-weight: 600;
        }
      }
    }
    .bonuses_list &_details_total {
      display: block;
    }
    &_details_total {
      display: grid;
      grid-template-columns: 40% 60%;
      width: 100%;
      @include media("<=800px") {
        grid-template-columns: 100%;
      }
      & li {
        list-style: none;
        line-height: 36px;
        font-size: 12px;
      }
      &_number {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        & p {
          font-family: Inter;
          font-size: 85px;
          font-weight: 500;
          line-height: 122px;
          justify-content: center;
          color: $primary-background-color;
          @include media("<=800px") {
            font-size: 40px;
            font-weight: 600;
            line-height: 50px;
          }
        }
        & span {
          font-family: Inter;
          font-size: 30px;
          font-weight: 500;
          color: $paragraph-text-color;
          @include media("<=800px") {
            font-size: 20px;
            color: #18191f6e;
          }
        }
      }
      &_content {
        & ul li {
          font-family: Inter;
          font-size: 20px;
          font-weight: 500;
          border-bottom: 1px solid $light-background-vary-light;
          width: 370px;
          display: flex;
          justify-content: space-between;
          padding: 10px 0;
          & strong {
            font-family: Inter;
            font-size: 25px;
            font-weight: 700;
            color: $primary-background-color;
          }
          &:last-child {
            border: none;
          }
        }
      }
      &_content,
      &_logos,
      &_icon_list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        gap: 20px;
        align-content: center;
        & div {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }
    }
  }

  &__our_verdict {
    padding: 30px 0 50px;
    @include media("<=800px") {
      &_title {
        font-size: 20px !important;
        text-align: left !important;
      }
      &_container {
        padding: 20px 25px !important;
        & p {
          font-size: 16px !important;
        }
      }
    }
    &_title {
      font-family: Inter;
      font-size: 30px;
      font-weight: 700;
      text-align: center;
      margin-bottom: 20px;
    }
    &_container {
      padding: 25px 50px;
      min-height: 340px;
      border-radius: 20px;
      box-shadow: 5px 8px 15px rgba(196, 193, 193, 0.8);

      & p {
        font-family: Inter;
        font-size: 18px;
        font-weight: 400;
        text-align: left;
        margin-bottom: 15px;
      }

      & a {
        color: #864cd7;
        text-decoration: underline;
      }

      & h2 {
        font-family: Inter;
        font-size: 22px;
        font-weight: 700;
        line-height: 27px;
        letter-spacing: 0em;
        text-align: left;
        margin: 20px 0;

        @include media("<=800px") {
          font-size: 20px;
          line-height: 32px;
        }
      }
    }
    &_score_group {
      padding: 30px 0 50px;
      display: grid;
      grid-template-columns: 33% 66%;

      @include media("<=800px") {
        grid-template-columns: 100%;
        justify-content: center;
      }
      & h4 {
        font-family: Inter;
        font-size: 22px;
        font-weight: 600;
      }
      & .scoreCircle {
        width: 250px;
        margin: 0 auto 10px;
        & svg {
          display: block;
          margin: initial;
          width: initial;
          height: initial;
          & text {
            font-weight: 700;
            text-anchor: middle;
            fill: $paragraph-text-color;
            &.heavy {
              font-weight: 600;
              font-size: 45px;
            }
          }
          & circle {
            transition: stroke-dashoffset 2s
              cubic-bezier(0.16, 0.79, 0.46, 1.03);
            fill: white;
            transform: translate(100px, 100px) rotate(-89.9deg);
          }
        }
      }
      & .arc-background {
        stroke-width: 3px;
        stroke: rgba(0, 0, 0, 0.05);
      }
      & .arc {
        stroke-width: 10px;
        stroke: #fcc13e;
      }
      &_categories {
        @include media("<=800px") {
          margin-top: 38px;
        }

        & h4 {
          @include media("<=800px") {
            display: none;
          }
        }
      }
      &_lines:last-child {
        margin-bottom: 0;
      }
      &_lines {
        @include media("<=800px") {
          margin-bottom: 24px;
        }
      }
    }
    &_score_details {
      & a {
        color: #864cd7;
        text-decoration: underline;
      }
      & .archive_casinos__text {
        font-family: Inter;
        font-size: 20px;
        font-weight: 600;
      }
      &_item_title {
        font-family: Inter;
        font-size: 25px;
        font-weight: 700;
        text-align: left;
        padding: 40px 0 15px;
      }
      &_banner {
        padding: 40px 0 60px;
        & .archive_casinos__single_item_head {
          align-items: center;
        }
      }
    }
  }
}

.archive_casinos {
  &__single_item_head {
    @include media("<=800px") {
      flex-direction: column;
      justify-content: center;
      margin: 0 auto;
      text-align: center;
    }
  }
  &__info {
    @include media("<=800px") {
      margin: 10px auto 0;
    }
  }
  &__text {
    @include media("<=800px") {
      margin: 0 auto;
    }
  }
}

.list_of_reviews {
  margin: 50px 0;
  &__container {
    &_title {
      font-family: Inter;
      font-size: 30px;
      font-weight: 700;
      text-align: center;
    }
    &_search {
      & svg {
        top: 63px;
        left: 20px;
        position: relative;
      }
      & input {
        margin: 20px 0;
        font-size: 16px;
        font-weight: 400;
        width: 100%;
        border-radius: 30px;
        border: none;
        box-shadow: 0 2px 9px rgba(158, 158, 158, 0.25);
        padding: 18px 20px 18px 60px;
        &::placeholder {
          color: #a8a8a8;
        }
      }
    }
    &_feed {
      margin: 20px 0;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      column-gap: 50px;
      row-gap: 20px;

      @include media("<=800px") {
        grid-template-columns: 100%;
      }
      &_item {
        display: none;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: 75px;
        border-radius: 40px;
        border: none;
        box-shadow: 0 2px 9px rgba(158, 158, 158, 0.25);
        padding: 0 20px;
        column-gap: 15px;
        &.active {
          display: flex;
        }
        &_img {
          max-height: 40px;
        }
        &_title {
          flex-grow: 2;
        }
        &_engage {
          background: $secondary-background-color;
          display: block;
          width: 34px;
          height: 34px;
          text-align: center;
          padding: 9px;
          border-radius: 20px;
        }
      }
    }
    &_pagination {
      margin: 50px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 25px;
      div &_page_dot {
        background: $primary-background-color;
        cursor: default;
        box-shadow: 0 2px 8px rgba(158, 158, 158, 0.4);
        display: block;
        width: 13px;
        height: 13px;
        border-radius: 7px;
      }
      &_page_dot.clickable {
        cursor: pointer;
        background: $secondary-background-color;
      }
      &_right {
        transform: scale(-1, 1);
      }
      &_right,
      &_left {
        cursor: pointer;
        background: $primary-background-color;
        display: block;
        width: 30px;
        height: 30px;
        text-align: center;
        padding: 8px;
        border-radius: 20px;
      }
    }
  }
}

.rotate_faq__single_icon {
  transform: rotate(270deg);
  transition: all ease 0.5s;
}
