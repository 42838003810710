@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@300;500;600;800&display=swap");
@import url("https://fonts.cdnfonts.com/css/gilroy-bold");
h1, h2 {
  font-family: 'Inter', sans-serif;
  font-weight: 700; }

h3, h4 {
  font-family: 'Inter', sans-serif;
  font-weight: 400; }

p,
a,
button,
input,
select,
option,
ul,
li,
table,
td,
tr,
th,
tbody,
thead,
div,
header,
section,
aside,
figure,
label,
textarea,
::placeholder {
  font-family: 'Inter', sans-serif;
  font-weight: 400; }

span {
  font-family: 'Inter', sans-serif;
  font-weight: 700; }

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none; }

a {
  text-decoration: none; }

ul,
ol,
li {
  margin: 0;
  padding: 0; }

html {
  scroll-behavior: smooth; }

small {
  font-size: inherit; }

/**
*
* Testing
*
**/
.gh-head.header {
  padding-bottom: 5px; }

.news_banner .banner__inner {
  margin-top: 55px; }
  .news_banner .banner__inner #rev_slider_8_1_forcefullwidth rs-layer {
    font-family: Inter !important; }
  .news_banner .banner__inner #rev_slider_8_1_forcefullwidth rs-module [class^='fa-']:before,
  .news_banner .banner__inner #rev_slider_8_1_forcefullwidth rs-module [class*=' fa-']:before {
    position: relative;
    top: -1px;
    font-size: small; }
  .news_banner .banner__inner #rev_slider_8_1_forcefullwidth .fa-circle.brown:before {
    color: #fcc13e; }
  .news_banner .banner__inner #rev_slider_8_1_forcefullwidth .fa-circle.violet:before {
    color: #864cd7; }
  .news_banner .banner__inner #rev_slider_8_1_forcefullwidth .fa-circle.green:before {
    color: #40c145; }
  .news_banner .banner__inner #rev_slider_8_1_forcefullwidth .fa-circle.red:before {
    color: #ee4266; }
  .news_banner .banner__inner #rev_slider_8_1_forcefullwidth #rev_slider_8_1_wrapper .hermes .tp-tab-date {
    margin-bottom: 7px;
    text-transform: uppercase;
    margin-top: 7px; }
  .news_banner .banner__inner #rev_slider_8_1_forcefullwidth #rev_slider_8_1_wrapper .hermes .tp-tab-title {
    font-family: Inter;
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
    text-transform: none; }
  .news_banner .banner__inner #rev_slider_8_1_wrapper {
    width: 100%; }

.ug-gallery-wrapper {
  min-height: 700px; }
  @media (max-width: 800px) {
    .ug-gallery-wrapper {
      margin: 0 !important; } }
  .ug-gallery-wrapper .ug-theme-panel {
    top: 287px !important; }
    .ug-gallery-wrapper .ug-theme-panel .ug-default-button-fullscreen,
    .ug-gallery-wrapper .ug-theme-panel .ug-default-button-play,
    .ug-gallery-wrapper .ug-theme-panel .ug-default-button-hidepanel,
    .ug-gallery-wrapper .ug-theme-panel .ug-default-button-hidepanel-tip {
      display: none !important; }
    .ug-gallery-wrapper .ug-theme-panel .ug-strip-panel {
      background: white !important; }
      .ug-gallery-wrapper .ug-theme-panel .ug-strip-panel .ug-strip-arrow {
        display: none !important; }
      .ug-gallery-wrapper .ug-theme-panel .ug-strip-panel .ug-thumbs-strip {
        width: 100% !important;
        left: 0 !important;
        top: 40px !important; }
        .ug-gallery-wrapper .ug-theme-panel .ug-strip-panel .ug-thumbs-strip-inner {
          width: 100% !important;
          display: flex;
          justify-content: space-between;
          gap: 20px; }
          .ug-gallery-wrapper .ug-theme-panel .ug-strip-panel .ug-thumbs-strip-inner .ug-thumb-wrapper {
            min-width: 256px !important;
            position: relative !important;
            left: 0 !important; }
            .ug-gallery-wrapper .ug-theme-panel .ug-strip-panel .ug-thumbs-strip-inner .ug-thumb-wrapper .ug-thumb-loader,
            .ug-gallery-wrapper .ug-theme-panel .ug-strip-panel .ug-thumbs-strip-inner .ug-thumb-wrapper .ug-thumb-error,
            .ug-gallery-wrapper .ug-theme-panel .ug-strip-panel .ug-thumbs-strip-inner .ug-thumb-wrapper .ug-thumb-border-overlay,
            .ug-gallery-wrapper .ug-theme-panel .ug-strip-panel .ug-thumbs-strip-inner .ug-thumb-wrapper .ug-thumb-overlay {
              display: none !important; }
            .ug-gallery-wrapper .ug-theme-panel .ug-strip-panel .ug-thumbs-strip-inner .ug-thumb-wrapper img {
              height: 154px !important;
              width: 350px !important;
              left: -40px !important;
              position: relative !important;
              opacity: 1 !important;
              display: block !important; }
            .ug-gallery-wrapper .ug-theme-panel .ug-strip-panel .ug-thumbs-strip-inner .ug-thumb-wrapper h3 {
              font-family: Inter;
              font-size: 14px;
              font-weight: 700;
              line-height: 22px;
              letter-spacing: 0em;
              text-align: left; }
            .ug-gallery-wrapper .ug-theme-panel .ug-strip-panel .ug-thumbs-strip-inner .ug-thumb-wrapper p {
              font-family: Inter;
              font-size: 14px;
              font-weight: 600;
              line-height: 30px;
              letter-spacing: 0em;
              text-align: left; }
            @media (max-width: 800px) {
              .ug-gallery-wrapper .ug-theme-panel .ug-strip-panel .ug-thumbs-strip-inner .ug-thumb-wrapper h3 {
                font-size: 16px;
                font-weight: 700;
                line-height: 25px; }
              .ug-gallery-wrapper .ug-theme-panel .ug-strip-panel .ug-thumbs-strip-inner .ug-thumb-wrapper p {
                font-size: 12px;
                font-weight: 600; } }
    .ug-gallery-wrapper .ug-theme-panel .ug-textpanel {
      top: -150px !important;
      left: 50px !important;
      height: auto !important; }
      @media (max-width: 800px) {
        .ug-gallery-wrapper .ug-theme-panel .ug-textpanel {
          left: 0 !important; } }
      .ug-gallery-wrapper .ug-theme-panel .ug-textpanel .ug-textpanel-bg {
        background: none !important; }
      .ug-gallery-wrapper .ug-theme-panel .ug-textpanel .ug-textpanel-textwrapper {
        position: initial !important;
        height: auto !important;
        width: 580px !important;
        color: white; }
        @media (max-width: 800px) {
          .ug-gallery-wrapper .ug-theme-panel .ug-textpanel .ug-textpanel-textwrapper {
            margin: auto !important;
            width: 300px !important; }
            .ug-gallery-wrapper .ug-theme-panel .ug-textpanel .ug-textpanel-textwrapper .tag_text {
              justify-content: center; } }
        .ug-gallery-wrapper .ug-theme-panel .ug-textpanel .ug-textpanel-textwrapper h3 {
          font-family: Inter;
          font-size: 30px;
          font-weight: 800;
          line-height: 45px;
          letter-spacing: 0em;
          text-align: left; }
        .ug-gallery-wrapper .ug-theme-panel .ug-textpanel .ug-textpanel-textwrapper p {
          font-family: Inter;
          font-size: 18px;
          font-weight: 600;
          line-height: 30px;
          letter-spacing: 0em;
          text-align: left; }
        @media (max-width: 800px) {
          .ug-gallery-wrapper .ug-theme-panel .ug-textpanel .ug-textpanel-textwrapper h3 {
            font-size: 25px;
            font-weight: 700;
            line-height: 39px;
            text-align: center; }
          .ug-gallery-wrapper .ug-theme-panel .ug-textpanel .ug-textpanel-textwrapper p {
            font-size: 12px;
            font-weight: 600;
            line-height: 30px; } }
  .ug-gallery-wrapper .ug-slider-wrapper {
    height: 360px !important;
    overflow: hidden; }
    .ug-gallery-wrapper .ug-slider-wrapper .ug-slider-inner,
    .ug-gallery-wrapper .ug-slider-wrapper .ug-slide-wrapper,
    .ug-gallery-wrapper .ug-slider-wrapper .ug-item-wrapper {
      height: 100% !important; }
    .ug-gallery-wrapper .ug-slider-wrapper .ug-slider-control,
    .ug-gallery-wrapper .ug-slider-wrapper .ug-slider-preloader,
    .ug-gallery-wrapper .ug-slider-wrapper .ug-button-videoplay {
      display: none !important; }
    .ug-gallery-wrapper .ug-slider-wrapper .ug-item-wrapper img {
      width: 100% !important;
      height: auto !important;
      left: 0 !important; }
    @media (max-width: 800px) {
      .ug-gallery-wrapper .ug-slider-wrapper .ug-item-wrapper img {
        width: auto !important;
        height: 110% !important;
        left: -25% !important; } }

.highlights_latest {
  margin: 0 auto; }
  .highlights_latest .inner {
    padding: 55px 0 50px;
    max-width: 1110px;
    display: flex; }
    @media (max-width: 800px) {
      .highlights_latest .inner {
        display: block;
        max-width: 90%; }
        .highlights_latest .inner .highlights,
        .highlights_latest .inner .latest {
          width: 100%; } }
  .highlights_latest__headers {
    padding-left: 30px;
    border-left: 5px solid #8354D4;
    margin-bottom: 40px; }
    .highlights_latest__headers h2 {
      font-weight: 700;
      font-size: 30px; }
  .highlights_latest .latest {
    width: 30%;
    margin-left: 20px;
    border-left: 1px solid #f1f1f1; }
    @media (max-width: 800px) {
      .highlights_latest .latest {
        margin-left: 0;
        border-left: none; } }
  .highlights_latest .highlights {
    width: 68%; }
  .highlights_latest__latest_feed_single_item {
    padding: 25px 0 25px 33px;
    display: flex;
    border-bottom: 1px solid #f1f1f1; }
    .highlights_latest__latest_feed_single_item span {
      width: 33%;
      font-size: 12px;
      font-weight: 700;
      color: #7f8c8d; }
    .highlights_latest__latest_feed_single_item strong {
      width: 66%;
      margin-left: 20px;
      font-size: 12px;
      font-weight: 600;
      color: #292929; }
  .highlights .highlights_latest__news_feed {
    display: flex;
    flex-wrap: wrap; }
    .highlights .highlights_latest__news_feed_single_item {
      flex: 50%;
      text-align: left; }
      .highlights .highlights_latest__news_feed_single_item_inner {
        display: flex;
        flex-direction: column;
        height: 595px;
        margin-right: 30px;
        margin-bottom: 30px;
        overflow: hidden; }
      @media (max-width: 800px) {
        .highlights .highlights_latest__news_feed_single_item {
          flex: 100%; }
          .highlights .highlights_latest__news_feed_single_item_inner {
            margin-right: 0; } }
    .highlights .highlights_latest__news_feed_image {
      min-height: 350px;
      background-size: cover;
      background-position: center; }
    .highlights .highlights_latest__news_feed_info {
      padding-top: 20px; }
      .highlights .highlights_latest__news_feed_info_tag a {
        font-size: 16px;
        font-weight: 600;
        color: #585858; }
      .highlights .highlights_latest__news_feed_info_date {
        font-size: 16px;
        font-weight: 400;
        color: #7f8c8d; }
      .highlights .highlights_latest__news_feed_info_text h4 {
        margin-top: 20px;
        font-family: Inter;
        font-size: 18px;
        font-weight: 700;
        line-height: 28px;
        letter-spacing: 0em;
        text-align: left;
        color: #292929; }
      .highlights .highlights_latest__news_feed_info_text p {
        margin-top: 10px;
        font-size: 16px;
        font-weight: 400;
        color: #292929; }

.site_launches {
  margin: 0 auto; }
  .site_launches .inner {
    padding: 0 0 50px;
    max-width: 1110px; }
    @media (max-width: 800px) {
      .site_launches .inner {
        max-width: 90%; } }
  .site_launches__headers {
    padding-left: 30px;
    border-left: 5px solid #8354D4;
    margin-bottom: 40px; }
    .site_launches__headers h2 {
      font-weight: 700;
      font-size: 30px; }
  .site_launches__news_feed {
    display: flex;
    flex-wrap: wrap; }
    .site_launches__news_feed_single_item:nth-child(5) div {
      margin-right: 0; }
    .site_launches__news_feed_single_item:nth-child(2) {
      flex: 66.66%; }
      @media (max-width: 800px) {
        .site_launches__news_feed_single_item:nth-child(2) .site_launches__news_feed_single_item_inner {
          height: 415px;
          flex-direction: column;
          gap: 18px; } }
      .site_launches__news_feed_single_item:nth-child(2) .site_launches__news_feed_image {
        min-height: 415px; }
      .site_launches__news_feed_single_item:nth-child(2) .site_launches__news_feed_info a p,
      .site_launches__news_feed_single_item:nth-child(2) .site_launches__news_feed_info span:nth-child(1),
      .site_launches__news_feed_single_item:nth-child(2) .site_launches__news_feed_info span:nth-child(2) {
        display: none; }
      .site_launches__news_feed_single_item:nth-child(2) .site_launches__news_feed_info_text h4 {
        font-size: 30px;
        line-height: 50px;
        font-weight: 700;
        z-index: 2;
        position: relative;
        top: -180px;
        margin: 0 50px;
        color: #f7f7f7;
        text-shadow: 2px 2px 3px #0000008c; }
        @media (max-width: 800px) {
          .site_launches__news_feed_single_item:nth-child(2) .site_launches__news_feed_info_text h4 {
            font-size: 23px !important;
            font-weight: 600;
            line-height: 28px !important;
            margin: 0 27px;
            text-align: center;
            font-style: italic; } }
      .site_launches__news_feed_single_item:nth-child(2) div {
        margin-right: 0; }
    .site_launches__news_feed_single_item {
      flex: 33.33%;
      text-align: left; }
      .site_launches__news_feed_single_item_inner {
        display: flex;
        flex-direction: column;
        height: 415px;
        margin-right: 30px;
        margin-bottom: 30px;
        overflow: hidden; }
      @media (max-width: 800px) {
        .site_launches__news_feed_single_item {
          flex: 100% !important;
          width: 100%; }
          .site_launches__news_feed_single_item_inner {
            flex-direction: row;
            margin-right: 0;
            height: auto;
            margin-bottom: 20px;
            gap: 18px; } }
    .site_launches__news_feed_image {
      min-height: 190px;
      background-size: cover;
      background-position: center; }
      @media (max-width: 800px) {
        .site_launches__news_feed_image {
          min-width: 106px;
          min-height: 106px; } }
    .site_launches__news_feed_info {
      padding-top: 20px; }
      @media (max-width: 800px) {
        .site_launches__news_feed_info {
          display: flex;
          flex-direction: column;
          padding-top: 0; }
          .site_launches__news_feed_info_tag {
            display: flex;
            flex-direction: column; }
          .site_launches__news_feed_info_date {
            font-size: 10px !important; } }
      .site_launches__news_feed_info_tag a {
        font-size: 16px;
        font-weight: 600;
        color: #585858; }
      .site_launches__news_feed_info_date {
        font-size: 16px;
        font-weight: 400;
        color: #7f8c8d; }
      @media (max-width: 800px) {
        .site_launches__news_feed_info_text h4 {
          font-size: 14px !important;
          line-height: 20px !important;
          font-weight: 600 !important; }
        .site_launches__news_feed_info_text p {
          display: none; } }
      .site_launches__news_feed_info_text h4 {
        margin-top: 20px;
        font-family: Inter;
        font-size: 18px;
        font-weight: 700;
        line-height: 28px;
        letter-spacing: 0em;
        text-align: left;
        color: #292929; }
      .site_launches__news_feed_info_text p {
        margin-top: 10px;
        font-size: 16px;
        font-weight: 400;
        color: #292929; }

.interesting .site_launches__news_feed_single_item:nth-child(2) {
  flex: 33%; }
  @media (max-width: 800px) {
    .interesting .site_launches__news_feed_single_item:nth-child(2) .site_launches__news_feed_single_item_inner {
      height: auto;
      flex-direction: row;
      gap: 18px; } }
  .interesting .site_launches__news_feed_single_item:nth-child(2) .site_launches__news_feed_image {
    min-height: 190px; }
    @media (max-width: 800px) {
      .interesting .site_launches__news_feed_single_item:nth-child(2) .site_launches__news_feed_image {
        min-width: 106px;
        min-height: 106px; } }
  .interesting .site_launches__news_feed_single_item:nth-child(2) .site_launches__news_feed_info a p,
  .interesting .site_launches__news_feed_single_item:nth-child(2) .site_launches__news_feed_info span:nth-child(1),
  .interesting .site_launches__news_feed_single_item:nth-child(2) .site_launches__news_feed_info span:nth-child(2) {
    display: flex; }
  .interesting .site_launches__news_feed_single_item:nth-child(2) .site_launches__news_feed_info_text h4 {
    position: unset;
    margin-top: 20px;
    font-family: Inter;
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    color: #292929;
    text-shadow: none;
    margin: 20px 0 0; }
    @media (max-width: 800px) {
      .interesting .site_launches__news_feed_single_item:nth-child(2) .site_launches__news_feed_info_text h4 {
        font-size: 14px !important;
        line-height: 20px !important;
        font-weight: 600 !important;
        font-style: normal !important; } }
  .interesting .site_launches__news_feed_single_item:nth-child(2) div {
    margin-right: 15px; }
    @media (max-width: 800px) {
      .interesting .site_launches__news_feed_single_item:nth-child(2) div {
        margin-right: 0; } }

.most-popular {
  margin: 50px auto; }
  .most-popular .inner {
    box-shadow: #0000003f 5px 5px 15px 0;
    border-radius: 20px;
    padding: 55px 50px 50px;
    max-width: 1110px; }
    @media (max-width: 800px) {
      .most-popular .inner {
        max-width: 90%; } }
  .most-popular__headers {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 20px; }
  .most-popular__news_feed {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 25px; }
    .most-popular__news_feed_single_item {
      text-align: left; }
      .most-popular__news_feed_single_item_inner {
        display: flex;
        flex-direction: row;
        border-bottom: 1px solid #e8e8e8;
        padding-bottom: 24px; }
    .most-popular__news_feed_image {
      border-radius: 5px;
      height: 100px;
      min-width: 100px;
      background-size: cover;
      background-position: center; }
    .most-popular__news_feed_info {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-left: 15px; }
      .most-popular__news_feed_info_text {
        flex: 0 1 100%; }
        .most-popular__news_feed_info_text h4 {
          font-size: 16px;
          font-weight: 500;
          text-decoration: none;
          color: #18191f; }
          .most-popular__news_feed_info_text h4:hover {
            color: #737b7d; }
      .most-popular__news_feed_info_date, .most-popular__news_feed_info_name {
        font-size: 10px;
        font-weight: 400;
        color: #18191f;
        margin-top: 10px; }
      .most-popular__news_feed_info_name {
        margin-left: 15px;
        color: #737b7d; }
        .most-popular__news_feed_info_name a {
          color: #737b7d; }
          .most-popular__news_feed_info_name a:hover {
            text-decoration: underline; }

.tag_list {
  margin: 0 auto; }
  .tag_list .inner {
    padding: 0 0 50px;
    max-width: 1110px;
    display: flex; }
    @media (max-width: 800px) {
      .tag_list .inner {
        flex-direction: column;
        max-width: 90%; } }
  .tag_list__headers_title {
    margin-bottom: 30px;
    margin-right: 30px;
    font-weight: 700;
    font-size: 25px;
    border-bottom: 2px solid #f1f1f1;
    padding-bottom: 20px;
    color: black; }
    @media (max-width: 800px) {
      .tag_list__headers_title {
        border: none; } }
    .tag_list__headers_title svg {
      margin-left: 15px; }
  .tag_list .tags {
    width: 100%; }
  .tags .tag_list__news_feed {
    display: flex;
    flex-wrap: wrap; }
    .tags .tag_list__news_feed_banner {
      position: relative;
      margin-bottom: 30px; }
      .tags .tag_list__news_feed_banner span {
        position: absolute;
        margin-top: 14%;
        margin-left: 40px;
        font-family: Inter;
        font-size: 30px;
        font-weight: 700;
        line-height: 45px;
        color: #f1f1f1;
        max-width: 35%;
        text-shadow: 2px 2px 3px #0000008c; }
        @media (max-width: 800px) {
          .tags .tag_list__news_feed_banner span {
            max-width: 100%;
            font-size: 23px !important;
            font-weight: 600;
            line-height: 28px !important;
            text-align: center;
            font-style: italic;
            margin: 0;
            bottom: 20px;
            width: 90%;
            left: 50%;
            transform: translate(-50%, 0); } }
      .tags .tag_list__news_feed_banner img {
        width: 766px; }
        @media (max-width: 800px) {
          .tags .tag_list__news_feed_banner img {
            width: 100%; } }
    .tags .tag_list__news_feed_single_item {
      flex: 50%;
      text-align: left;
      margin-bottom: 10px; }
      @media (max-width: 800px) {
        .tags .tag_list__news_feed_single_item {
          flex: 100%; }
          .tags .tag_list__news_feed_single_item_inner {
            margin-right: 0 !important; }
          .tags .tag_list__news_feed_single_item_more {
            margin-right: 0 !important; } }
      .tags .tag_list__news_feed_single_item_inner {
        display: flex;
        flex-direction: column;
        height: 389px;
        margin-right: 30px;
        margin-bottom: 30px;
        overflow: hidden; }
        @media (max-width: 800px) {
          .tags .tag_list__news_feed_single_item_inner {
            height: auto;
            margin-bottom: 5px; } }
      .tags .tag_list__news_feed_single_item_more {
        height: 80px;
        border-top: 2px solid #f1f1f1;
        margin-right: 30px;
        display: flex;
        align-items: center; }
        @media (max-width: 800px) {
          .tags .tag_list__news_feed_single_item_more {
            height: 70px; } }
        .tags .tag_list__news_feed_single_item_more h4 {
          font-family: Inter;
          font-size: 14px;
          font-weight: 600;
          color: #292929;
          text-decoration: none; }
      .tags .tag_list__news_feed_single_item_more:nth-child(5) {
        margin-bottom: 30px; }
    .tags .tag_list__news_feed_image {
      min-height: 202px;
      background-size: cover;
      background-position: center; }
    .tags .tag_list__news_feed_info_tag a {
      font-size: 16px;
      font-weight: 600;
      color: #585858; }
    .tags .tag_list__news_feed_info_date {
      font-size: 16px;
      font-weight: 400;
      color: #7f8c8d; }
    @media (max-width: 800px) {
      .tags .tag_list__news_feed_info_text a p {
        display: none; }
      .tags .tag_list__news_feed_info_text p {
        display: none; }
      .tags .tag_list__news_feed_info_text h4 {
        font-size: 16px !important;
        line-height: 26px !important; } }
    .tags .tag_list__news_feed_info_text a {
      color: #292929; }
    .tags .tag_list__news_feed_info_text h4 {
      margin-top: 20px;
      margin-bottom: 15px;
      font-family: Inter;
      font-size: 18px;
      font-weight: 700;
      line-height: 28px;
      letter-spacing: 0em;
      text-align: left;
      color: #292929; }
    .tags .tag_list__news_feed_info_text p {
      font-size: 16px;
      font-weight: 400;
      color: #292929; }
  .choice .tag_list__headers {
    padding-left: 30px;
    border-left: 5px solid #8354D4;
    margin-bottom: 40px;
    margin-bottom: 0; }
    .choice .tag_list__headers h2 {
      font-weight: 700;
      font-size: 30px;
      padding-bottom: 15px; }
  .choice .tag_list__news_feed {
    display: flex;
    flex-direction: column;
    border-left: 2px solid #f1f1f1; }
    @media (max-width: 800px) {
      .choice .tag_list__news_feed {
        flex-direction: row;
        overflow: auto;
        border: none; }
        .choice .tag_list__news_feed_single_item {
          min-width: 243px;
          margin: 0 15px 0 0 !important; } }
    .choice .tag_list__news_feed_single_item {
      width: 275px;
      text-align: left;
      margin: 0 0 15px 37px; }
      .choice .tag_list__news_feed_single_item_inner {
        display: flex;
        flex-direction: column;
        overflow: hidden; }
    .choice .tag_list__news_feed_image {
      height: 255px;
      max-width: 280px;
      background-size: cover;
      background-position: center; }
    .choice .tag_list__news_feed_info {
      margin-top: 5px; }
      .choice .tag_list__news_feed_info_tag a {
        font-size: 16px;
        font-weight: 600;
        color: #585858; }
      .choice .tag_list__news_feed_info_text h4 {
        margin-top: 10px;
        font-family: Inter;
        font-size: 18px;
        font-weight: 700;
        line-height: 28px;
        color: #292929; }
      .choice .tag_list__news_feed_info_text p {
        margin-top: 10px;
        font-size: 16px;
        font-weight: 400;
        color: #292929; }

.guides-section {
  margin: 0 auto; }
  .guides-section .nav-tab {
    font-size: 20px;
    font-weight: 600; }
  .guides-section .nav-tab.nav-tab-active {
    border: none;
    color: #292929; }
    @media (max-width: 800px) {
      .guides-section .nav-tab.nav-tab-active {
        background: #864cd7;
        color: white;
        border-radius: 84px; } }
  .guides-section .guides-nav-tab-wrapper {
    display: flex;
    overflow: auto; }
  .guides-section .inner {
    padding: 0 0 50px;
    max-width: 1110px; }
    @media (max-width: 800px) {
      .guides-section .inner {
        max-width: 90%; } }
  .guides-section__headers {
    padding-left: 30px;
    border-left: 5px solid #8354D4;
    margin-bottom: 40px; }
    .guides-section__headers h2 {
      font-weight: 700;
      font-size: 30px; }
  .guides-section__guides_feed {
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap; }
    .guides-section__guides_feed_single_item {
      flex: 33%;
      text-align: left; }
      @media (max-width: 800px) {
        .guides-section__guides_feed_single_item {
          flex: 100% !important;
          width: 100%; }
          .guides-section__guides_feed_single_item_inner {
            flex-direction: row !important;
            margin-right: 0 !important;
            height: auto;
            margin-bottom: 20px;
            gap: 18px; }
          .guides-section__guides_feed_single_item_info p {
            display: none; } }
      .guides-section__guides_feed_single_item_inner {
        display: flex;
        flex-direction: column;
        margin-right: 30px;
        margin-bottom: 30px;
        overflow: hidden; }
      .guides-section__guides_feed_single_item_image {
        min-height: 202px;
        background-size: cover;
        background-position: center; }
        @media (max-width: 800px) {
          .guides-section__guides_feed_single_item_image {
            min-width: 106px;
            min-height: 106px; } }
      @media (max-width: 800px) {
        .guides-section__guides_feed_single_item_info a h4 {
          margin-top: 0 !important; }
        .guides-section__guides_feed_single_item_info a span {
          display: none; } }
      .guides-section__guides_feed_single_item_info a h4 {
        margin: 20px 0 15px;
        font-family: Inter;
        font-size: 18px;
        font-weight: 700;
        line-height: 28px;
        letter-spacing: 0em;
        text-align: left;
        color: #292929; }
      .guides-section__guides_feed_single_item_info a span {
        font-family: Inter;
        font-size: 16px;
        font-weight: 400;
        line-height: 23px;
        letter-spacing: 0em;
        text-align: left;
        color: #292929; }
    .guides-section__guides_feed_single_item:nth-child(1) {
      flex: 66%; }
      @media (max-width: 800px) {
        .guides-section__guides_feed_single_item:nth-child(1) .guides-section__guides_feed_single_item_inner {
          flex-direction: column !important;
          gap: 18px; } }
      .guides-section__guides_feed_single_item:nth-child(1) .guides-section__guides_feed_single_item_image {
        min-height: 476px; }
      .guides-section__guides_feed_single_item:nth-child(1) .guides-section__guides_feed_single_item_info a h4 {
        font-size: 30px;
        line-height: 50px;
        font-weight: 700;
        z-index: 2;
        position: relative;
        top: -180px;
        margin: 0 50px;
        color: #f7f7f7;
        text-shadow: 2px 2px 3px #0000008c; }
        @media (max-width: 800px) {
          .guides-section__guides_feed_single_item:nth-child(1) .guides-section__guides_feed_single_item_info a h4 {
            font-size: 23px;
            font-weight: 600;
            line-height: 28px;
            max-width: 100%;
            margin-left: 0;
            margin: 0 27px;
            text-align: center;
            font-style: italic; } }
      .guides-section__guides_feed_single_item:nth-child(1) .guides-section__guides_feed_single_item_info a span {
        display: none; }

.news_card {
  margin: 0 auto; }
  .news_card .inner {
    padding: 30px 0 50px;
    max-width: 1110px;
    display: flex;
    flex-wrap: wrap; }
    @media (max-width: 1100px) {
      .news_card .inner {
        max-width: 90%; } }
    .news_card .inner .news {
      flex: 60%; }
      @media (max-width: 1100px) {
        .news_card .inner .news {
          flex: 100%; } }
    .news_card .inner .latest {
      flex: 40%;
      padding-left: 50px; }
      @media (max-width: 1100px) {
        .news_card .inner .latest {
          flex: 100%;
          margin-top: 50px;
          padding-left: 0; } }
  .news_card__headers {
    margin-bottom: 20px; }
    .news_card__headers h1 {
      font-family: Inter;
      font-size: 35px;
      font-weight: 700;
      line-height: 47px;
      letter-spacing: 0em;
      text-align: left; }
      @media (max-width: 1100px) {
        .news_card__headers h1 {
          font-size: 23px;
          line-height: 33px; } }
  .news_card__news_tag a {
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    line-height: 30px;
    color: #292929; }
  .news_card__news_date {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 30px;
    color: #7f8c8d; }
  .news_card__news {
    margin-top: 20px; }
    .news_card__news_content p {
      margin: 15px auto;
      font-family: Inter;
      font-size: 16px;
      font-weight: 400;
      line-height: 23px;
      letter-spacing: 0em;
      text-align: left; }
    .news_card__news_content img {
      width: 100% !important;
      height: auto !important; }
    .news_card__news_content a {
      color: #7e53dd;
      font-weight: 600;
      text-decoration: underline; }
    .news_card__news_content h2 span {
      color: #292929 !important; }
    .news_card__news_author.bottom {
      display: grid; }
      .news_card__news_author.bottom .news_card__news_author_icon,
      .news_card__news_author.bottom .news_card__news_author_info {
        display: block; }
    .news_card__news_author {
      margin: 20px auto;
      display: grid;
      grid-template-columns: 80px 36% 50%;
      align-items: center;
      justify-content: space-between; }
      @media (max-width: 1100px) {
        .news_card__news_author {
          display: flex; } }
      .news_card__news_author_social {
        text-align: right; }
        @media (max-width: 1100px) {
          .news_card__news_author_social {
            width: 100%;
            display: flex;
            justify-content: space-between; } }
      .news_card__news_author .author-name {
        font-family: Inter;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        color: #292929; }
      @media (max-width: 1100px) {
        .news_card__news_author_info {
          display: none; } }
      .news_card__news_author_info_date {
        font-family: Inter;
        font-size: 14px;
        font-weight: 500;
        line-height: 30px;
        letter-spacing: 0em;
        text-align: left;
        color: #7f8c8d; }
        .news_card__news_author_info_date .fa-circle {
          font-size: 0.5em;
          line-height: 0.2em;
          vertical-align: 0.2em;
          margin: 0 10px; }
      .news_card__news_author_info_date {
        font-family: Inter;
        font-size: 14px;
        font-weight: 500;
        line-height: 30px;
        letter-spacing: 0em;
        text-align: left;
        color: #7f8c8d; }
        .news_card__news_author_info_date .fa-circle {
          font-size: 0.5em;
          line-height: 0.2em;
          vertical-align: 0.2em;
          margin: 0 10px; }
      @media (max-width: 1100px) {
        .news_card__news_author_icon {
          display: none; } }
      .bottom .news_card__news_author_icon img {
        border: 4px solid #FFFFFF;
        box-shadow: #0000003f 3px 3px 20px 0; }
      .news_card__news_author_icon img {
        width: 65px;
        height: 65px;
        border-radius: 100px;
        object-fit: cover; }
      .news_card__news_author_icon svg {
        background: #7f8c8d; }
    .news_card__news_content h1,
    .news_card__news_content h2,
    .news_card__news_content h3 {
      font-family: Inter;
      font-size: 20px;
      font-weight: 600;
      line-height: 23px;
      letter-spacing: 0em;
      text-align: left; }
  .news_card__news_image img {
    width: 100%; }
  .latest .news_card__headers {
    padding-left: 30px;
    border-left: 5px solid #8354D4;
    margin-bottom: 40px; }
    .latest .news_card__headers h2 {
      font-weight: 700;
      font-size: 30px; }
  .latest .news_card__latest_feed {
    display: flex;
    flex-direction: column;
    border-left: 2px solid #f1f1f1; }
    @media (max-width: 1100px) {
      .latest .news_card__latest_feed {
        border: none; } }
    .latest .news_card__latest_feed_single_item {
      width: 275px;
      text-align: left;
      margin: 0 0 20px 37px;
      padding-bottom: 20px;
      border-bottom: 2px solid #f1f1f1; }
      @media (max-width: 1100px) {
        .latest .news_card__latest_feed_single_item {
          margin-left: 0; } }
      .latest .news_card__latest_feed_single_item_inner {
        display: flex;
        flex-direction: row;
        overflow: hidden; }
    .latest .news_card__latest_feed_image {
      height: 74px;
      min-width: 86px;
      background-size: cover;
      background-position: center; }
    .latest .news_card__latest_feed_date {
      font-family: Inter;
      font-size: 12px;
      font-weight: 400;
      line-height: 10px;
      letter-spacing: 0em;
      text-align: left;
      color: #7f8c8d; }
    .latest .news_card__latest_feed_info {
      margin-left: 15px; }
      .latest .news_card__latest_feed_info_text h4 {
        font-family: Inter;
        font-size: 14px;
        font-weight: 600;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;
        color: #292929; }

.guides_feed {
  margin: 40px auto; }
  .guides_feed .inner {
    display: flex;
    flex-wrap: wrap;
    padding: 0 20px; }
  .guides_feed__headers {
    margin-bottom: 40px; }
    .guides_feed__headers h2 {
      font-weight: 700;
      font-size: 30px;
      margin-bottom: 5px; }
    .guides_feed__headers p {
      font-family: Inter;
      font-size: 18px;
      font-weight: 400;
      line-height: 38px;
      text-align: left; }
      @media (max-width: 800px) {
        .guides_feed__headers p {
          font-size: 16px;
          line-height: 23px; } }
      .guides_feed__headers p a {
        color: #8354D4;
        text-decoration: underline; }
  .guides_feed__categories {
    flex: 30%; }
    @media (max-width: 800px) {
      .guides_feed__categories {
        flex: 100%;
        order: 2; } }
    .guides_feed__categories_headers {
      border-left: 5px solid #8354d4;
      text-align: left;
      padding-left: 20px;
      margin-bottom: 20px;
      display: block; }
      .guides_feed__categories_headers_title {
        font-family: Inter;
        font-size: 28px;
        font-weight: 700;
        margin: 5px 0; }
    .guides_feed__categories_item {
      padding: 15px 0 15px 33px;
      display: flex;
      border-bottom: 1px solid #f1f1f1;
      flex-direction: row;
      font-family: Inter;
      font-size: 16px;
      font-weight: 400;
      line-height: 30px;
      color: #8354D4; }
      @media (max-width: 800px) {
        .guides_feed__categories_item {
          display: inline-block !important;
          padding: 0 10px 0; } }
  .guides_feed__single_item {
    text-align: left; }
    .guides_feed__single_item_inner {
      display: flex;
      flex-direction: row;
      margin-right: 30px;
      margin-bottom: 30px;
      overflow: hidden; }
    .guides_feed__single_item_image {
      min-width: 254px;
      min-height: 231px;
      background-size: cover;
      background-position: center; }
    .guides_feed__single_item_info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 20px 10px 20px 30px;
      border: 1px solid #f1f1f1; }
      .guides_feed__single_item_info_title h4 {
        font-family: Inter;
        font-size: 18px;
        font-weight: 700;
        line-height: 30px;
        color: #292929; }
      .guides_feed__single_item_info_date, .guides_feed__single_item_info_text {
        font-family: Inter;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        color: #292929; }
        .guides_feed__single_item_info_date a, .guides_feed__single_item_info_text a {
          text-decoration: underline;
          color: #8354D4; }
  .guides_feed__feed {
    flex: 70%; }
    @media (max-width: 800px) {
      .guides_feed__feed {
        flex: 100%;
        order: 0; } }
  @media (max-width: 800px) {
    .guides_feed .pagination {
      margin-bottom: 20px; } }

.tag_feed {
  margin: 40px auto;
  display: flex;
  flex-wrap: wrap;
  border-left: 1px solid #f1f1f1; }
  @media (max-width: 800px) {
    .tag_feed {
      flex-wrap: wrap-reverse;
      border: none; } }
  .news .tag_feed__headers {
    padding-left: 30px;
    border-left: 5px solid #8354D4;
    margin-bottom: 40px; }
    .news .tag_feed__headers h2 {
      font-weight: 700;
      font-size: 30px; }
  .tag_feed__news {
    flex: 30%; }
    @media (max-width: 800px) {
      .tag_feed__news {
        flex: 100%;
        padding: 0 20px; } }
  .tag_feed__feed {
    flex: 70%;
    padding-left: 50px; }
    @media (max-width: 800px) {
      .tag_feed__feed {
        flex: 100%;
        padding: 0 20px; } }
  .tag_feed__headers {
    margin-bottom: 40px; }
    .tag_feed__headers h2 {
      font-weight: 700;
      font-size: 30px; }
  .tag_feed__latest_feed_single_item {
    padding: 25px 0 25px 33px;
    display: flex;
    border-bottom: 1px solid #f1f1f1;
    flex-direction: row; }
    @media (max-width: 800px) {
      .tag_feed__latest_feed_single_item {
        padding-left: 0; } }
    .tag_feed__latest_feed_single_item span {
      width: 33%;
      font-size: 12px;
      font-weight: 700;
      color: #7f8c8d; }
    .tag_feed__latest_feed_single_item strong {
      width: 66%;
      margin-left: 20px;
      font-size: 12px;
      font-weight: 600;
      color: #292929; }
  .tag_feed__single_item {
    text-align: left; }
    .tag_feed__single_item_inner {
      display: flex;
      flex-direction: row;
      margin-right: 30px;
      margin-bottom: 30px;
      overflow: hidden; }
      @media (max-width: 800px) {
        .tag_feed__single_item_inner {
          flex-direction: column;
          margin-right: 0; } }
    .tag_feed__single_item_image {
      min-width: 254px;
      min-height: 231px;
      background-size: cover;
      background-position: center; }
    .tag_feed__single_item_info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 20px 45px 20px 30px;
      border: 1px solid #f1f1f1; }
      @media (max-width: 800px) {
        .tag_feed__single_item_info {
          padding: 20px 22px 20px 22px;
          gap: 12px; } }
      .tag_feed__single_item_info_title h4 {
        font-family: Inter;
        font-size: 18px;
        font-weight: 700;
        line-height: 30px;
        color: #292929; }
      .tag_feed__single_item_info_date, .tag_feed__single_item_info_text {
        font-family: Inter;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        color: #292929; }
        .tag_feed__single_item_info_date a, .tag_feed__single_item_info_text a {
          text-decoration: underline;
          color: #8354D4; }

@media (max-width: 800px) {
  .article .site_launches__news_feed_single_item_inner {
    flex-direction: column !important; }
  .article .site_launches__news_feed_image {
    min-height: 180px !important; }
  .article .site_launches__news_feed_info_date {
    display: none !important; }
  .article .site_launches__news_feed_info_text {
    font-size: 16px !important; } }
