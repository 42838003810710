@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@300;500;600;800&display=swap");
@import url("https://fonts.cdnfonts.com/css/gilroy-bold");
h1, h2 {
  font-family: 'Inter', sans-serif;
  font-weight: 700; }

h3, h4 {
  font-family: 'Inter', sans-serif;
  font-weight: 400; }

p,
a,
button,
input,
select,
option,
ul,
li,
table,
td,
tr,
th,
tbody,
thead,
div,
header,
section,
aside,
figure,
label,
textarea,
::placeholder {
  font-family: 'Inter', sans-serif;
  font-weight: 400; }

span {
  font-family: 'Inter', sans-serif;
  font-weight: 700; }

/**
*
* Testing
*
**/
.pagination {
  display: flex;
  justify-content: center;
  gap: 20px;
  align-items: baseline;
  color: #7e53dd; }
  .pagination span,
  .pagination a {
    display: block;
    padding: 7px;
    border-radius: 3px; }
  .pagination__current_page {
    color: white;
    background: #7e53dd; }
  .pagination__first_post, .pagination__last_post {
    color: #864CD7;
    background: #E8E1F3;
    font-weight: 500; }
  .pagination__newer_posts, .pagination__older_posts {
    padding: 9px 12px !important;
    color: white;
    background: #FCC13E; }
  .pagination__newer_posts.muted, .pagination__older_posts.muted {
    background-color: white;
    color: #FCC13E;
    border: 1px solid #FCC13E; }
  .pagination__of_posts {
    font-weight: 500; }
