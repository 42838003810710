@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@300;500;600;800&display=swap");
@import url("https://fonts.cdnfonts.com/css/gilroy-bold");
h1, h2 {
  font-family: 'Inter', sans-serif;
  font-weight: 700; }

h3, h4 {
  font-family: 'Inter', sans-serif;
  font-weight: 400; }

p,
a,
button,
input,
select,
option,
ul,
li,
table,
td,
tr,
th,
tbody,
thead,
div,
header,
section,
aside,
figure,
label,
textarea,
::placeholder {
  font-family: 'Inter', sans-serif;
  font-weight: 400; }

span {
  font-family: 'Inter', sans-serif;
  font-weight: 700; }

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none; }

a {
  text-decoration: none; }

ul,
ol,
li {
  margin: 0;
  padding: 0; }

html {
  scroll-behavior: smooth; }

small {
  font-size: inherit; }

/**
*
* Testing
*
**/
.archive_casinos__banner {
  background: #8354D4;
  color: #f7f7f7; }

.archive_casinos__heading {
  font-size: 42px;
  font-weight: 700;
  padding-top: 20px; }

.archive_casinos__subheading {
  font-size: 16px;
  font-weight: 600;
  color: #FCC13E;
  padding: 20px 0; }

.archive_casinos__paragraph {
  font-weight: 400;
  font-size: 16px;
  padding-bottom: 30px; }

.archive_casinos__title {
  width: 50%; }

.archive_casinos__img_title {
  height: 335px; }

.archive_casinos__img {
  position: absolute;
  z-index: 100;
  padding-left: 649px;
  top: 56px; }

.archive_casinos__shape-top {
  background-color: #8354D4;
  width: 100%;
  height: 50px;
  border-radius: 50% / 75%;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #8354D4;
  transform: scale(1.05, -1); }

.archive_casinos .anchor__menu {
  max-width: none;
  border: none;
  border-radius: 0; }

.archive_casinos__image {
  display: flex;
  flex-direction: column;
  align-items: center; }
  .archive_casinos__image img {
    width: 160px;
    border-radius: 15px; }

.archive_casinos__image_rev, .archive_casinos__engage_tc, .archive_casinos__engage_rev {
  text-decoration: underline;
  color: #585858;
  font-size: 14px;
  padding: 5px; }

.archive_casinos__engage_tc.muted {
  text-decoration: none;
  font-weight: 500;
  color: #7f8c8d; }

.archive_casinos__casinos_item_box {
  margin: 10px auto; }
  .archive_casinos__casinos_item_box .inner .ad_disclosure {
    margin-bottom: 45px; }

@media (max-width: 800px) {
  .archive_casinos__single_item {
    padding: 0 20px; } }

.archive_casinos__single_item_head {
  display: flex;
  justify-content: space-between; }

.archive_casinos__single_item_about {
  padding: 20px 0;
  flex: 2 1 100%;
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px; }
  .archive_casinos__single_item_about a {
    color: #FCC13E;
    text-decoration: underline; }

.archive_casinos__single_item_details {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: baseline; }
  .archive_casinos__single_item_details h4 {
    font-family: Mulish, sans-serif;
    font-weight: 700;
    font-size: 18px;
    margin-top: 20px; }
  .archive_casinos__single_item_details_field {
    padding-top: 15px;
    display: flex;
    align-items: center; }
    .archive_casinos__single_item_details_field span {
      font-weight: 400;
      font-size: 16px;
      width: 90%; }
      @media (max-width: 800px) {
        .archive_casinos__single_item_details_field span {
          font-size: 12px; } }
  .archive_casinos__single_item_details i {
    color: #fff;
    display: block;
    height: 22px;
    width: 22px;
    border-radius: 15px;
    margin-right: 10px;
    text-align: center;
    padding-top: 4px; }
  .archive_casinos__single_item_details__pros i {
    background: #a2d532; }
  .archive_casinos__single_item_details__cons i {
    background: #ee4d4d; }
  .archive_casinos__single_item_details_pros_cons {
    display: flex;
    flex-direction: row;
    gap: 20px;
    flex: 1 1 50%;
    padding-right: 20px; }
    @media (max-width: 800px) {
      .archive_casinos__single_item_details_pros_cons {
        padding: 0 0 20px 0; } }

.archive_casinos__single_item_inner {
  margin: 20px auto;
  box-shadow: 0 0 12px rgba(158, 158, 158, 0.55);
  padding: 45px 65px 20px;
  border-radius: 10px;
  max-width: 1100px; }
  @media (max-width: 500px) {
    .archive_casinos__single_item_inner {
      width: 95%;
      padding: 30px 20px; } }

.archive_casinos__info {
  width: 195px;
  height: 135px;
  background: #f0eefc;
  border-radius: 10px;
  text-align: center;
  padding-top: 20px;
  margin-top: 15px; }
  .archive_casinos__info h3 {
    font-weight: 500;
    color: #8354D4;
    font-size: 20px; }
    .archive_casinos__info h3 strong {
      font-size: 30px; }

.archive_casinos__text h4 > * {
  text-align: center !important; }

.archive_casinos__text {
  width: 40%;
  margin-top: 20px;
  font-size: 20px;
  font-weight: 60;
  text-align: center; }
  .archive_casinos__text a {
    display: block !important; }
  @media (max-width: 800px) {
    .archive_casinos__text {
      width: 100%;
      padding: 20px 0; }
      .archive_casinos__text a {
        display: none !important; } }

div .archive_casinos__engage {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center; }
  div .archive_casinos__engage_play {
    color: #8354D4 !important;
    text-decoration: none !important;
    background: #FCC13E;
    padding: 11px 50px;
    text-transform: uppercase;
    font-weight: 700; }
  div .archive_casinos__engage_tc {
    padding-top: 20px;
    color: #4b4b4b !important; }
    @media (max-width: 800px) {
      div .archive_casinos__engage_tc {
        display: block; } }

.archive_casinos__single_item_terms {
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  margin-top: 1em; }

.archive_casinos__single_item_bonus_value {
  font-weight: 700;
  font-size: 16px; }

.archive_casinos__single_item_bonus_title {
  text-align: center; }

.archive_casinos__single_item_bonus_details {
  display: flex;
  align-items: center;
  gap: 20px; }
  .archive_casinos__single_item_bonus_details img {
    height: fit-content; }

.archive_casinos__single_item_details_features {
  border-radius: 20px;
  background-color: #f9f6fd;
  flex: 1 1 50%;
  width: 100%; }
  .archive_casinos__single_item_details_features .nav-tab-wrapper {
    padding: 12px 20px;
    background-color: #eee6f9;
    border-radius: 20px 20px 0 0;
    overflow: auto; }
    .archive_casinos__single_item_details_features .nav-tab-wrapper_center {
      display: flex;
      margin: 0 auto;
      width: fit-content; }
  .archive_casinos__single_item_details_features .nav-tab.nav-tab-active {
    border: none; }
  .archive_casinos__single_item_details_features .nav-tab:last-child {
    margin-right: 0; }
  .archive_casinos__single_item_details_features .nav-tab {
    font-size: 16px;
    font-weight: 500;
    padding: 0;
    margin-right: 7px; }
    @media (max-width: 800px) {
      .archive_casinos__single_item_details_features .nav-tab {
        font-size: 12px;
        font-weight: 700; } }
  .archive_casinos__single_item_details_features .tab-target {
    margin: 25px 30px; }
    @media (max-width: 800px) {
      .archive_casinos__single_item_details_features .tab-target {
        margin: 25px 15px; } }

.archive_casinos__single_item_details_total {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around; }
  @media (max-width: 800px) {
    .archive_casinos__single_item_details_total {
      gap: 20px; } }
  .archive_casinos__single_item_details_total li {
    list-style: none;
    line-height: 36px;
    font-size: 12px; }
  .archive_casinos__single_item_details_total div:first-child {
    text-align: center; }
    .archive_casinos__single_item_details_total div:first-child p {
      font-size: 40px;
      font-weight: 600;
      justify-content: center;
      color: #864cd7; }
  .providers_list .archive_casinos__single_item_details_total div:first-child,
  .game_types .archive_casinos__single_item_details_total div:first-child {
    flex: 1 1 25%;
    align-items: center; }
    @media (max-width: 800px) {
      .providers_list .archive_casinos__single_item_details_total div:first-child,
      .game_types .archive_casinos__single_item_details_total div:first-child {
        flex: 1 1 100%; } }
  .providers_list .archive_casinos__single_item_details_total div:last-child,
  .game_types .archive_casinos__single_item_details_total div:last-child {
    flex: 1 1 75%;
    align-items: center;
    justify-content: center; }
    @media (max-width: 800px) {
      .providers_list .archive_casinos__single_item_details_total div:last-child,
      .game_types .archive_casinos__single_item_details_total div:last-child {
        flex: 1 1 100%; } }
  .archive_casinos__single_item_details_total_content, .archive_casinos__single_item_details_total_number {
    flex: 1 1 50%;
    align-items: center; }
    @media (max-width: 800px) {
      .archive_casinos__single_item_details_total_content, .archive_casinos__single_item_details_total_number {
        flex: 1 1 100%; } }
  @media (max-width: 800px) {
    .archive_casinos__single_item_details_total_content ul {
      display: flex;
      justify-content: center;
      gap: 8px; }
    .archive_casinos__single_item_details_total_content li {
      display: flex;
      flex-direction: column;
      font-family: Inter;
      font-size: 10px;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0em;
      text-align: center;
      margin-right: 8px; } }
  div .archive_casinos__single_item_details_total_logos {
    flex: 2 1 100%;
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
    justify-content: center; }
    div .archive_casinos__single_item_details_total_logos img {
      width: 70px; }
  .archive_casinos__single_item_details_total_icon_list, .archive_casinos__single_item_details_total_logos {
    gap: 15px;
    margin-top: 20px; }
  .archive_casinos__single_item_details_total_icon_list {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start; }
    .providers_list .archive_casinos__single_item_details_total_icon_list div {
      max-width: 80px; }
      .providers_list .archive_casinos__single_item_details_total_icon_list div img {
        max-height: 35px;
        max-width: 80px; }
    .archive_casinos__single_item_details_total_icon_list div {
      text-align: center;
      max-width: 40px;
      font-size: 12px; }
      .archive_casinos__single_item_details_total_icon_list div img {
        max-height: 35px;
        max-width: 35px; }

.archive_casinos__single_item_more_button {
  text-align: center;
  display: block;
  padding: 30px;
  cursor: pointer;
  width: 100%;
  flex: 2 1 100%; }
  .archive_casinos__single_item_more_button svg {
    transform: scale(1, -1);
    transition: all 0.5s ease-in-out; }
  .expand .archive_casinos__single_item_more_button svg {
    transform: scale(1, 1);
    transition: all 0.5s ease-in-out; }

.archive_casinos .filter_conntent_block-sort select {
  min-width: unset; }

.archive_casinos .filter_conntent_block-sort-list {
  margin-right: 2rem; }

.archive_casinos .filter_conntent_block-form {
  display: flex;
  align-items: center;
  width: 50%; }
  .archive_casinos .filter_conntent_block-form svg {
    margin-right: -25px; }
  .archive_casinos .filter_conntent_block-form input {
    background: none;
    border-radius: 30px;
    font-size: 16px;
    font-weight: 600;
    color: #292929;
    border: solid 1px #d9d9d9;
    height: 31px;
    width: 100%;
    padding-left: 25px;
    padding-right: 150px; }
    .archive_casinos .filter_conntent_block-form input::placeholder {
      color: #d9d9d9; }
    .archive_casinos .filter_conntent_block-form input:focus {
      color: #292929; }
  .archive_casinos .filter_conntent_block-form-button {
    margin-left: -100px; }
    .archive_casinos .filter_conntent_block-form-button button {
      background-color: #8354D4;
      border: none;
      width: 150px;
      height: 30px;
      border-radius: 0 15px 15px 0;
      color: white;
      cursor: pointer; }

.lotteries .archive_casinos__single_item_head {
  justify-content: space-around; }
