@import '../vars';
@import '../reset';
@import '../breakpoints';

// This is the stylesheet for the about page
.about{
    &__banner_container{
        display: grid;
        grid-template-columns: 60% 35%;
        padding-top: 30px;
        justify-content: space-between;
        
        @include media ('<=800px'){
            grid-template-columns: 100%;
            padding: 0 20px 0;
        }
    }

    &__banner_left{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        @include media ('<=800px'){
            padding-top: 20px;
            display: grid;
            justify-content: center;
            margin: 0 auto;
        }
    }

    &__banner_img{
        display: grid;
        justify-content: center;
        align-items: center;
        img{
            width: 90%;

            @include media ('<=800px'){
                width: 100%;
                display: none;
            }
        }
    }
    &__banner_heading{
        font-size: 42px;
        font-weight: 700;
        padding: 0;

        @include media ('<=800px'){
            text-align: left;
            font-size: 25px;
            padding: 20px 0 0 0;
        }
    }
    &__banner_subheading{
        font-size: 18px;
        color: #fcc13e;
        font-weight: 700;

        @include media ('<=800px'){
            margin: 0 auto;
        }
    }
    &__banner_paragraph{
        font-weight: 400;
        font-size: 16px;
        padding-bottom: 30px;
        line-height: 30px;

        @include media ('<=800px'){
            text-align: center;
            margin: 0 auto;
        }
    }
    &__banner_left_img{
        @include media ('>=800px'){
            display: none;
        }

        @include media ('<=800px'){
            display: flex;
            margin: 0 auto;
            justify-content: center;
            padding-top: 20px;
            img{
                width: 50%;
            }
        }
    }
    &__shape{
        width: 100%;
        overflow: hidden;
    }
}
.advantages{
    padding: 60px 0;
    text-align: center;
    margin-bottom: 100px;
    margin-top: 50px;

    @include media ('<=800px'){
        margin-bottom: 0;
    }
    &__title h3{
        font-family: Inter;
        font-weight: 700;
        font-size: 25px;
    }
    &__options{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 70px;
        padding: 0 20px;


        @include media ('<=1000px'){
            grid-template-columns: repeat(2, 50%);
            gap: 20px;
        }
        @include media ('<=500px'){
            grid-template-columns: 100%;
            gap: 20px;
        }
        &_item{
            margin-top: 20px;
            & img{
                height: 110px;
            }
            &_title{
                text-transform: uppercase;
                font-weight: 600;
                font-size: 20px;
                margin: 15px;
            }
            &_text{
                font-weight: 400;
                font-size: 16px;
                text-align: center;

                @include media ('<=500px'){
                    max-width: 80%;
                    margin: 0 auto;
                }
            }
        }
    }
}
.who_block{
    display: grid;
    grid-template-columns: 60% 40%;
    margin-bottom: 40px;

    @include media ('<=800px'){
        grid-template-columns: 100%;
        margin-top: 60px;
        position: relative;
    }
    &__text_container{
        max-width: 60%;
        margin: 0 auto;
        padding: 70px 0;

        @include media('<=800px'){
            padding: 0 0 30px;
            max-width: 90%;
        }
    }
    &__left{
        background-color: #F4EFFB;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        text-align: left;
        @include media('<=800px'){
            background-color: #fff;
        }
        &_title{
            font-size: 25px;
            font-weight: 700;
            position: absolute;
            top: -50px;
            left: 50%;
            transform: translate(-50%, 0);

            @include media('<=800px'){
                font-size: 20px;
            }
        }
        &_text {
            margin-top: 40px;
            font-size: 16px;
            font-weight: 400;
            line-height: 27px;
        }
    }
    &__right{
        background-color: $primary-background-color;
        display: flex;
        justify-content: center;
        align-items: center;

        @include media ('<=800px'){
            width: 95%;
            margin: 10px auto;
            border-radius: 12px;
            order: -10;
        }
    }
    &__right_img{
        width: 100%;
        img{
            width: 80%;
            @include media ('<=800px'){
                width: 60%;
                margin: 0 auto;
                display: flex;
            }
        }
    }
}

.users_reviews{
    text-align: center;
    width: 100%;
    
    @include media ('<=480px'){
        background-color: #864CD7;
    }
    &__title{
        & h3 {
            font-family: Inter;
            font-size: 25px;
            font-weight: 700;
            line-height: 31px;
            letter-spacing: 0px;
            text-align: center;
        }
        
        @include media ('<=480px'){
            color: #fff;
            font-weight: 500;
        }
    }
    & .swiper{
        &-pagination{
            &-bullet{
                width: 13px;
                height: 13px;
                background: #864CD7C0;
                @include media ('<=800px'){
                    background: #ffffffc0;
                }
                &-active{
                    width: 21px;
                    height: 21px;
                    border: 6px solid #8354d4;
                    background: #864CD700;
                    box-shadow: none;
                    @include media ('<=800px'){
                        background: #ffffff00;
                        border: 6px solid rgba(255, 255, 255, 0.5);
                    }
                }
            }
        }
    }
    &__single{
        background-color: #fff;
        width: 90%;
        box-shadow: 1px 1px 16px rgba(128, 128, 128, 0.277);
        border-radius: 20px;
        margin: 60px 10px;

        @include media ('<=480px'){
            width: 100%;
            margin: 60px 40px;
        }
    }
    &__single_img{
        width: 120px;
        margin-top: -50px;
        margin-bottom: 10px;
    }
    &__single_heading{
        font-size: 20px;
        font-weight: 700;
    }
    &__single_subheading{
        font-size: 15px;
        color: #737B7D;
        font-weight: 500;
        padding: 10px 0;
    }
    &__single_paragraph{
        max-width: 80%;
        text-align: center;
        margin: 0 auto;
        padding: 0 0 30px;
        font-family: Inter;
        font-size: 16px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0px;
        @include media ('<=480px'){
            font-size: 14px;
        }
    }
    &__about_slider_container{
        display: flex;
        align-items: center;
    }
    &__shape_upper{
        background-color: #fff;
        clip-path: ellipse(85% 50% to 50% 50%);
        height: 40px;
        padding: 40px;
        clip-path: ellipse(85% 50% at 50% 20%);
        border: 1px solid #fff;
        margin-top: -10px;
    }
    &__shape_lower{
        background-color: #fff;
        clip-path: ellipse(25% 50% to 50% 50%);
        height: 40px;
        padding: 40px;
        clip-path: ellipse(75% 50% at 50% 120%);
        border: 1px solid #fff;
    }
}

.responsible_gaming{
    display: grid;
    grid-template-columns: repeat(2,48%);
    margin: 0 auto;
    padding: 0 20px;

    @include media ('<=800px'){
        grid-template-columns: 100%;
        margin: 0 auto;
    }
    &__left{
        padding: 20px;
        text-align: center;

        @include media ('<=800px'){
            display: flex;
            flex-wrap: wrap;
            justify-content: space-evenly;
            order: 1;

            & img{
                flex: 0 0 40%;
                width: 40%;
            }
        }
    }
    &__right{
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        &_title{
            font-size: 25px;
            font-weight: 700;
            padding-bottom: 30px;

            @include media ('<=800px'){
                font-size: 20px;
                text-align: center;
            }
        }
        &_text {
            font-size: 16px;
            font-weight: 400;
            line-height: 27px;
        }
    }
}
.values{
    padding: 60px 20px;
    text-align: center;
    margin-bottom: 40px;

    @include media ('<=800px'){
        margin-top: 0;
        padding: 30px 20px;
    }
    &__title h3{
        font-family: Inter;
        font-weight: 700;
        font-size: 25px;
        margin-bottom: 40px;

        @include media ('<=800px'){
            margin-bottom: 0;
            font-size: 20px;
        }
    }
    &__options{
        display: grid;
        grid-template-columns: repeat(2, 45%);
        justify-content: center;
        gap: 40px;

        @include media ('<=800px'){
            grid-template-columns: 100%;
            gap: 0;
        }
        &_item{
            box-shadow: #00000015 5px 5px 15px 0;
            border-radius: 10px;
            padding: 55px;
            margin-top: 10px;
            margin: 0 auto;

            @include media ('<=800px'){
                box-shadow: none;
                padding: 30px 0;
            }
            & img{
                height: 110px;
            }
            &_title{
                text-transform: uppercase;
                font-weight: 600;
                font-size: 20px;
                margin: 15px;

                @include media ('<=800px'){
                    font-size: 16px;
                    font-weight: 700;
                }
            }
            &_text{
                font-weight: 400;
                font-size: 16px;
            }
        }
    }
}

.our_contacts{
    & .inner{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    &__banner{
        background-image: url('../../img/sitemap/posts/Background-Posts-Desktop.png');
        background-position: bottom;
        padding: 20px 0 70px;
        background-size: cover;
        background-repeat: no-repeat;
        color: #fff;
        @include media ('<=800px'){
            padding-bottom: 15px;
        }
    }
    &__container{
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2,50%);

        @include media ('<=800px'){
            grid-template-columns: 100%;
        }
    }
    &__img_left{
        position: absolute;
        margin-top: -62px;
        margin-left: 343px;

        @include media ('<=800px'){
            display: none;
        }
    }

    &__img_right{
        transform: rotate(-36deg) scale(.5,.5);
        position: absolute;
        margin-top: -44px;
        margin-left: -154px;

        @include media ('<=800px'){
            display: none;
        }
    }
}
.terms_block{
    & .main_block{
        margin-top: 40px;
    }
    &__content{
        
        @include media ('<=800px'){
            margin: 0 10px;
        }
        & h3{
            padding: 15px 0 20px;
        }
        padding: 35px 60px;
        border-radius: 15px;
        box-shadow: #0000003F 2px 2px 15px 0;
        line-height: 25px;
        & li{
            margin-bottom: 10px;
        }
    }
    & .default_repeater{
        padding: 35px 60px;
        border-radius: 15px;
        box-shadow: #0000003F 2px 2px 15px 0;
        
        @include media ('<=800px'){
            margin: 30px 10px;
            padding: 35px 30px;
        }
        &__options{
            grid-template-columns: repeat(2,1fr);
            grid-gap: 30px;

            @include media ('<=800px'){
                grid-template-columns: 100%;
            }
            &_item:nth-child(2){
                text-align: center;
                background: #FDEDC5;
                border-radius: 15px;
                & div{
                    padding-top: 20px;
                }
                & .default_repeater__options_item_title{
                    margin: 0;
                    padding: 10px;
                    text-align: center;
                    color: $primary-background-color;
                    background: $secondary-background-color;
                    border-radius: 15px 15px 0 0;
                }
            }
            &_item{
                margin-top: 0;
                text-align: left;
                &_title{
                    text-align: left;
                    text-transform: unset;
                    font-size: 18px;
                    margin-bottom: 20px;
                }
            }
        }
    }
}

.responsible_gambling{
    & .main_block{
        padding: 40px 0 50px;
    }

    &__content{
        padding: 35px 60px;
        border-radius: 15px;
        box-shadow: #0000003F 2px 2px 15px 0;
        line-height: 25px;
        & h3{
            font-size: 20px;
        }
        & li {
            padding: 15px 0 20px;
        }

    }
}
.cookie_policy{
    & .main_block{
        padding: 40px 0 50px;
    }
    &__content{
        padding: 35px 60px;
        border-radius: 15px;
        box-shadow: #0000003F 2px 2px 15px 0;
        line-height: 25px;
        & p{
            margin: 20px 0 15px;
        }
        & strong {
            font-size: 20px;
            padding: 15px 0 20px;
        }

    }
}

.flo_import{
    input[type=file] {
        display: block;
    }
}

.users_reviews__about_slider_container{
    display: flex;
    align-items: center;
}