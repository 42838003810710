@import '../vars';
@import '../reset';
@import '../breakpoints';

// Styling sheet for single casinos and related casinos found at /single-casinos.php

.top_pick{
  margin: 100px auto;

  @include media ('<=800px'){
    margin: 10px auto;
  }
  &__title{
    font-family: Inter;
    line-height: 50px;
    margin: 50px auto 30px;
    & h2,
    & h1{
      font-size: 40px;
      font-weight: 700;

      @include media ('<=800px'){
        font-size: 30px;
        line-height: 35px;
      }
    }
    & h3{
      font-size: 22px;
      font-weight: 600;
    }
  }
  &__description,
  &__description p{
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 27px;
    margin: 20px auto;
  }

  & .nav-tab-wrapper{
    margin: 20px auto;
    display: flex;
    justify-content: center;
    gap: 128px;
    & .nav-tab{
      font-family: Inter;
      font-size: 14px;
      font-weight: 500;
      display: block;
      color: $paragraph-text-color;
      background: $light-background-vary-light;
      width: fit-content;
      border-radius: 20px;
      border: none;
      &.nav-tab-active{
        color: $tetiary-background-color;
        background: $primary-background-color;
      }
    }
  }
  .tab-target{
    height: 270px;
    justify-content: space-around;
  }

}

.best_of_best_block{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 60px;
  margin-bottom: 30px;

  @include media ('<=800px'){
    grid-template-columns: 100%;
  }
  &.bottom_offset{
    padding-bottom: 210px;
  }
  &__left{
    @include media ('<=800px'){
      padding: 0 20px;
    }
  }
  &__right{
    padding-bottom: 50px;
  }
  & .archive_casinos{
    &__single_item_terms{
      font-family: Inter;
      font-size: 10px;
      font-weight: 300;
      line-height: 15px;
      letter-spacing: 0em;
    }
    &__text{
      margin: 15px auto;
    }
    &__engage_play{
      padding: 11px 130px;
    }
    &__single_item_inner{
      padding: 45px 40px 20px;
    }
    &__single_item_head{
      align-items: center;
      flex-direction: column;
    }
    &__image img{
      max-height: 90px;
      width: auto;
    }
    &__info{
      width: 246;
      height: 95px;
      padding-top: unset;
    }
    &__casinos_item_block{

    }
  }

  &.blue_inner.screen_offset{
    & .nav-tab.nav-tab-active{
      color: $tetiary-background-color;
      background: $primary-background-color;
    }
  }
  &.blue_inner{
    & .best_of_best_block__right > *{
      color: $tetiary-background-color;
    }
    & .nav-tab-wrapper{
      gap: 20px;
    }
    & .nav-tab.nav-tab-active{
      color: $paragraph-text-color;
      background: $tetiary-background-color;
    }
    display: flex;
    & .tab-target.tab-target-active{
      display: block;
    }
    & .category_screenshots .tab-target{
      height: 400px;
      max-width: 480px;
      text-align: end;
      & img:nth-child(1){
        z-index: 2;
        position: inherit;
        max-width: 440px;
      }
      & img:nth-child(2){
        top: -30px;
        width: 249px;
        position: relative;
      }
    }
  }
  &.screen_offset{
    flex-direction: column;
    div &.blue_inner .category_screenshots .tab-target.tab-target-active{
      display: flex;
      height: 270px;
      max-width: unset;
      & > img{
        position: relative;
        max-width: unset;
        top: unset;
        width: unset;
      }
    }
    & .category_screenshots{
      position: relative;
      height: 140px;
      top: -221px;
    }
    & .tab-target{
      height: 270px;
      justify-content: space-around;
    }
  }
}



.author-review{
  background: $primary-background-color;
  & .hot-sites__content {
    @include media ('<=800px'){
      display: grid;
      grid-template-columns: 100%;
    }
  }
  & .hot-sites__content_item{
    box-shadow: none;
  }
  &__inner{
    padding: 20px 10px 40px;
  }
  &__shape{
    width: 100%;
    overflow-x: hidden;
  }
  &__shape-bottom{
    transform: scale(1.05,1);
  }
  &__shape-bottom,
  &__shape-top{
    background-color: white;
    width: 100%;
    height: 50px;
    border-radius: 50% / 75%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border: 1px solid white;
  }
  &__shape-top{
    transform: scale(1.05,-1);
  }
  &__header-title{
    color: $tetiary-color;
    font-weight: 800;
    font-size: 30px;
    margin: 70px 0 50px;
  }
  &__header-author{
    display: flex;
    &-data{
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      & > *{
        font-size: 16px;
        color: $tetiary-color;
      }
    }
    & img{
      border-radius: 45px;
      border: 4px solid $light-background-color;
      max-width: 68px;
      margin-right: 10px;
    }
  }
  &__best-bonus-title{
    font-size: 22px;
    font-weight: 700;
    color: $tetiary-color;
    padding-bottom: 15px;
  }
  &__author-articles{
    background-color: $light-background-color;
    border-radius: 5px;
    padding: 60px 128px;

    @include media ('<=800px'){
      padding: 20px 5px;
    }
    &-block{
      display: flex;
      flex-direction: column;
    }
  }
  &__content_item{
    background-color: $tetiary-color;
    border-radius: 5px;
    margin-bottom: 15px;
    display: flex;
    padding: 21px 85px;
    align-items: center;
    justify-content: space-between;

    @include media ('<=800px'){
      padding: 21px 25px;
    }
    &_welcome_offer{
      font-weight: bold;
    }
    & div{
      font-size: 20px;
    }
    & img{
      height: 75px;
    }
  }
  &__author-articles-title{
    display: flex;
    justify-content: space-around;
    padding: 15px;
    & span{
      font-weight: 700;
      font-size: 20px;
      color: $tetiary-color;
    }
  }
  &__content_item_img{
    display: flex;
    flex-direction: column;
    align-items: center;
    & a{
      text-decoration: underline;
      color: $accent-text-color;
      font-size: 14px;
      padding: 5px;
    }
  }
  &__foreword{
    line-height: 35px;
    & div{
      background-color: #a87ee2;
      border-radius: 5px;
      padding: 60px 128px;

      @include media ('<=800px'){
        padding: 20px 0;
      }
      & table{
        width: 100%;
        background: none;
        & th{
          font-weight: 700;
          font-size: 20px;
          color: #fff;
          text-align: center;
        }
        tr:first-child{
          background: none;
          border: none;
          height: 50px;
          & td{
            font-weight: 700;
          }
        }
        & tr{
          background-color: #fff;
          height: 150px;
          & td{
            border-top: 5px solid #a87ee2;
            border-bottom: 5px solid #a87ee2;
            color: $paragraph-text-color;
            font-size: 20px;
            text-align: center;
          }
          & td:first-child {
            border-left: 5px solid #a87ee2;
            border-bottom-left-radius: 20px;
            border-top-left-radius: 20px;
            & img{
              height: 80px;
            }
            & a{
              text-decoration: underline;
              color: #585858;
              font-size: 14px;
              padding: 5px;
            }
          }
          & td:last-child {
            border-right: 5px solid #a87ee2;
            border-bottom-right-radius: 20px;
            border-top-right-radius: 20px;
            font-weight: 700;
          }
        }
      }
    }
    & br{
      content: "";
      margin: 3em;
      display: block;
      font-size: 24%;
    }
    & a{
      color: $secondary-background-color;
      text-decoration: underline;
    }
    & li{
      margin-left: 20px;
      margin-bottom: 10px;
    }
    & h2.heading{
      font-weight: 800;
      font-size: 30px;
    }
    & h2,
    & h3{
      margin: 20px 0 15px;
    }
  }
  &__foreword,
  &__afterword,
  &__final{
    margin-top: 30px;
    &,
    & p{
      font-size: 16px;
      color: $tetiary-color;
      margin-bottom: 45px;
    }
  }

}
