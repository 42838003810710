@import '../vars';
@import '../reset';
@import '../breakpoints';

// Styling sheet for single casinos and related casinos found at /single-casinos.php

.single_games {
  overflow: hidden;
  & .about__shape {
    @include media('<=800px') {
      display: none;
    }
  }
  &__banner {
    background-color: $primary-background-color;
    background-image: url('/wp-content/uploads/2022/09/game_back.png');
    background-repeat: repeat;
    background-size: 58%;

    @include media('<=800px') {
      display: none;
    }

    &_info {
      @include media('<=800px') {
        margin: 0 auto;
        text-align: center;

        h1 {
          text-align: center;
          font-size: 22px;
        }
      }
      & .banner_btn_play_now {
        @include media('<=800px') {
          margin: 0 auto;
        }
      }
    }

    &_introduction {
      & a{
        text-decoration: underline;
        color: $secondary-background-color;
        font-size: 14px;
        padding: 5px;
      }
      @include media('<=800px') {
        text-align: center;
      }
    }

    &_screenshots {
      display: flex;
      position: relative;
      z-index: 5;
      top: 275px;
      flex-direction: column;
      & h3 {
        font-family: Inter;
        font-size: 22px;
        font-weight: 700;
        line-height: 31px;
        margin-bottom: 20px;
      }
      & img {
        max-width: 190px;
        border-radius: 10px;

        @include media('<=600px') {
          width: 250px;
        }
      }
      &_no_screens {
        display: flex;
        overflow-x: auto;
        align-items: center;
        gap: 20px;
        box-shadow: 0 3px 8px rgb(221, 221, 221);
        border-radius: 15px;
        padding: 35px 40px 35px 20px;
        font-family: Inter;
        font-size: 18px;
        font-weight: 400;
      }
      &_box {
        @include media('<=800px') {
          display: flex;
          max-width: 380px;
          overflow-x: auto;
          margin: 0 auto;

          &::-webkit-scrollbar-thumb {
            background-color: #925edb;
            border-radius: 10px;
          }
          &::-webkit-scrollbar {
            width: 10px;
            height: 5px;
          }
          &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 5px #925edb;
            border-radius: 10px;
          }
          &::-webkit-scrollbar-thumb:hover {
            background: #925edb;
          }
        }
      }
    }

    &_game_param {
      display: flex;
      gap: 20px;
      margin-top: 30px;
      margin-bottom: -248px;
      z-index: 5;
      position: relative;

      @include media('<=800px') {
        display: grid;
        grid-template-columns: 100%;
        justify-content: center;
        align-items: center;
        align-content: center;
      }
      &_item {
        width: 280px;
        height: 124px;
        display: flex;
        border-radius: 20px;
        background: $tetiary-background-color;
        padding: 25px;
        box-shadow: 0 3px 8px rgb(221, 221, 221);

        @include media('<=800px') {
          display: grid;
          text-align: center;
          width: unset;
          height: unset;
        }
        &_text {
          padding: 5px 0 0 15px;
          font-family: Inter;
          font-size: 20px;
          font-weight: 600;
        }
      }
    }

    &_prov {
      display: flex;

      @include media('<=800px') {
        margin: 0 auto;
        text-align: center;
        justify-content: center;
        gap: 15px;
      }
      gap: 20px;
      &_provider,
      &_difficulty {
        display: flex;
        width: auto;
        height: 36px;
        background: $light-background-color;
        border-radius: 15px;
        justify-content: space-evenly;
        align-items: center;
        padding: 0 12px;
        gap: 5px;
        & img {
          max-height: 25px;
        }
        &_type {
          color: $secondary-background-color;
          font-weight: 600;
        }
      }
    }
  }
  &__banner_box {
    display: grid;
    grid-template-columns: 65% 30%;
    gap: 0 40px;
    align-items: center;

    @include media('<=800px') {
      grid-template-columns: 100%;
    }
  }
  &__banner_info_introduction {
    font-family: Inter;
    font-size: 18px;
    font-weight: 400;
  }
  &__banner {
    & .breadcrumbs,
    &_title,
    &_prov,
    &_introduction {
      color: $tetiary-background-color;
      padding-bottom: 30px;
    }
    & .breadcrumbs {
      @include media('<=800px') {
        display: none;
      }
    }
    & .banner_btn_play_now {
      width: 240px;
      text-align: center;
      font-family: Mulish;
      font-size: 18px;
      font-weight: 800;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  &__banner_title {
    font-family: Inter;
    font-size: 42px;
    font-weight: 700;
  }
  &__banner_subheading {
    font-family: Inter;
    font-size: 26px;
    font-weight: 700;
  }
  &__banner_image {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    display: block;
    background: white;
    min-width: 330px;
    z-index: 5;
    margin-bottom: -190px;
    border-radius: 30px;
    box-shadow: 0 3px 8px rgb(221, 221, 221);

    @include media('<=800px') {
      order: -1;
      margin: 20px 0;
    }
  }
  &__banner_thumb {
    border-radius: 20px;
    width: 100%;
  }
  &__banner_terms_condition {
    font-size: 14px;
  }
  &__banner_game_info {
    &_header {
      min-width: 330px;
      background: $paragraph-text-color;
      color: $tetiary-background-color;
      padding: 10px;
      font-family: Inter;
      font-size: 18px;
      font-weight: 700;
      & i {
        color: $secondary-background-color;
        margin-right: 5px;
      }
    }
    &_box {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      padding: 10px 20px;
      &:nth-child(even) {
        background: $tetiary-background-color;
      }
      &:last-child {
        border-radius: 0 0 30px 30px;
      }
      &_title {
        color: $paragraph-text-color;
        font-family: Inter;
        font-size: 16px;
        font-weight: 600;
        text-align: left;
      }
      &_val {
        color: $paragraph-text-color;
        font-family: Inter;
        font-size: 16px;
        font-weight: 400;
        text-align: left;
      }
    }
  }

  &__mobile_banner_introduction {
    text-align: center;
    line-height: 27px;
    padding: 0 20px 20px;
    font-size: 18px;
  }

  &__view_mobile {
    @include media('>=800px') {
      display: none;
    }
    &_desc {
      & .single_games__banner_screenshots {
        position: initial;
        margin: 40px auto;
        width: 90%;
        & h3 {
          text-align: center;
        }
        &_box {
          & img {
            min-width: 274px;
          }
        }
      }
    }
    & .games_show_more {
      position: absolute;
      bottom: -40px;
      left: 50%;
      transform: translate(-50%, 0);
      display: flex;
      align-items: center;
      gap: 5px;
    }
  }

  &__view_mobile_banner {
    display: flex;
    justify-content: center;
    padding: 30px 0;
  }

  &__view_mobile_desc_btns {
    padding-top: 20px;
  }
  &__mobile_game_param {
    padding: 12px 0;
  }
  &__mobile_banner_info {
    position: relative;
    margin-top: 70px;
  }
  &__mobile_banner_game_param_item_text {
    display: flex;
    font-size: 16px;
  }

  &__mobile_banner_game_param_item {
    display: flex;
    justify-content: center;
    gap: 15px;
    align-items: center;
    align-content: center;
    width: 90%;
    background-color: #fff;
    box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.095);
    padding: 10px 0;
    margin: 0 auto 15px;
    border-radius: 20px;
  }

  &__mobile_banner_game_info {
    width: 90%;
    margin: 10px auto;
  }

  &__mobile_banner_game_info_header {
    background-color: $primary-background-color;
    padding: 18px 10px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    text-align: center;
    color: #fff;
    font-size: 18px;

    i {
      color: #fff;
      font-size: 20px;
      margin-right: 10px;
    }
  }

  &__mobile_quick_verdict {
    width: 90%;
    margin: 10px auto;
    box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.095);
    text-align: center;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 20px;
  }

  &__mobile_quick_icon {
    img {
      width: 50px;
      height: 50px;
    }
  }

  &__mobile_our_verdict_score_group_text {
    h4 {
      font-size: 20px;
      font-weight: 600;
      padding-top: 10px;
    }

    p {
      font-size: 18px;
      line-height: 30px;
    }
  }

  &__similar_games {
    display: flex;
    justify-content: space-between;
    padding: 20px 5px;

    @include media('<=800px') {
      display: flex;
      min-width: 390px;
      overflow: auto;
    }
  }
}

.game_verdict {
  @include media('<=800px') {
    display: none;
  }
  margin-top: 270px;
  box-shadow: 0 3px 8px rgb(221, 221, 221);
  border-radius: 15px;
  & h4 {
    font-family: Inter;
    font-size: 22px;
    font-weight: 700;
    padding: 20px 0;
  }
  & p {
    font-family: Inter;
    font-size: 20px;
    font-weight: 400;
  }
  & .single_casino__our_verdict_score_group .scoreCircle {
    width: 200px;
  }

  .single_casino__our_verdict_score_group {
    padding: 30px;
    display: grid;
    grid-template-columns: 100%;
    text-align: center;
  }
}

.casino_offers {
  margin-top: 300px;
  @include media('<=800px') {
    margin-top: 0;
  }
  &__title {
    margin: 50px 0;
    & h4 {
      font-family: Inter;
      font-size: 22px;
      font-weight: 700;
      padding: 20px 0;
      text-align: center;
    }
    & p {
      font-family: Inter;
      font-size: 16px;
      font-weight: 400;
      line-height: 32px;
      text-align: center;
      @include media('<=800px') {
        display: none;
      }
    }
  }
  &__feed {
    & .ad_disclosure {
      padding-bottom: 15px;
      @include media('<=800px') {
        display: none;
      }
    }
  }
}

.game_review {
  background: $primary-background-color;
  & .inner {
    @include media('<=800px') {
      width: 90%;
    }
  }
  &__inner {
    padding: 20px 0 40px;
  }
  &__shape-bottom {
    transform: scale(1.05, 1);
  }
  &__shape-bottom,
  &__shape-top {
    background-color: white;
    width: 100%;
    height: 50px;
    border-radius: 50% / 75%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border: 1px solid white;
  }
  &__shape-top {
    transform: scale(1.05, -1);
  }
  &__header-title {
    color: $tetiary-color;
    font-weight: 800;
    font-size: 30px;
    margin: 70px 0 50px;
  }
  &__header-author {
    display: flex;
    &-data {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      & > * {
        font-size: 16px;
        color: $tetiary-color;
      }
    }
    & img {
      border-radius: 45px;
      border: 4px solid $light-background-color;
      max-width: 68px;
      margin-right: 10px;
    }
  }

  &__content_item {
    background-color: $tetiary-color;
    border-radius: 5px;
    margin-bottom: 15px;
    display: flex;
    padding: 21px 85px;
    align-items: center;
    justify-content: space-between;
    &_welcome_offer {
      font-weight: bold;
    }
    & div {
      font-size: 20px;
    }
    & img {
      height: 75px;
    }
  }
  &__author-articles-title {
    display: flex;
    justify-content: space-around;
    padding: 15px;
    & span {
      font-weight: 700;
      font-size: 20px;
      color: $tetiary-color;
    }
  }
  &__content_item_img {
    display: flex;
    flex-direction: column;
    align-items: center;
    & a {
      text-decoration: underline;
      color: $accent-text-color;
      font-size: 14px;
      padding: 5px;
    }
  }
  &__foreword {
    & img {
      width: 100%;
    }
    & div {
      background-color: #a87ee2;
      border-radius: 5px;
      padding: 60px 128px;
      & table {
        width: 100%;
        background: none;
        & th {
          font-weight: 700;
          font-size: 20px;
          color: #fff;
          text-align: center;
        }
        tr:first-child {
          background: none;
          border: none;
          height: 50px;
          & td {
            font-weight: 700;
          }
        }
        & tr {
          background-color: #fff;
          height: 150px;
          & td {
            border-top: 5px solid #a87ee2;
            border-bottom: 5px solid #a87ee2;
            color: $paragraph-text-color;
            font-size: 20px;
            text-align: center;
          }
          & td:first-child {
            border-left: 5px solid #a87ee2;
            border-bottom-left-radius: 20px;
            border-top-left-radius: 20px;
            & img {
              height: 80px;
            }
            & a {
              text-decoration: underline;
              color: #585858;
              font-size: 14px;
              padding: 5px;
            }
          }
          & td:last-child {
            border-right: 5px solid #a87ee2;
            border-bottom-right-radius: 20px;
            border-top-right-radius: 20px;
            font-weight: 700;
          }
        }
      }
    }
    & a {
      color: $secondary-background-color;
      text-decoration: underline;
    }
    & li {
      margin-left: 20px;
      margin-bottom: 10px;
    }
    & h2.heading {
      font-weight: 800;
      font-size: 30px;
    }
    & h2,
    & h3 {
      margin: 20px 0 15px;
    }
  }
  &__foreword,
  &__afterword,
  &__final {
    & h3 {
      font-family: Inter;
      font-size: 22px;
      font-weight: 700;
      line-height: 50px;
    }
    margin-top: 30px;
    &,
    & p {
      font-family: Inter;
      font-size: 16px;
      font-weight: 400;
      line-height: 27px;
      color: $tetiary-color;
      margin: 15px 0;
    }
  }
}

.simmilar_games {
  margin: 70px 0;
  & h3 {
    font-family: Inter;
    font-size: 30px;
    font-weight: 700;
    line-height: 31px;
    margin-bottom: 30px;
    @include media('<=800px') {
      text-align: center;
      font-size: 22px;
    }
  }
}

.from_provider {
  margin: 70px 0;
  & h3 {
    font-family: Inter;
    font-size: 30px;
    font-weight: 700;
    line-height: 31px;
    margin-bottom: 30px;
    @include media('<=800px') {
      text-align: center;
      font-size: 22px;
    }
  }
}
