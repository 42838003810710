@import '../vars';
@import '../reset';
@import '../breakpoints';


.lotteries{
  & .single_casino__our_verdict {
    margin-top: 30px;
    &_title {
      font-family: Inter;
      font-size: 30px;
      font-weight: 700;
      line-height: 31px;
      text-align: center;
      margin: 35px auto;
    }

    &_container {
      font-family: Inter;
      font-size: 18px;
      font-weight: 400;
      line-height: 29px;
      text-align: left;
      & p {
        font-family: Inter;
        font-size: 18px;
        font-weight: 400;
        line-height: 29px;
        text-align: left;
      }
      & h3 {
        font-family: Inter;
        font-size: 25px;
        font-weight: 700;
        line-height: 31px;
        text-align: left;
        margin: 35px auto;
      }
    }
  }
  & .about__banner_container{
    grid-template-columns: 60% 40%;
  }
  & .filter_conntent_block{
    position: relative;
    max-width: 1100px;
    background: #fff;
    margin: 40px auto;
  }
  .expand .expandable{
    height: fit-content;
    & .archive_casinos__single_item_about{
      overflow: hidden;
    }
  }
  & .filter_conntent_block{
    &-sort select{
      min-width: unset;
    }
    &-sort-list{
      margin-right: 2rem;
    }
    &-form{
      display: flex;
      align-items: center;
      width: 50%;
      & svg{
        margin-right: -25px;
      }
      & input{
        background: none;
        border-radius: 30px;
        font-size: 16px;
        font-weight: 600;
        color: $paragraph-text-color;
        border: solid 1px #D9D9D9;
        height: 31px;
        width: 100%;
        padding-left: 25px;
        padding-right: 150px;
        &::placeholder{
          color: #D9D9D9
        }
        &:focus{
          color: $paragraph-text-color;
        }
      }
      &-button{
        margin-left: -100px;
        & button{
          background-color: $primary-background-color;
          border: none;
          width: 150px;
          height: 30px;
          border-radius: 0 15px 15px 0;
          color: white;
          cursor: pointer;
        }

      }
    }
  }
  & .disclaimer div{
    padding: 10px 10px 30px 10px;
    border-bottom: 1px solid $tetiary-background-color;
  }
  & .author-review{
    background: white;
    &__foreword p:last-child{
      margin-bottom: 0;
    }
    &__foreword{
      margin-bottom: 0;
    }
    &__header-title,
    &__foreword,
    &__foreword p{
      color: $paragraph-text-color;
    }
    &__header-author-data > *{
      color: $paragraph-text-color;
    }
  }
}
