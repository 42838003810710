@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@300;500;600;800&display=swap");
@import url("https://fonts.cdnfonts.com/css/gilroy-bold");
h1, h2 {
  font-family: 'Inter', sans-serif;
  font-weight: 700; }

h3, h4 {
  font-family: 'Inter', sans-serif;
  font-weight: 400; }

p,
a,
button,
input,
select,
option,
ul,
li,
table,
td,
tr,
th,
tbody,
thead,
div,
header,
section,
aside,
figure,
label,
textarea,
::placeholder {
  font-family: 'Inter', sans-serif;
  font-weight: 400; }

span {
  font-family: 'Inter', sans-serif;
  font-weight: 700; }

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none; }

a {
  text-decoration: none; }

ul,
ol,
li {
  margin: 0;
  padding: 0; }

html {
  scroll-behavior: smooth; }

small {
  font-size: inherit; }

/**
*
* Testing
*
**/
input[type="file"] {
  display: none; }

body {
  margin: 0;
  font-size: 14px;
  line-height: 1.42857143;
  color: #333; }

span {
  font-family: Lato,sans-serif;
  font-weight: 400; }

.inner div[class^="single"] .add_to_favorites.added .fa-heart {
  text-shadow: 0 0; }

.inner div[class^="single"] .add_to_favorites .fa-heart {
  font-size: 2.2em;
  text-shadow: -2px 0 #D93C65, 0 2px #D93C65, 2px 0 #D93C65, 0 -2px #D93C65; }

.inner div[class^="single"] .add_to_favorites .loading_ring div {
  width: 33px;
  height: 33px; }

.add_to_favorites {
  position: absolute;
  padding: 18px;
  z-index: 10; }
  .add_to_favorites.added .fa-heart {
    color: #D93C65;
    text-shadow: 0 0; }
  .add_to_favorites .fa-heart {
    margin-top: 2px;
    cursor: pointer;
    color: white;
    font-size: 1.4em;
    text-shadow: -1px 0 #D93C65, 0 1px #D93C65, 1px 0 #D93C65, 0 -1px #D93C65; }
  .add_to_favorites.disabled .loading_ring {
    display: block; }
  .add_to_favorites.disabled .fa-heart {
    display: none; }
  .add_to_favorites .loading_ring {
    display: none; }
  .add_to_favorites .loading_ring div {
    width: 20px;
    height: 20px;
    margin: 0;
    border: 2px solid #bb1216;
    border-color: #bb1216 transparent transparent transparent; }

.load_more_resources {
  display: none; }

.header {
  background-color: #8354D4;
  padding-bottom: 10px; }
  @media (max-width: 800px) {
    .header {
      padding-bottom: 10px; } }
  .header .inner {
    max-width: 1100px; }
  .inner .header__menu ul.sub-menu li .item-top-line {
    display: none; }
  .inner .header__menu a {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px; }
  .inner .header__menu .current_page_item .item-top-line {
    display: block;
    height: 5px;
    border-radius: 0 0 5px 5px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: #FCC13E; }
  .inner .header__menu li {
    padding-top: 10px; }
    .inner .header__menu li:hover .item-top-line {
      display: block;
      height: 5px;
      border-radius: 0 0 5px 5px;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      background: #FCC13E; }
    .inner .header__menu li:hover i:before {
      color: white; }
    .inner .header__menu li i:before {
      color: #9d87f1; }
  .inner .header__menu #menu-header-desktop-menu {
    display: none; }
  .header__container.search_expand .header__menu {
    display: none;
    transition: all ease 0.5s; }
  .header__container.search_expand .header__search-block-form,
  .header__container.search_expand .header__search {
    width: 98%;
    transition: all ease 0.5s; }
  .header__container.search_expand .header__search-block-form input {
    max-width: none;
    width: 98%;
    transition: all ease 0.5s;
    display: flex; }
  .header__search-block-form {
    display: flex;
    align-items: center; }
    @media (max-width: 800px) {
      .header__search-block-form {
        display: none; } }
    .header__search-block-form input {
      background: #a87ee2;
      border-radius: 30px;
      font-size: 16px;
      font-weight: 600;
      border: none;
      color: #f7f7f7;
      height: 31px;
      transition: all ease 0.5s;
      padding-left: 10px;
      display: none; }
      .header__search-block-form input::placeholder {
        color: #c4b9ef; }
      .header__search-block-form input:focus {
        color: #f7f7f7;
        background-color: #a87ee2; }
    .header__search-block-form-button {
      margin-left: -30px; }
      .header__search-block-form-button button {
        background: none;
        border: none; }
      .header__search-block-form-button svg {
        margin-top: 5px;
        color: #f7f7f7; }
  .header__mobile_search {
    padding: 5px 15px 0 0;
    color: #fff;
    font-size: 20px; }
    @media (min-width: 800px) {
      .header__mobile_search {
        display: none; } }
  .header__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 70px; }
    @media (max-width: 800px) {
      .header__container {
        display: grid;
        grid-template-columns: 10% 65% 10% 15%;
        justify-content: center;
        padding-top: 10px; } }
  .header__logo {
    width: 74px; }
    @media (max-width: 800px) {
      .header__logo {
        text-align: center;
        justify-content: center;
        display: flex;
        margin: 0 auto;
        padding: 0 0 0 45px; } }
  @media (max-width: 768px) {
    .header__menu {
      display: none; } }
  .header__menu ul {
    display: flex;
    align-content: center;
    align-items: center; }
    .header__menu ul li {
      right: 10px;
      padding: 0 10px 10px;
      margin: 0;
      min-height: 70px;
      display: flex;
      align-items: center;
      align-content: center; }
      .header__menu ul li .dropdown_icon {
        color: #fff;
        padding-left: 5px;
        display: inline; }
      .header__menu ul li .fa-house:before {
        color: #FCC13E; }
      .header__menu ul li:hover .dropdown_icon i {
        color: #FFFFFF; }
      .header__menu ul li a {
        margin: 0;
        padding: 0; }
  .header__menu ul.sub-menu {
    position: absolute;
    display: flex;
    flex-direction: column;
    z-index: 999;
    width: auto;
    top: 60px;
    left: calc(50% - 84px);
    white-space: nowrap;
    transition: all ease 0.5s;
    display: none;
    padding-top: 10px; }
    .header__menu ul.sub-menu:before {
      font-family: "Font Awesome 6 Free";
      font-weight: 900;
      content: '\f0de';
      color: #a87ee2;
      position: absolute;
      font-size: 45px;
      margin-top: -19px;
      margin-left: 36%; }
    @media (max-width: 1000px) {
      .header__menu ul.sub-menu {
        top: 70px; } }
    .header__menu ul.sub-menu li {
      background-color: #a87ee2;
      min-height: 45px;
      min-width: 160px; }
      .header__menu ul.sub-menu li a {
        color: #f7f7f7;
        width: 100%;
        height: 100%; }
      .header__menu ul.sub-menu li:first-child {
        border-radius: 7px 7px 0 0; }
      .header__menu ul.sub-menu li:last-child {
        border-radius: 0 0 7px 7px; }
      .header__menu ul.sub-menu li:hover {
        background-color: #c4b9ef; }
      .header__menu ul.sub-menu li:hover a {
        color: #FFFFFF; }
  .header__menu li {
    padding: 15px 15px;
    list-style-type: none;
    position: relative;
    display: flex; }
    @media (max-width: 990px) {
      .header__menu li {
        padding: 0 10px; } }
  .header__menu li:hover ul.sub-menu {
    display: block;
    transition: all ease 0.5s; }
  .header__menu a {
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 400;
    display: flex;
    justify-content: space-between; }
    @media (max-width: 990px) {
      .header__menu a {
        font-size: 13px;
        font-weight: 500; } }
    .header__menu a .mi {
      padding-left: 5px; }
  .header__menu a:hover {
    color: #FFFFFF; }
  @media (max-width: 768px) {
    .header__search_link svg {
      width: 30px;
      height: 30px;
      padding-right: 10px; } }
  .header__search {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center; }
    .header__search svg {
      font-size: 32px; }
  .header__search_link {
    cursor: pointer;
    padding: 5px 6px 0px;
    background: #a87ee2;
    margin-right: 10px;
    border-radius: 5px;
    height: 32px;
    width: 32px; }
    @media (max-width: 768px) {
      .header__search_link {
        padding-top: 2px; } }
  .header__mobile_menu {
    z-index: 999;
    position: absolute;
    background-color: #8354d4;
    top: 60px;
    left: -100%;
    margin: 0;
    width: 100%;
    height: 100vh;
    transition: all ease 0.5s;
    overflow: auto;
    box-shadow: -11px 10px 12px dimgray;
    max-height: 99vh;
    overflow-y: auto; }
    .header__mobile_menu > ul {
      position: relative;
      max-height: 100vh;
      overflow-y: auto; }
    .header__mobile_menu ul.sub-menu {
      display: none;
      position: relative;
      overflow: auto;
      padding-top: 10px; }
      .header__mobile_menu ul.sub-menu li {
        width: 100%;
        margin-bottom: 7px;
        padding-left: 0; }
        .header__mobile_menu ul.sub-menu li a {
          font-size: 14px;
          color: #FFFFFF;
          font-weight: 400; }
    .header__mobile_menu ul li {
      padding: 15px 15px;
      list-style-type: none;
      display: block;
      overflow: auto; }
    .header__mobile_menu ul li a {
      font-size: 18px;
      font-weight: 600;
      color: #FFFFFF;
      display: flex;
      justify-content: flex-start;
      gap: 12px; }
      .header__mobile_menu ul li a .new_drop_down.up {
        transform: rotate(180deg); }
    .header__mobile_menu_social {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly; }
      @media (max-width: 800px) {
        .header__mobile_menu_social {
          margin: 20px 10px 10px;
          width: 70%; } }
      .header__mobile_menu_social a {
        display: block;
        width: 40px;
        height: 40px;
        text-align: center;
        border-radius: 50%;
        border: 1px solid #c8bdf1;
        color: #FFFFFF;
        font-size: 20px;
        margin: 0 5px; }
        .header__mobile_menu_social a i {
          margin-top: 10px; }
  .header__mobile_menu.show {
    left: 0;
    transition: all ease 0.5s; }
    @media (min-width: 800px) {
      .header__mobile_menu.show {
        left: -100%; } }
  .header__language {
    display: block; }
    @media (max-width: 800px) {
      .header__language {
        margin-right: 10px; } }
    .header__language-block-form-button-dropdown {
      display: none;
      position: absolute;
      background: #a87ee2;
      border-radius: 10px;
      text-align: right;
      padding: 5px 11px;
      margin-left: -50px;
      z-index: 1000; }
      .header__language-block-form-button-dropdown ul {
        list-style: none; }
        .header__language-block-form-button-dropdown ul li div,
        .header__language-block-form-button-dropdown ul li a {
          height: 25px;
          margin-bottom: 5px;
          display: flex;
          justify-content: right;
          align-items: center; }
          .header__language-block-form-button-dropdown ul li div span,
          .header__language-block-form-button-dropdown ul li a span {
            color: #f7f7f7;
            font-weight: 400;
            margin-right: 5px; }
        .header__language-block-form-button-dropdown ul li.disabled {
          cursor: default; }
          .header__language-block-form-button-dropdown ul li.disabled span {
            color: #cabae0; }
          .header__language-block-form-button-dropdown ul li.disabled img {
            opacity: 0.5; }
    .header__language-block-form i {
      color: #f7f7f7;
      margin-right: 5px;
      font-size: 10px; }
    .header__language-block-form-button {
      cursor: pointer;
      display: flex;
      align-items: center;
      height: 40px; }
    .header__language-block-form img {
      border-radius: 50%; }
    .header__language:hover .header__language-block-form i:before {
      content: "\f0d8"; }
    .header__language:hover .header__language-block-form-button-dropdown {
      display: block; }

.hamburger {
  display: none; }
  @media (max-width: 768px) {
    .hamburger {
      display: block;
      margin-left: 20px; } }
  .hamburger__inner span i {
    font-size: 20px;
    color: #FFFFFF; }
    .hamburger__inner span i.fa-times {
      font-size: 20px; }
  .hamburger__bar {
    background-size: cover;
    padding: 20px;
    background-repeat: no-repeat; }

#menu-header-desktop-menu-1 {
  display: none; }

.show-sub-menu {
  display: block; }

.inner {
  max-width: 1110px;
  margin: 0 auto; }
  .inner .obingo_search_load_more.hide,
  .inner .disabled.obingo_search_load_more {
    display: none; }
  .inner .latest_guides__box_btn {
    width: 100%;
    display: flex;
    justify-content: center; }
    .inner .latest_guides__box_btn a {
      width: 170px;
      cursor: pointer;
      max-height: 34px; }

.disabled .loading_ring {
  display: block; }

.loading_ring div {
  display: none;
  box-sizing: border-box;
  position: absolute;
  width: 40px;
  height: 40px;
  margin: 8px;
  border: 8px solid #7e53dd;
  border-radius: 50%;
  animation: loading_ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #7e53dd transparent transparent transparent;
  transition: all .3s ease-in-out; }

.loading_ring div:nth-child(1) {
  animation-delay: -0.45s; }

.loading_ring div:nth-child(2) {
  animation-delay: -0.3s; }

.loading_ring div:nth-child(3) {
  animation-delay: -0.15s; }

@keyframes loading_ring {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.Modal__Overlay {
  position: fixed;
  inset: 0px;
  top: initial;
  right: initial;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  z-index: 100; }
  .Modal__Overlay a {
    background-color: transparent;
    color: #057cb9;
    text-decoration: none;
    cursor: pointer;
    transition: color .1s ease-in-out; }
  .Modal__Overlay a:focus,
  .Modal__Overlay a:hover {
    color: #2bafe2;
    text-decoration: underline; }
  .Modal__Overlay .owl-upside-close {
    position: absolute;
    right: 21px;
    top: 18px;
    cursor: pointer;
    z-index: 10; }
    .Modal__Overlay .owl-upside-close i {
      font-size: 30px;
      color: #7f8c8d; }
  .Modal__Overlay.disabled {
    display: none; }
  .Modal__Overlay .checkbox,
  .Modal__Overlay .radio {
    position: relative;
    display: block;
    margin-top: 10px;
    margin-bottom: 10px; }
  .Modal__Overlay .checkbox label,
  .Modal__Overlay .radio label {
    min-height: 20px;
    padding-left: 20px;
    margin-bottom: 0;
    font-weight: 400;
    cursor: pointer;
    font-size: small; }
  .Modal__Overlay label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: 400; }
  .Modal__Overlay .modal-forgoot-popup.disabled {
    display: none; }
  .Modal__Overlay .modal-forgoot-popup .password-reset-signup-links p {
    margin-top: 10px; }
  .Modal__Overlay .modal-login-popup.disabled {
    display: none; }
  .Modal__Overlay .loading-modal.disabled {
    display: none; }
  .Modal__Overlay a.open_login_modal {
    font-size: 18px;
    line-height: 36px;
    color: #fff;
    font-weight: 700;
    padding: 7px 15px;
    text-transform: uppercase;
    background: #89b91d;
    border-radius: 2px;
    border: 1px solid #89b91d;
    margin: 15px auto 10px; }
    .Modal__Overlay a.open_login_modal:hover {
      text-decoration: none;
      cursor: pointer; }
  .Modal__Overlay .registration-popup {
    bottom: initial;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    max-width: 570px;
    width: auto;
    height: auto;
    border: none;
    position: relative;
    background: 0 0;
    padding: 25px;
    z-index: 500; }
    .Modal__Overlay .registration-popup.disabled {
      display: none; }
    .Modal__Overlay .registration-popup-main {
      background-color: #fff;
      border-radius: 8px;
      box-shadow: 0 0 11px 11px #00000030;
      position: relative;
      width: 100%; }
      .Modal__Overlay .registration-popup-main .reg-overlay-form {
        border-radius: 5px;
        padding-left: 80px;
        padding-right: 80px;
        padding-top: 66px; }
        .Modal__Overlay .registration-popup-main .reg-overlay-form .checkbox {
          margin: 15px auto 10px;
          max-width: 364px; }
        .Modal__Overlay .registration-popup-main .reg-overlay-form .checkbox input[type=checkbox].has-error {
          outline: 1px solid #df455e;
          box-shadow: 0 0 7px #ecb8be; }
        .Modal__Overlay .registration-popup-main .reg-overlay-form .checkbox input[type=checkbox],
        .Modal__Overlay .registration-popup-main .reg-overlay-form .checkbox-inline input[type=checkbox],
        .Modal__Overlay .registration-popup-main .reg-overlay-form .radio input[type=radio],
        .Modal__Overlay .registration-popup-main .reg-overlay-form .radio-inline input[type=radio] {
          position: absolute;
          margin-left: -20px; }
        .Modal__Overlay .registration-popup-main .reg-overlay-form input[type=checkbox],
        .Modal__Overlay .registration-popup-main .reg-overlay-form input[type=radio] {
          margin: 4px 0 0;
          line-height: normal; }
      .Modal__Overlay .registration-popup-main .reg-overlay-form h4 {
        color: #99c225;
        font-size: 28px;
        line-height: 32px;
        font-weight: 500;
        text-transform: uppercase;
        margin-bottom: 3px;
        margin-top: 0;
        width: calc(100% - 20px); }
      .Modal__Overlay .registration-popup-main .reg-overlay-form h5 {
        color: #2bafe3;
        font-size: 22px;
        line-height: 25px;
        font-weight: 700;
        text-transform: uppercase;
        margin: 0; }
      .Modal__Overlay .registration-popup-main .reg-overlay-form-inner {
        color: #666;
        float: left;
        margin-top: 8px;
        width: 100%; }
        .Modal__Overlay .registration-popup-main .reg-overlay-form-inner .reg-overlay-form-field-button {
          text-align: center; }
          .Modal__Overlay .registration-popup-main .reg-overlay-form-inner .reg-overlay-form-field-button p {
            font-size: 18px;
            line-height: 22px;
            margin: 15px auto 50px; }
          .Modal__Overlay .registration-popup-main .reg-overlay-form-inner .reg-overlay-form-field-button .btn-big {
            border-radius: 3px;
            font-size: 37px;
            height: 82px;
            line-height: 82px;
            max-width: 315px;
            width: 100%;
            overflow: hidden;
            text-shadow: 0 1px 3px #00000030;
            text-decoration: none;
            margin: 15px auto; }
            .Modal__Overlay .registration-popup-main .reg-overlay-form-inner .reg-overlay-form-field-button .btn-big.grey {
              background-color: #e5e5e5; }
          .Modal__Overlay .registration-popup-main .reg-overlay-form-inner .reg-overlay-form-field-button .btn-big {
            background: #89b91d;
            border: none;
            border-radius: 2px;
            color: #fff;
            display: inline-block;
            text-align: center;
            text-decoration: none;
            text-transform: uppercase;
            user-select: none; }
        .Modal__Overlay .registration-popup-main .reg-overlay-form-inner .form-control-feedback {
          color: #df455e;
          font-size: 12px;
          font-weight: normal;
          line-height: normal; }
        .Modal__Overlay .registration-popup-main .reg-overlay-form-inner .reg-overlay-form-field {
          margin-bottom: 5px;
          margin-top: 20px;
          color: #717171;
          font-size: 18px;
          line-height: 22px;
          font-weight: 400;
          position: relative; }
          .Modal__Overlay .registration-popup-main .reg-overlay-form-inner .reg-overlay-form-field .has-success.form-control {
            border-color: #3c763d;
            box-shadow: 0 0 7px #ddf8dc; }
          .Modal__Overlay .registration-popup-main .reg-overlay-form-inner .reg-overlay-form-field .has-error.form-control {
            border-color: #df455e;
            box-shadow: 0 0 7px #f8dce1; }
          .Modal__Overlay .registration-popup-main .reg-overlay-form-inner .reg-overlay-form-field input[type=email],
          .Modal__Overlay .registration-popup-main .reg-overlay-form-inner .reg-overlay-form-field input[type=password],
          .Modal__Overlay .registration-popup-main .reg-overlay-form-inner .reg-overlay-form-field input[type=text] {
            background-color: #f6f6f6;
            height: 44px;
            width: 100%;
            max-width: 364px;
            color: #222;
            font-size: 18px;
            line-height: 24px;
            padding-left: 16px;
            border-radius: 3px;
            border: 1px solid #e1e1e1;
            box-shadow: none;
            font-weight: 400; }
          .Modal__Overlay .registration-popup-main .reg-overlay-form-inner .reg-overlay-form-field::placeholder {
            color: #7f8c8d; }
  .Modal__Overlay .modal-message-popup,
  .Modal__Overlay .modal-conformation-popup,
  .Modal__Overlay .modal-login-group-popup {
    width: 100%;
    max-width: 320px;
    box-shadow: 0 0 11px 11px #00000030; }
    .Modal__Overlay .modal-message-popup.disabled,
    .Modal__Overlay .modal-conformation-popup.disabled,
    .Modal__Overlay .modal-login-group-popup.disabled {
      display: none; }
    .Modal__Overlay .modal-message-popup .modal-login-main,
    .Modal__Overlay .modal-conformation-popup .modal-login-main,
    .Modal__Overlay .modal-login-group-popup .modal-login-main {
      position: relative;
      background-color: #fff;
      background-clip: padding-box;
      border-radius: 6px;
      outline: 0;
      box-shadow: 0 0 11px 11px #00000030; }
      .Modal__Overlay .modal-message-popup .modal-login-main .modal-body,
      .Modal__Overlay .modal-conformation-popup .modal-login-main .modal-body,
      .Modal__Overlay .modal-login-group-popup .modal-login-main .modal-body {
        position: relative;
        padding: 25px; }
        .Modal__Overlay .modal-message-popup .modal-login-main .modal-body .login_title h3,
        .Modal__Overlay .modal-conformation-popup .modal-login-main .modal-body .login_title h3,
        .Modal__Overlay .modal-login-group-popup .modal-login-main .modal-body .login_title h3 {
          color: #2bafe3;
          font-size: 18px;
          line-height: 22px;
          margin: 0 0 26px; }
        .Modal__Overlay .modal-message-popup .modal-login-main .modal-body .login_form_box,
        .Modal__Overlay .modal-conformation-popup .modal-login-main .modal-body .login_form_box,
        .Modal__Overlay .modal-login-group-popup .modal-login-main .modal-body .login_form_box {
          padding: 0 0 20px;
          position: relative;
          border-radius: 3px;
          overflow: hidden;
          display: flex;
          align-items: center;
          justify-content: flex-start; }
          .Modal__Overlay .modal-message-popup .modal-login-main .modal-body .login_form_box .icon_user,
          .Modal__Overlay .modal-conformation-popup .modal-login-main .modal-body .login_form_box .icon_user,
          .Modal__Overlay .modal-login-group-popup .modal-login-main .modal-body .login_form_box .icon_user {
            position: absolute;
            top: 0;
            left: 0;
            width: 44px;
            height: 44px;
            line-height: 45px;
            text-align: right;
            background: #279fdc;
            padding-top: 6px;
            border-radius: 3px 0 0 3px; }
            .Modal__Overlay .modal-message-popup .modal-login-main .modal-body .login_form_box .icon_user img,
            .Modal__Overlay .modal-conformation-popup .modal-login-main .modal-body .login_form_box .icon_user img,
            .Modal__Overlay .modal-login-group-popup .modal-login-main .modal-body .login_form_box .icon_user img {
              vertical-align: middle;
              max-width: 100%;
              height: auto; }
          .Modal__Overlay .modal-message-popup .modal-login-main .modal-body .login_form_box input,
          .Modal__Overlay .modal-conformation-popup .modal-login-main .modal-body .login_form_box input,
          .Modal__Overlay .modal-login-group-popup .modal-login-main .modal-body .login_form_box input {
            font-size: 16px;
            color: #4c4c4c;
            height: 44px;
            border: 1px solid #e4e4e4;
            padding: 0 15px 0 59px;
            border-radius: 3px;
            background: #f6f6f6;
            width: 100%; }
          .Modal__Overlay .modal-message-popup .modal-login-main .modal-body .login_form_box.forgooten,
          .Modal__Overlay .modal-conformation-popup .modal-login-main .modal-body .login_form_box.forgooten,
          .Modal__Overlay .modal-login-group-popup .modal-login-main .modal-body .login_form_box.forgooten {
            font-size: 12px;
            color: #777; }
            .Modal__Overlay .modal-message-popup .modal-login-main .modal-body .login_form_box.forgooten a:hover,
            .Modal__Overlay .modal-conformation-popup .modal-login-main .modal-body .login_form_box.forgooten a:hover,
            .Modal__Overlay .modal-login-group-popup .modal-login-main .modal-body .login_form_box.forgooten a:hover {
              cursor: pointer;
              color: #626262;
              text-decoration: underline; }
          .Modal__Overlay .modal-message-popup .modal-login-main .modal-body .login_form_box input[type=button],
          .Modal__Overlay .modal-message-popup .modal-login-main .modal-body .login_form_box input[type=submit],
          .Modal__Overlay .modal-conformation-popup .modal-login-main .modal-body .login_form_box input[type=button],
          .Modal__Overlay .modal-conformation-popup .modal-login-main .modal-body .login_form_box input[type=submit],
          .Modal__Overlay .modal-login-group-popup .modal-login-main .modal-body .login_form_box input[type=button],
          .Modal__Overlay .modal-login-group-popup .modal-login-main .modal-body .login_form_box input[type=submit] {
            font-size: 18px;
            line-height: 36px;
            color: #fff;
            font-weight: 700;
            padding: 0 15px;
            background: #89b91d;
            border-radius: 2px;
            width: 120px;
            height: 36px;
            border: 1px solid #89b91d;
            float: right;
            margin-left: auto;
            cursor: pointer; }
            .Modal__Overlay .modal-message-popup .modal-login-main .modal-body .login_form_box input[type=button]:hover,
            .Modal__Overlay .modal-message-popup .modal-login-main .modal-body .login_form_box input[type=submit]:hover,
            .Modal__Overlay .modal-conformation-popup .modal-login-main .modal-body .login_form_box input[type=button]:hover,
            .Modal__Overlay .modal-conformation-popup .modal-login-main .modal-body .login_form_box input[type=submit]:hover,
            .Modal__Overlay .modal-login-group-popup .modal-login-main .modal-body .login_form_box input[type=button]:hover,
            .Modal__Overlay .modal-login-group-popup .modal-login-main .modal-body .login_form_box input[type=submit]:hover {
              background: #8dc015; }
        .Modal__Overlay .modal-message-popup .modal-login-main .modal-body .password-reset-signup-links,
        .Modal__Overlay .modal-conformation-popup .modal-login-main .modal-body .password-reset-signup-links,
        .Modal__Overlay .modal-login-group-popup .modal-login-main .modal-body .password-reset-signup-links {
          height: 36px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: 150px; }
