//this scss stylesheet if for css rules that will be repeated
@import '../vars';
@import '../breakpoints';

.pagination{
  display: flex;
  justify-content: center;
  gap: 20px;
  align-items: baseline;
  color: $accent-color;
  & span,
  & a{
    display: block;
    padding: 7px;
    border-radius: 3px;
  }
  &__current_page{
    color: white;
    background: $accent-color;
  }
  &__first_post,
  &__last_post{
    color: #864CD7;
    background: #E8E1F3;
    font-weight: 500;
  }
  &__newer_posts,
  &__older_posts{
    padding: 9px 12px !important;
    color: white;
    background: $secondary-background-color;
  }
  &__newer_posts.muted,
  &__older_posts.muted{
    background-color: white;
    color: $secondary-background-color;
    border: 1px solid $secondary-background-color;
  }
  &__of_posts{
    font-weight: 500;
  }
}