@import '../vars';
@import '../reset';
@import '../breakpoints';

// This is the style sheet fo the single guides page
.single_news{
  &__banner{
    background-color: $primary-background-color;
    padding: 20px 0 20px;
    position: relative;
    &_feed_info{
      &_date,
      &_name{
        font-size: 12px;
        font-weight: 400;
        color: $tetiary-color;
        margin-top: 10px;
      }
      &_name{
        margin-left: 20px;
        & a{
          color: $tetiary-color;
          font-weight: 600;
          &:hover{
            text-decoration: underline;
          }
        }
      }
    }
  }
  &__container{
    padding: 0 20px;
    max-width: 1100px;
    & img{
      padding-right: 10px;
    }
  }
  &__banner_title{
    font-size: 30px;
    color: $tetiary-color;
    padding-top: 10px;
  }
  &__banner_paragraph{
    padding: 15px 0;
    font-size: 17px;
    color: $tetiary-color;
  }

  // styling the content image section
  &__content{
    padding: 30px 20px 0;
    & .inner{
      display: flex;
      flex-direction: row;
    }
    &_right{
      width: 66%;
      padding: 0 20px;
      &_image{
        img{
          width: 100%;
          filter: drop-shadow(2px 2px 4px #c5c5c5);
          border-radius: 15px;
        }
        img:hover{
          filter: drop-shadow(2px 2px 4px #949494)
        }
      }
      &_body{
        padding: 50px 0;
        line-height: 1.5em;
        color: $paragraph-text-color;
        font-size: 16px;
        font-weight: 400;
        & p{
          padding-bottom: 10px;
        }
        & .quote{
          font-size: 20px;
          line-height: 36px;
          font-style: italic;
          padding: 30px 20px 30px 90px;
        }
        & .note {
          font-size: 20px;
          line-height: 36px;
          font-style: italic;
          padding: 0 20px 0 40px;
          margin: 20px 0 20px 50px;
          border-left: 4px solid #7c66cb;
        }
        &_author{
          width: 100%;
          border-top: 1px solid #ececec;
          margin: 30px 0 0;
          padding: 20px 0;
          &_name_author{
            color: $background-text-color;
          }
          &_icon{
            & img{
              width: 65px;
              border-radius: 50%;
              border: 2px solid #ececec;
            }
            display: flex;
            align-items: center;
          }
          &_name{
            padding-left: 20px;
            color: $paragraph-text-color;
            & a{
              color: $paragraph-text-color;
              text-decoration: none;
              &:hover{
                text-decoration: underline;
              }
            }
            &_desc{
              margin-top: 20px;
              font-size: 16px;
              font-weight: 400;
              color: $paragraph-text-color;
            }
          }
        }
      }
    }

    &_left{
      width: 33%;
      padding-left: 35px;
      &__headers{
        text-align: left;
        padding-bottom: 16px;
      }
      &__news_feed{
        display: flex;
        flex-direction: column;
        &_single_item{
          display: flex;
          flex-direction: row;
          padding-bottom: 24px;
        }
        &_image{
          border-radius: 10px;
          height: 85px;
          min-width: 85px;
          background-size: cover;
          background-position: center;
        }
        &_info{
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          margin-left: 15px;
          align-items: center;
          &_text{
            flex: 0 1 100%;
            & h4{
              font-size: 16px;
              font-weight: 500;
              text-decoration: none;
              color: $accent-text-color;
              &:hover{
                color: $background-text-color;
              }
            }
          }
          &_date,
          &_name{
            font-size: 10px;
            font-weight: 400;
            color: $accent-text-color;
            //margin-top: 10px;
          }
          &_name{
            margin-left: 15px;
            & a{
              color: $background-text-color;
              &:hover{
                text-decoration: underline;
              }
            }
            color: $background-text-color;
          }
        }
      }
    }

  }

}