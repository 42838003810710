@import '../vars';
@import '../reset';
@import '../breakpoints';

// This is the style sheet fo the single guides page
.single_guides{
    &__banner{
        background-color: $secondary-background-color;
        padding: 40px 0;
        position: relative;
    }
    &__container{
        padding: 0 20px;
        max-width: 1100px;
    }
    &__banner_title{
        font-size: 30px;
        color: $primary-background-color;
        padding-top: 10px;
    }
    &__banner_paragraph{
        padding-top: 20px;
        font-size: 17px;
        color: $primary-background-color;
    }
    &__banner_links{
        position: absolute;
        bottom: -8px;
        a{
            background-color: $accent-color;
            padding: 5px 20px;
            color: $tetiary-color;
            font-size: 14px;
            border-radius: 20px;
        }
    }

    // styling the content image section
    &__content{
        padding: 80px 20px 50px;
    }
    &__content_image{
        display: flex;
        justify-content: left;
        max-width: 900px;
        padding: 20px;
        margin: 0 auto;
        img{
            width: 100%;
            filter: drop-shadow(2px 2px 4px #c5c5c5);
        }
        img:hover{
            filter: drop-shadow(2px 2px 4px #949494)
        }
    }
    &__content_body{
        padding: 50px 0;
        line-height: 1.5em;
        
        .inner p{
            a{
                color: red;
                font-weight: bold;
                text-decoration: underline;

                &:hover{
                    color: $secondary-background-color;

                }
            }
        }
    }
    &__author_container{
        display: flex;
        align-items: center;
        align-content: center;
    }
    &__author_thumb{
        border-radius: 50%;
    }
    &__author_text{
        padding-left: 20px;
    }
    &__author_info{
        color: $background-text-color;
        span{
            color: $accent-color;
            font-weight: 600;
            line-height: 25px;
        }
    }
    &__author_date{
        font-size: 14px;
        color: $background-text-color;
    }
}