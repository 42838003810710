@import '../vars';
@import '../reset';
@import '../breakpoints';

.compare{
    &__banner_container{
        display: grid;
        // grid-template-columns: repeat(2, 48%);
        grid-template-columns: 60% 35%;
        padding: 30px 20px 0;
        justify-content: space-between;
        position: relative;
        
        @include media ('<=800px'){
            grid-template-columns: 100%;
        }
    }

    &__banner_left{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        @include media ('<=800px'){
            padding-top: 20px;
            display: grid;
            justify-content: center;
            text-align: left;
            margin: 0 auto;
        }

        & .default__heading{
            width: auto;
        }
    }
    &__banner_img.mobile{
        display: none;
        @include media ('<=800px'){
            display: grid;
            justify-content: center;
            align-items: center;
            margin: 20px 0;
        }
        img{
            @include media ('<=800px'){
                width: 180px;
                height: 180px;
            }
        }
    }

    &__banner_img{
        display: grid;
        justify-content: center;
        align-items: center;
        @include media ('<=800px'){
            display: none;
        }
        img{
            width: 90%;
        }
    }
    &__banner_heading{
        font-size: 42px;
        font-weight: 700;
        // padding: 20px 0;

        @include media ('<=800px'){
            text-align: center;
            font-size: 25px;
            margin: 0 auto;
        }
    }
    &__banner_subheading{
        font-size: 18px;
        color: #fcc13e;
        font-weight: 700;
        // padding: 20px 0;

        @include media ('<=800px'){
            margin: 0 auto;
        }
    }
    &__banner_paragraph{
        font-weight: 400;
        font-size: 16px;
        padding-bottom: 30px;
        line-height: 30px;

        @include media ('<=800px'){
            text-align: center;
            margin: 0 auto;
        }
    }
    &__banner_left_img{
        @include media ('>=800px'){
            display: none;
        }

        @include media ('<=800px'){
            display: flex;
            margin: 0 auto;
            justify-content: center;
            padding-top: 20px;
            img{
                width: 50%;
            }
        }
    }
    &__shape{
        width: 100%;
        overflow: hidden;
    }
}