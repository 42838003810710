@import "../vars";
@import "../reset";
@import "../breakpoints";

.archive_casinos {
  // styling the banner section
  &__banner {
    background: $primary-background-color;
    color: $tetiary-background-color;
  }
  &__heading {
    font-size: 42px;
    font-weight: 700;
    padding-top: 20px;
  }
  &__subheading {
    font-size: 16px;
    font-weight: 600;
    color: $secondary-background-color;
    padding: 20px 0;
  }
  &__paragraph {
    font-weight: 400;
    font-size: 16px;
    padding-bottom: 30px;
  }
  &__title {
    width: 50%;
  }
  &__img_title {
    height: 335px;
  }
  &__img {
    position: absolute;
    z-index: 100;
    padding-left: 649px;
    top: 56px;
  }

  &__shape-top {
    background-color: $primary-background-color;
    width: 100%;
    height: 50px;
    border-radius: 50% / 75%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border: 1px solid $primary-background-color;
    transform: scale(1.05, -1);
  }
  & .anchor__menu {
    max-width: none;
    border: none;
    border-radius: 0;
  }
  &__image {
    display: flex;
    flex-direction: column;
    align-items: center;
    & img {
      width: 160px;
      border-radius: 15px;
    }
  }
  &__image_rev,
  &__engage_tc,
  &__engage_rev {
    text-decoration: underline;
    color: $accent-text-color;
    font-size: 14px;
    padding: 5px;
  }
  &__engage_tc {
    &.muted {
      text-decoration: none;
      font-weight: 500;
      color: $background-text-color;
    }
  }
  &__casinos_item_box {
    margin: 10px auto;
    & .inner .ad_disclosure {
      margin-bottom: 45px;
    }
  }
  &__single_item {
    @include media("<=800px") {
      padding: 0 20px;
    }
  }
  &__single_item_head {
    display: flex;
    justify-content: space-between;
  }
  &__single_item_about {
    padding: 20px 0;
    flex: 2 1 100%;
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    & a {
      color: $secondary-background-color;
      text-decoration: underline;
    }
  }
  &__single_item_details {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: baseline;
    & h4 {
      font-family: Mulish, sans-serif;
      font-weight: 700;
      font-size: 18px;
      margin-top: 20px;
    }
    &_field {
      padding-top: 15px;
      display: flex;
      align-items: center;
      & span {
        font-weight: 400;
        font-size: 16px;
        width: 90%;
        @include media("<=800px") {
          font-size: 12px;
        }
      }
    }
    & i {
      color: #fff;
      display: block;
      height: 22px;
      width: 22px;
      border-radius: 15px;
      margin-right: 10px;
      text-align: center;
      padding-top: 4px;
    }
    &__pros {
      & i {
        background: #a2d532;
      }
    }
    &__cons {
      & i {
        background: #ee4d4d;
      }
    }
    &_pros_cons {
      display: flex;
      flex-direction: row;
      gap: 20px;
      flex: 1 1 50%;
      padding-right: 20px;
      @include media("<=800px") {
        padding: 0 0 20px 0;
      }
    }
  }
  &__single_item_inner {
    margin: 20px auto;
    box-shadow: 0 0 12px rgba(158, 158, 158, 0.55);
    padding: 45px 65px 20px;
    border-radius: 10px;
    max-width: 1100px;

    @include media("<=500px") {
      width: 95%;
      padding: 30px 20px;
    }
  }
  &__info {
    width: 195px;
    height: 135px;
    background: #f0eefc;
    border-radius: 10px;
    text-align: center;
    padding-top: 20px;
    margin-top: 15px;
    & h3 {
      font-weight: 500;
      color: $primary-background-color;
      font-size: 20px;
      & strong {
        font-size: 30px;
      }
    }
  }
  &__text h4 > * {
    text-align: center !important;
  }
  &__text {
    width: 40%;
    margin-top: 20px;
    font-size: 20px;
    font-weight: 60;
    text-align: center;

    & a {
      display: block !important;
    }

    @include media("<=800px") {
      width: 100%;
      padding: 20px 0;

      & a {
        display: none !important;
      }
    }
  }
  div &__engage {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    &_play {
      color: $primary-background-color !important;
      text-decoration: none !important;
      background: $secondary-background-color;
      padding: 11px 50px;
      text-transform: uppercase;
      font-weight: 700;
    }
    &_tc {
      padding-top: 20px;
      color: #4b4b4b !important;
      //display: none;
      @include media("<=800px") {
        display: block;
      }
    }
  }

  &__single_item {
    &_terms {
      font-family: Inter;
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      margin-top: 1em;
    }
    &_bonus {
      &_value {
        font-weight: 700;
        font-size: 16px;
      }
      &_title {
        text-align: center;
      }
      &_details {
        display: flex;
        align-items: center;
        gap: 20px;
        & img {
          height: fit-content;
        }
      }
    }
    &_details_features {
      border-radius: 20px;
      background-color: #f9f6fd;
      flex: 1 1 50%;
      width: 100%;
      & .nav-tab-wrapper {
        padding: 12px 20px;
        background-color: #eee6f9;
        border-radius: 20px 20px 0 0;
        overflow: auto;
        &_center {
          display: flex;
          margin: 0 auto;
          width: fit-content;
        }
      }
      .nav-tab.nav-tab-active {
        border: none;
      }
      & .nav-tab:last-child {
        margin-right: 0;
      }
      & .nav-tab {
        font-size: 16px;
        font-weight: 500;
        padding: 0;
        margin-right: 7px;
        @include media("<=800px") {
          font-size: 12px;
          font-weight: 700;
        }
      }
      & .tab-target {
        margin: 25px 30px;
        @include media("<=800px") {
          margin: 25px 15px;
        }
      }
    }
    &_details_total {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      //height: 320px;
      @include media("<=800px") {
        gap: 20px;
      }
      & li {
        list-style: none;
        line-height: 36px;
        font-size: 12px;
      }
      & div:first-child {
        & p {
          font-size: 40px;
          font-weight: 600;
          justify-content: center;
          color: #864cd7;
        }
        text-align: center;
      }
      .providers_list & div:first-child,
      .game_types & div:first-child {
        flex: 1 1 25%;
        align-items: center;
        @include media("<=800px") {
          flex: 1 1 100%;
        }
      }
      .providers_list & div:last-child,
      .game_types & div:last-child {
        flex: 1 1 75%;
        align-items: center;
        justify-content: center;
        @include media("<=800px") {
          flex: 1 1 100%;
        }
      }
      &_content,
      &_number {
        flex: 1 1 50%;
        align-items: center;
        @include media("<=800px") {
          flex: 1 1 100%;
        }
      }
      &_content {
        @include media("<=800px") {
          & ul {
            display: flex;
            justify-content: center;
            gap: 8px;
          }
          & li {
            display: flex;
            flex-direction: column;
            font-family: Inter;
            font-size: 10px;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: 0em;
            text-align: center;
            margin-right: 8px;
          }
        }
      }
      div &_logos {
        flex: 2 1 100%;
        display: flex;
        gap: 15px;
        flex-wrap: wrap;
        justify-content: center;
        & img {
          width: 70px;
        }
      }
      &_icon_list,
      &_logos {
        gap: 15px;
        margin-top: 20px;
      }
      &_icon_list {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        .providers_list & div {
          max-width: 80px;
          & img {
            max-height: 35px;
            max-width: 80px;
          }
        }
        & div {
          text-align: center;
          max-width: 40px;
          font-size: 12px;
          & img {
            max-height: 35px;
            max-width: 35px;
          }
        }
      }
    }
    &_more_button {
      text-align: center;
      display: block;
      padding: 30px;
      cursor: pointer;
      width: 100%;
      flex: 2 1 100%;
      & svg {
        transform: scale(1, -1);
        transition: all 0.5s ease-in-out;
      }
      .expand & svg {
        transform: scale(1, 1);
        transition: all 0.5s ease-in-out;
      }
    }
  }

  & .filter_conntent_block {
    &-sort select {
      min-width: unset;
    }
    &-sort-list {
      margin-right: 2rem;
    }
    &-form {
      display: flex;
      align-items: center;
      width: 50%;
      & svg {
        margin-right: -25px;
      }
      & input {
        background: none;
        border-radius: 30px;
        font-size: 16px;
        font-weight: 600;
        color: $paragraph-text-color;
        border: solid 1px #d9d9d9;
        height: 31px;
        width: 100%;
        padding-left: 25px;
        padding-right: 150px;
        &::placeholder {
          color: #d9d9d9;
        }
        &:focus {
          color: $paragraph-text-color;
        }
      }
      &-button {
        margin-left: -100px;
        & button {
          background-color: $primary-background-color;
          border: none;
          width: 150px;
          height: 30px;
          border-radius: 0 15px 15px 0;
          color: white;
          cursor: pointer;
        }
      }
    }
  }
}

.lotteries {
  & .archive_casinos {
    &__single_item_head {
      justify-content: space-around;
    }
  }
}
